import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './common/appState/appStateHooks';
import { selectAvailableClients, updateAvailableClients } from './common/appState/availableClientsSlice';
import { selectClientAlias, updateClientId, updateClientName } from './common/appState/currentClientSlice';

// This component exists to manage items which may need to be rehydrated from session storage. Examples include the currently selected
// client the user is working with and the list of available clients. These items are both stored in the Redux global app store, which is an
// in-memory store. If the user refreshes the page via the browser, those values are lost, and need to be rehydrated from session storage.
const SessionStorage = () => {
  const dispatch = useAppDispatch();
  const clientAlias = useAppSelector(selectClientAlias);
  const availableClients = useAppSelector(selectAvailableClients);

  // watch for clientAlias being removed from the Redux app store and rehydrate it
  useEffect(() => {
    if (!clientAlias.clientID || !clientAlias.clientName) {
      const persistedClient = sessionStorage.getItem('clientAlias');

      if (persistedClient) {
        var parsedClient = JSON.parse(persistedClient);

        dispatch(updateClientId(parsedClient.clientID));
        dispatch(updateClientName(parsedClient.clientName));
      }
    }
  }, [clientAlias, dispatch]);

  // watch for the client list being removed from the Redux app store and rehydrate it
  useEffect(() => {
    if (!sessionStorage.clientList) {
      return;
    }

    if (!availableClients || availableClients.length === 0) {
      const availableClientsRaw = JSON.parse(sessionStorage.clientList);

      const availableClients = availableClientsRaw.map((availableClient: { clientID: string; clientName: string }) => ({
        clientID: availableClient.clientID,
        clientName: availableClient.clientName,
      }));

      if (availableClients.length > 0) {
        dispatch(updateAvailableClients(availableClients));
      }
    }
  }, [availableClients, dispatch]);

  // by default this component doesn't display anything, it exists just to rehydrate Redux from browser session storage
  return <></>;
};

export default SessionStorage;
