import React, { useEffect } from 'react';
import api from '../../common/api';
import { getData } from '../../models/Helpers/FetchHelper';
import TokenContext from '../../common/tokenContext';
import { selectClientAlias } from '../../common/appState/currentClientSlice';
import { ClientModule } from '../../models/Module';
import { useAppDispatch, useAppSelector } from '../../common/appState/appStateHooks';
import { selectClientModules, updateClientModules } from '../../common/appState/clientModulesSlice';
import { useQuery } from '@tanstack/react-query';
import { arraysEqual } from '../../models/Helpers/ArrayHelper';

/**
 * This component is responsible for retrieving which modules are available for a client/user
 * and updating the Redux store with them
 */
const FeatureManagement = () => {
  const { clientID } = useAppSelector(selectClientAlias);
  const currentModules = useAppSelector(selectClientModules);
  const dispatch = useAppDispatch();

  const retrieveModules = async () => {
    let enabledModules: ClientModule[] = [];
    if (!sessionStorage.accessToken || clientID === '0') {
      return [];
    }
    var tokenContext = new TokenContext(sessionStorage.accessToken);

    await tokenContext.useToken(
      async (token) => {
        const enabledModulesResponse = await getData(`${api.baseUrl}/api/clients/${clientID}/modules/active`, token);
        if (enabledModulesResponse.ok) {
          enabledModules = (await enabledModulesResponse.json()) as ClientModule[];
        }
      },
      () => {
        return Promise.resolve();
      }
    );

    return enabledModules;
  };

  const { data } = useQuery({
    queryKey: [clientID, 'enabledModules'],
    queryFn: () => retrieveModules(),
  });

  useEffect(() => {
    if (!data) {
      if (currentModules.enabledModules.length !== 0) {
        dispatch(updateClientModules([]));
      }

      return;
    }

    if (arraysEqual(currentModules.enabledModules, data, (a, b) => a.id === b.id)) {
      return;
    }

    dispatch(updateClientModules(data!));
  });

  return <></>;
};

export default FeatureManagement;
