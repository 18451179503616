import { createSlice } from '@reduxjs/toolkit';
import { DestinationSortType, SortOrder } from '../../components/callTriage/destinations/types';
import { RootState } from './appStore';

interface CallTriageSettings {
  incidentSortOrder: SortOrder;
  incidentSortType: DestinationSortType;
  sortOrder: SortOrder;
  sortType: DestinationSortType;
}

const initialState: CallTriageSettings = {
  incidentSortOrder: SortOrder.Ascending,
  incidentSortType: DestinationSortType.Distance,
  sortOrder: SortOrder.Ascending,
  sortType: DestinationSortType.Name,
};

interface ReducerAction<T> {
  type: string;
  payload: T;
}

export const callTriageSettings = createSlice({
  name: 'callTriageSettings',
  initialState,
  reducers: {
    updateDestinationSortType: (state, action: ReducerAction<DestinationSortType>) => {
      state.sortType = action.payload;
    },
    updateDestinationSortOrder: (state, action: ReducerAction<SortOrder>) => {
      state.sortOrder = action.payload;
    },
    updateIncidentDestinationSortType: (state, action: ReducerAction<DestinationSortType>) => {
      state.incidentSortType = action.payload;
    },
    updateIncidentDestinationSortOrder: (state, action: ReducerAction<SortOrder>) => {
      state.incidentSortOrder = action.payload;
    },
  },
});

export const {
  updateDestinationSortType,
  updateDestinationSortOrder,
  updateIncidentDestinationSortOrder,
  updateIncidentDestinationSortType,
} = callTriageSettings.actions;

export const selectDestinationSortOrder = (state: RootState) => state.callTriageSettings.sortOrder;
export const selectDestinationSortType = (state: RootState) => state.callTriageSettings.sortType;
export const selectIncidentDestinationSortType = (state: RootState) => state.callTriageSettings.incidentSortType;
export const selectIncidentDestinationSortOrder = (state: RootState) => state.callTriageSettings.incidentSortOrder;

export default callTriageSettings.reducer;
