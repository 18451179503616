const routes = Object.freeze({
  adminSettings: '/adminSettings',
  associate: '/associate',
  callTriage: '/callTriage',
  callTriageIncident: '/callTriage/incidents',
  callTriageEditIncident: '/callTriage/incidents/:id',
  callTriageDestinations: '/callTriage/destinations',
  callTriageDispositionsByUserReport: '/reports/callTriage/dispositionsByUserReport',
  callTriageEventLog: '/callTriage/eventLog',
  callTriageTransportsByAgencyReport: '/reports/callTriage/transportsByAgencyReport',
  callTriageTransportsByDestinationReport: '/reports/callTriage/transportsByDestinationReport',
  create1: '/create1',
  create2: '/create2',
  create3: '/create3',
  dashboard: '/dashboard',
  forgot: '/forgot',
  forgot2: '/forgot2',
  incidentDetails: '/incidentDetails',
  incidentSearch: '/incidentSearch',
  login: '/',
  personnel: '/personnel',
  reporting: '/reports',
  responderActionByPersonnelReport: '/reports/responder/actionByPersonnelReport',
  responderStatusByPersonnelReport: '/reports/responder/statusByPersonnelReport',
  responderStatusByIncidentReport: '/reports/responder/statusByIncidentReport',
});

export default routes;
