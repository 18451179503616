/**
 * An error thrown when an API request gets a non-success response.
 */
export class ApiResponseError extends Error {
  message: string;

  /**
   * The HTTP status code of the response.
   */
  status: number;

  constructor(message: string, status: number) {
    super();
    Object.setPrototypeOf(this, ApiResponseError.prototype);
    this.name = 'ApiResponseError';
    this.message = message;
    this.status = status;
  }

  /**
   * Returns true if the given value is an ApiResponseError object.
   */
  static isApiResponseError(obj: any): obj is ApiResponseError {
    return obj && typeof obj === 'object' && 'name' in obj && obj.name === 'ApiResponseError';
  }
}
