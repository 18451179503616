export const constructAddress = (address: {
  streetAddressFull?: string;
  streetNumber?: string;
  streetPrefix?: string;
  streetName?: string;
  streetType?: string;
  streetSuffix?: string;
  locationName?: string;
  city?: string;
  state?: string;
}) => {
  // if we have a full address, take it.
  if (address.streetAddressFull) {
    return address.streetAddressFull;
  }
  const cityState =
    !!address.city && !!address.state
      ? `${address.city}, ${address.state}`
      : !!address.city || !!address.state
      ? `${address.city} ${address.state}`.trim()
      : '';
  const addressParts = [
    address.streetNumber,
    address.streetPrefix,
    address.streetName,
    address.streetType,
    address.streetSuffix,
    !!address.locationName ? `@ ${address.locationName}` : '',
    cityState,
  ];
  // reduce the address to only parts that exist.
  return addressParts.reduce((a, b) => (!!b ? `${a} ${b}`.trim() : a), '');
};

export const distance = (x1: number, y1: number, x2: number, y2: number) => {
  const x = x1 - x2;
  const y = y1 - y2;
  return Math.sqrt(x * x + y * y);
};
