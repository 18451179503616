import { Autocomplete, AutocompleteProps } from '@react-google-maps/api';
import React from 'react';
import { LatLong } from '../types';

type SignalAutocompleteProps = {
  isEditable?: boolean;
  location?: LatLong;
  setLocation?: React.Dispatch<React.SetStateAction<LatLong | undefined>>;
  handlePlaceChange: (place: google.maps.places.PlaceResult) => void;
  mapLoaded: boolean;
} & AutocompleteProps;

function SignalAutocomplete({
  isEditable,
  location,
  setLocation,
  handlePlaceChange,
  children,
  mapLoaded,
  ...props
}: SignalAutocompleteProps) {
  const [autocomplete, setAutocomplete] = React.useState<google.maps.places.Autocomplete | null>(null);

  const onLoad = React.useCallback(function callback(autocomplete: google.maps.places.Autocomplete) {
    setAutocomplete(autocomplete);
  }, []);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      handlePlaceChange(autocomplete.getPlace());
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  return mapLoaded ? (
    <>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} {...props}>
        {children}
      </Autocomplete>
    </>
  ) : (
    <></>
  );
}

export default React.memo(SignalAutocomplete);
