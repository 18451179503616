import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import api from '../../common/api';
import routes from '../../common/routesDefinitions';
import { ClientUserInvitationRequest } from '../../models/ClientUserInvitationRequest';
import { postData } from '../../models/Helpers/FetchHelper';
import { appendQueryString } from '../../services/api/querystrings';
import Logo from '../logo/logo';

export default function Create2() {
  const location = useLocation();
  const navigate = useNavigate();
  // Checks to see if the user has completed step 1
  const emailAddress = location?.state?.emailAddress;
  const firstName = location?.state?.firstName;
  const lastName = location?.state?.lastName;
  const searchParams: ClientUserInvitationRequest = location?.state?.searchParams;

  if (!emailAddress) {
    navigate(routes.create1);
  }
  // User object attributes for Aware API call
  const [userPassword, setUserPassword] = useState({
    password: '',
  });
  const [userPassConfirm, setUserPassConfirm] = useState({
    password: '',
  });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  // Handlers for user input in text fields to apply values to attribute states
  const handlePasswordChange = (event: any) => {
    setUserPassword({ ...userPassword, password: event.target.value });
  };
  const handlePassConfirmChange = (event: any) => {
    setUserPassConfirm({ ...userPassConfirm, password: event.target.value });
  };

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();
    if (userPassword.password !== userPassConfirm.password) {
      setErrorMessages(['The passwords you entered did not match.']);
      setUserPassword({ password: '' });
      setUserPassConfirm({ password: '' });
      return;
    }
    const awareResponse = await postData(`${api.baseAwareUrl}/user/SaveNew`, {
      // new user object assembly for Aware api POST
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      password: userPassword.password,
      passwordConfirm: userPassConfirm.password,
    });
    if (!awareResponse.ok) {
      // alerts the user that an error occurred
      setErrorMessages(['There was an error processing your request. Please try again.']);
      return;
    }
    const data = await awareResponse.json();
    navigate(routes.create3, {
      state: {
        emailAddress,
        firstName,
        lastName,
        searchParams,
        password: userPassword.password,
        code: data.verificationCode,
      },
    });

    setUserPassword({ password: '' });
    setUserPassConfirm({ password: '' });
  };

  return (
    <div>
      <Row className="mx-0">
        <Col className="textRight">
          <Logo />
        </Col>
      </Row>
      <div className="container">
        <div className="row justify-content-center mx-0">
          <div className="col-xl-5 col-lg-12 col-md-9">
            <div className="form-bg form-border shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row mx-0">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-white mb-4">
                          Create an Aware account:
                          <br />
                          Step 2 of 3
                        </h1>
                      </div>
                      <Form onSubmit={(e) => handleFormSubmit(e)}>
                        <Alert
                          dismissible
                          onClose={() => setErrorMessages([])}
                          variant="danger"
                          show={errorMessages.length > 0}
                        >
                          <ul>
                            {errorMessages.map((errorMessage) => (
                              <li>{errorMessage}</li>
                            ))}
                          </ul>
                        </Alert>
                        <p className="form-title">Create a Password</p>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="password"
                            name="password"
                            onChange={handlePasswordChange}
                            value={userPassword.password}
                            required
                          />
                        </Form.Group>
                        <p className="form-title">Confirm Password</p>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="password"
                            name="passConfirm"
                            onChange={handlePassConfirmChange}
                            value={userPassConfirm.password}
                            required
                          />
                        </Form.Group>
                        <div className="row mb-3 mx-1">
                          <Button type="submit" name="next" variant="primary">
                            Next Step
                          </Button>
                        </div>
                        <div className="row mx-1"></div>
                      </Form>
                      <div className="text-center text-white">
                        <p>Already have an Aware account?</p>
                        <br />
                        <Link to={appendQueryString(routes.login, searchParams)}>Back to Login Page</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
