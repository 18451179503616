import ClientAlias from '../common/clientAlias';
import { ClientUser } from './ClientUser';

export default class CurrentUser {
  static isAdministrator(currentClient: ClientAlias) {
    if (!currentClient) {
      throw new Error('Current user client was not provided');
    }

    if (this.userSignalAdmin()) {
      return true;
    }

    return this.userClientAdmin(currentClient);
  }

  private static userSignalAdmin() {
    // are we a Signal Admin?
    const rawUser = sessionStorage.user;

    // check if we're a Signal Admin
    if (rawUser) {
      const user = JSON.parse(rawUser);

      if (user.admin) {
        return true;
      }
    }

    return false;
  }

  private static userClientAdmin(currentClient: ClientAlias) {
    if (currentClient.clientID === '0') return false;
    const clientList = JSON.parse(sessionStorage.clientList);

    // find the client we're currently working in
    var currentClients = clientList.filter(
      (clientUser: ClientUser) => clientUser.clientID === currentClient.clientID
    ) as ClientUser[];

    if (currentClients.length === 0) {
      return false;
    }

    const userClient = currentClients[0];

    return userClient.clientUserRole.indexOf('Admin') > -1;
  }
}
