import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import api from '../../../common/api';
import { useAppSelector } from '../../../common/appState/appStateHooks';
import { selectClientId } from '../../../common/appState/currentClientSlice';
import { ReducerAction } from '../../../common/reducerAction';
import QueryResult from '../../../models/QueryResult';
import Report from '../reportBase';
import { DefaultReportParameters, DefaultReportReducer, DefaultUtcReportInitialState } from '../reportParameters';
import ReportQuery, { QueryParametersEnum } from '../reportQueries';

type ReportResponse = {
  awareUserId: string;
  fullName: string;
  destinationAssigned: number;
  lineDisconnected: number;
  nonTransport: number;
  total: number;
  percentage: string;
};

type ReportParameters = {
  userIDs: string[];
} & DefaultReportParameters;

const ReportInitialState: ReportParameters = {
  ...DefaultUtcReportInitialState,
  userIDs: [],
};

const ReportReducer: ImmerReducer<ReportParameters, ReducerAction<ReportParameters>> = (draft, action) => {
  switch (action.type) {
    case QueryParametersEnum.UserIDs:
      draft.userIDs = action.data.userIDs;
      break;
    default:
      DefaultReportReducer(draft, action);
      break;
  }
};

const CallTriageDispositionsByUserReport = (): JSX.Element => {
  const currentClientId = useAppSelector(selectClientId);
  const columnHelper = createColumnHelper<ReportResponse>();
  const [reportQuery, updateReportQuery] = useImmerReducer(ReportReducer, ReportInitialState);

  const resultColumns: (queryResult: QueryResult<ReportResponse>) => ColumnDef<ReportResponse, any>[] = (
    queryResult
  ) => [
    columnHelper.accessor('fullName', {
      header: 'Incident Created By',
      footer: queryResult.totalResult?.fullName,
    }),
    columnHelper.accessor('destinationAssigned', {
      header: 'Destination Assigned',
      footer: queryResult.totalResult?.destinationAssigned?.toString(),
    }),
    columnHelper.accessor('lineDisconnected', {
      header: 'Line Disconnected',
      footer: queryResult.totalResult?.lineDisconnected?.toString(),
    }),
    columnHelper.accessor('nonTransport', {
      header: 'Non-Transport',
      footer: queryResult.totalResult?.nonTransport?.toString(),
    }),
    columnHelper.accessor('total', { header: 'Total', footer: queryResult.totalResult?.total?.toString() }),
    columnHelper.accessor('percentage', { header: '% of Total', footer: queryResult.totalResult?.percentage }),
  ];

  return (
    <>
      <Report.Base
        title={'Call Triage - Total Count of Dispositions by User'}
        columns={resultColumns}
        fetchUrl={`${api.baseUrl}/api/clients/${currentClientId}/reports/callTriage/dispositionsByUser`}
        reportQuery={reportQuery}
        updateReportQuery={updateReportQuery}
        showFooter={true}
        utc={true}
      >
        <ReportQuery.Personnel reportQuery={reportQuery} updateReportQuery={updateReportQuery} />
      </Report.Base>
    </>
  );
};

export default CallTriageDispositionsByUserReport;
