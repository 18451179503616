import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCirclePlus } from '@imagetrend/fontawesome-pro/pro-solid-svg-icons';
import { useMemo, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import api from '../../common/api';
import { useAppSelector } from '../../common/appState/appStateHooks';
import { selectClientId } from '../../common/appState/currentClientSlice';
import { blackOrWhite } from '../../models/Helpers/ColorHelper';
import { DateTimeFormatter } from '../../models/Helpers/DateHelper';
import { getData } from '../../models/Helpers/FetchHelper';
import { Office } from '../../models/ResourceBridge/Office';
import DestinationTransports from './destinationTransports';
import { haversine_distance } from './destinations/functions';
import StatusIcons from './destinations/statusIcons';
import { CallTriageIncident, DestinationTransport, LatLong } from './types';

interface DestinationCardProps {
  destination: Office;
  index: number;
  small?: boolean;
  incident?: CallTriageIncident;
  isEditable?: boolean;
  onSelect: (d: Office) => void;
}

const formatDate = (date?: string | null) => {
  if (!date) return '';
  try {
    return DateTimeFormatter.format(new Date(date));
  } catch {
    return '';
  }
};

const DestinationCard = ({ destination, index, small, incident, isEditable, ...props }: DestinationCardProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [transports, setTransports] = useState<Array<DestinationTransport>>([]);
  const [showTransportCounts, setShowTransportCounts] = useState(false);
  const clientID = useAppSelector(selectClientId);

  const transportsCount = useMemo(() => {
    return transports
      .map((t) => t.incidentCount)
      .reduce((accumulatedCount, currentCount) => accumulatedCount + currentCount, 0);
  }, [transports]);

  const onTabSelection = async (key: string | null) => {
    switch (key) {
      case 'transports':
        const destinationTransportsResponse = await getData(
          `${api.baseUrl}/api/clients/${clientID}/destinations/${destination.id}/transports`,
          sessionStorage.accessToken
        );
        if (!destinationTransportsResponse.ok) {
          return;
        }

        const transports = (await destinationTransportsResponse.json()) as Array<DestinationTransport>;
        setTransports(transports);
        setShowTransportCounts(true);

        break;
    }
  };

  return (
    <>
      <Card className="m-2" key={index}>
        <Card.Body className="bg-it-light-blue rounded-top">
          <div className="d-flex">
            <div className="w-100">
              <Row>
                {small && (
                  <Col xs="1">
                    <div className="d-inline-block">
                      <Form.Check
                        id={`destination.${index}`}
                        title={destination.agencyName}
                        aria-label={destination.agencyName}
                        disabled={!isEditable}
                        checked={incident?.finalDestinationID === destination.id}
                        onChange={() => props.onSelect(destination)}
                        value={'Select'}
                      />
                      {incident?.latitude !== undefined && incident?.longitude !== undefined && (
                        <div className="value">{`${haversine_distance(
                          incident as LatLong,
                          destination as LatLong
                        ).toFixed(2)} mi`}</div>
                      )}
                    </div>
                  </Col>
                )}
                <Col xs="3">
                  <div className="d-inline-block">
                    <label htmlFor={`destination-name-${index}`} className="title">
                      Name
                    </label>
                    <div id={`destination-name-${index}`} className="value">
                      {destination.agencyName}
                    </div>
                  </div>
                </Col>
                <Col xs="2">
                  <div className="d-inline-block">
                    <div className="title">Region</div>
                    <div className="value">{destination.region}</div>
                  </div>
                </Col>
                <Col xs="2">
                  <div className="d-inline-block">
                    <div className="title">County</div>
                    <div className="value">{destination.county === undefined ? '' : destination.county}</div>
                  </div>
                </Col>
                <Col xs="2">
                  <div className="d-inline-block">
                    <div className="title">Diversion</div>
                    <div>
                      {Object.keys(destination.diversion).length > 0 && (
                        <span
                          className={`badge rounded-pill text-wrap text-${blackOrWhite(destination.diversion.color)}`}
                          style={{ backgroundColor: destination.diversion.color }}
                          title={destination.diversion.status_reason}
                        >
                          {destination.diversion.name}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={small ? '2' : '3'}>
                  <div className="d-inline-block">
                    <div className="title">Diversion Updated</div>
                    <div>{formatDate(destination.diversion.updated_on_local)}</div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="d-flex clickable" style={{ alignItems: 'center', width: '28px' }}>
              <FontAwesomeIcon
                className="text-it-dark-gray"
                icon={open ? faCircleMinus : faCirclePlus}
                size="2x"
                onClick={() => setOpen(!open)}
              />
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <Row style={{ marginLeft: '1rem' }}>
            <Col className="pt-2 pb-2" xs="4">
              <div className="title">NEDOCS</div>
              <div>
                {Object.keys(destination.nedoc).length > 0 && (
                  <span
                    className={`badge rounded-pill text-${blackOrWhite(
                      destination.nedoc.color?.includes('#', 0)
                        ? destination.nedoc.color
                        : '#' + destination.nedoc.color
                    )}`}
                    style={{
                      backgroundColor: destination.nedoc.color?.includes('#', 0)
                        ? destination.nedoc.color
                        : '#' + destination.nedoc.color,
                    }}
                  >
                    {destination.nedoc.description}
                  </span>
                )}
              </div>
            </Col>
            <Col className="py-2" xs="4" style={{ paddingLeft: '0' }}>
              <div className="title">NEDOCS Updated</div>
              <div>{formatDate(destination.nedoc.created_on_clientTime)}</div>
            </Col>
            <Col xs={4}>
              <Row>
                <Col xs={4}>
                  <StatusIcons.Trauma level={destination.traumaLevelID} title={destination.traumaLevel} />
                </Col>
                <Col xs={4}>
                  <StatusIcons.Cardiac level={destination.pA_STEMILevelID} title={destination.pA_STEMILevel} />
                </Col>
                <Col xs={4}>
                  <StatusIcons.Stroke level={destination.pA_StrokeLevelID} title={destination.pA_StrokeLevel} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Collapse in={open}>
            <div>
              <Tabs
                justify
                fill
                variant="pills"
                defaultActiveKey={'specialties'}
                onSelect={(eventKey) => onTabSelection(eventKey)}
              >
                <Tab eventKey="specialties" title={'Specialties'}>
                  {destination.specialties.length > 0 && (
                    <Row className="m-0 row-group">
                      {destination.specialties.map((specialty, index) => (
                        <Col
                          key={index}
                          md={small ? 12 : 6}
                          lg={6}
                          className="col-group"
                          style={{
                            backgroundColor: (small && index % 2 === 0) || (!small && index % 4 < 2) ? '' : '#E3F7FF',
                          }}
                        >
                          <Row>
                            <Col>{specialty.name}</Col>
                            <Col className="text-center" xs="3">
                              {/* TODO: This should proooobably be done client-side... */}
                              <span
                                className={`badge rounded-pill text-wrap text-${blackOrWhite(
                                  specialty.color.includes('#', 0) ? specialty.color : '#' + specialty.color
                                )}`}
                                style={{
                                  width: '90%',
                                  backgroundColor: specialty.color.includes('#', 0)
                                    ? specialty.color
                                    : '#' + specialty.color,
                                }}
                              >
                                {specialty.availability_status}
                              </span>
                            </Col>
                            <Col className="text-end">{formatDate(specialty.changed_on)}</Col>
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  )}
                </Tab>
                <Tab
                  eventKey="transports"
                  title={
                    <>
                      Transports
                      {showTransportCounts && (
                        <>
                          {' '}
                          <Badge bg="it-black">{transportsCount}</Badge>
                        </>
                      )}
                    </>
                  }
                >
                  <DestinationTransports destinationId={destination.id} transports={transports} />
                </Tab>
              </Tabs>
            </div>
          </Collapse>
        </Card.Footer>
      </Card>
    </>
  );
};

export default DestinationCard;
