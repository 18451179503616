import './StepButton.css';

import React from 'react';

import clsx from '../classnames';
import StepLabel, { StepLabelProps } from './StepLabel';

export interface StepButtonProps extends StepLabelProps, React.ButtonHTMLAttributes<HTMLButtonElement> {}

const StepButton: React.FC<StepButtonProps> = (props) => {
  const { active, completed, disabled, error, icon, children, className, ...other } = props;

  let childElement: React.ReactNode;
  if (Array.isArray(children) || React.isValidElement(children)) {
    childElement = children;
  } else {
    const stepLabelProps: StepLabelProps = {
      active,
      completed,
      disabled,
      icon,
    };
    childElement = <StepLabel {...stepLabelProps}>{children}</StepLabel>;
  }

  return (
    <button type="button" disabled={disabled} className={clsx('stepper-step-button', className)} {...other}>
      {childElement}
    </button>
  );
};
export default StepButton;
