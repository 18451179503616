// putting these here to avoid lexical errors
export enum ActiveStatus {
  None = 0,
  Active = 1 << 0,
  Inactive = 1 << 1,

  All = ~(~0 << 2),
}

export enum FilterState {
  None = 0,
  Dispatched = 1 << 0,
  EnRoute = 1 << 1,
  OnScene = 1 << 2,
  Transporting = 1 << 3,
  TransportCompleted = 1 << 4,
  Closed = 1 << 5,

  All = ~(~0 << 6),
}

export enum IncidentState {
  Dispatched = 'Dispatched',
  EnRoute = 'EnRoute',
  OnScene = 'On Scene',
  Transporting = 'Transporting',
  TransportCompleted = 'Transport Completed',
  Closed = 'Closed',
}

export const IncidentStateInformation: {
  [key in IncidentState]: {
    name: string;
    bgColor: string;
    textColor: string;
    order: number;
    filterState: FilterState;
  };
} = {
  [IncidentState.Dispatched]: {
    name: 'Dispatched',
    bgColor: 'it-blue',
    textColor: 'it-white',
    order: 1,
    filterState: FilterState.Dispatched,
  },
  [IncidentState.EnRoute]: {
    name: 'En Route',
    bgColor: 'it-green',
    textColor: 'it-white',
    order: 1,
    filterState: FilterState.EnRoute,
  },
  [IncidentState.OnScene]: {
    name: 'On Scene',
    bgColor: 'it-purple',
    textColor: 'it-white',
    order: 1,
    filterState: FilterState.OnScene,
  },
  [IncidentState.Transporting]: {
    name: 'Transporting',
    bgColor: 'it-gold',
    textColor: 'it-black',
    order: 1,
    filterState: FilterState.Transporting,
  },
  [IncidentState.TransportCompleted]: {
    name: 'Transport Completed',
    bgColor: 'it-brown',
    textColor: 'it-white',
    order: 1,
    filterState: FilterState.TransportCompleted,
  },
  [IncidentState.Closed]: {
    name: 'Closed',
    bgColor: 'it-black',
    textColor: 'it-white',
    order: 2,
    filterState: FilterState.Closed,
  },
};
