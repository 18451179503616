import React from "react";
import { ApiClient } from "../../services/api/ApiClient";
import { AppContext } from "../appContext/appContext";

export function useApiClient(): ApiClient {
    const appContextValue = React.useContext(AppContext);
    const apiClient = appContextValue?.apiClient;
    if (!apiClient) {
        throw new Error("Missing api client.");
    }
    return apiClient;
}
