import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Logo from '../logo/logo';
import LoginForm from './loginForm';

export default function LoginPage() {
  return (
    <>
      <Row className="mx-0 mb-3">
        <Col className="text-end">
          <Logo />
        </Col>
      </Row>
      <div className="container">
        <Row>
          <Col className="d-flex justify-content-center">
            <LoginForm />
          </Col>
        </Row>
      </div>
    </>
  );
}
