import React, { PropsWithChildren, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';

interface StickyMainContentProps {
  containerCssClass?: string;
}

const StickyMainContent = ({
  children,
  containerCssClass = '',
  ...props
}: PropsWithChildren<StickyMainContentProps> & React.HTMLAttributes<{}>) => {
  const mandatoryCssClasses = 'sticky-top mx-0';

  const [appliedContainerClasses, setAppliedcontainerClasses] = useState(
    `${mandatoryCssClasses} ${containerCssClass}`.trim()
  );

  useEffect(() => {
    const classes = `${mandatoryCssClasses} ${containerCssClass}`.trim();

    if (classes !== containerCssClass) {
      setAppliedcontainerClasses(classes);
    }
  }, [containerCssClass, setAppliedcontainerClasses]);

  return (
    <Row className={`${appliedContainerClasses}`} {...props}>
      {children}
    </Row>
  );
};

export default StickyMainContent;
