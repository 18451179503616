import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import { Link } from 'react-router-dom';

export default function IncidentCard(props: {
  icon: IconProp;
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>;
  divClassNames: string;
  title: string;
  id: string;
  address: string;
  location: string;
  status: string;
  time: string;
  responseStatus: string;
  unit: string;
  to: number;
  onClick: any;
}): JSX.Element {
  return (
    <>
      <Link
        key={props.id}
        to={{
          pathname: `/incidentDetails/${props.to}`,
        }}
        style={{ textDecoration: 'none', color: 'inherit' }}
        onClick={props.onClick}
      >
        <div
          className={
            props.divClassNames + ' pt-2 pe-3 mb-2 ' + (props.responseStatus === 'Closed' ? 'cardBgInactive' : 'cardBg')
          }
        >
          <Row>
            <Col sm={3} xl={2} className="mx-2">
              <Stack gap={2}>
                <FontAwesomeIcon icon={props.icon} {...props.iconProps}></FontAwesomeIcon>
                <p className="card-status">{props.responseStatus}</p>
              </Stack>
            </Col>
            <Col>
              <p className="card-title">{props.title}</p>
              <p className="card-text">{props.address}</p>
              <p className="card-location">{props.location}</p>
            </Col>
            <Col>
              <p className="card-title text-end">{props.time}</p>
              <p className="card-text text-end">{props.id}</p>
              <p className="card-subtext text-end">{props.unit}</p>
            </Col>
          </Row>
        </div>
      </Link>
    </>
  );
}
