import * as yup from 'yup';
import { SignalOption } from '../../../common/types';
import { Office } from '../../../models/ResourceBridge/Office';
import {
  CallTriageComment,
  CallTriageCommonPlace,
  CallTriageIncident,
  CallTriageIncidentDispositionID,
  CallTriageIncidentSubtype,
  CallTriagePatient,
  RequestType,
} from '../types';

export enum ActionTypes {
  addNote = 'addNote',
  setEventID = 'setEventID',
  setPatientAge = 'setPatientAge',
  setPatientAgeUnits = 'setPatientAgeUnits',
  setPatientGender = 'setPatientGender',
  setPatients = 'setPatients',
  setIncident = 'setIncident',
  setLocationData = 'setLocationData',
  setIncidentLocation = 'setIncidentLocation',
  setDestination = 'setDestination',
  setIncidentType = 'setIncidentType',
  setIncidentSubtype = 'setIncidentSubtype',
  setIncidentStatus = 'setIncidentStatus',
  setIncidentDisposition = 'setIncidentDisposition',
  setIncidentCommonPlace = 'setIncidentCommonPlace',
  setIncidentAgency = 'setIncidentAgency',
  setPrimaryResourceID = 'setPrimaryResourceID',
}

export type CallTriageData = {
  incident?: CallTriageIncident;
  patient?: CallTriagePatient;
  comment?: CallTriageComment;
  index?: number;
};

export const initialPatient: CallTriagePatient = {
  incidentPatientID: 0,
  age: null,
  ageUnitsID: null,
  genderID: null,
  deleted: false,
};

export const initialIncident: CallTriageIncident = {
  eventID: '',
  incidentNumber: '00000000000',
  incidentLocation: '',
  incidentSubtypeID: null,
  primaryResourceID: '',
  finalDestinationID: null,
  finalDestinationName: null,
  requestedDestinationName: null,
  requestedDestinationID: null,
  requestedDestinationType: null,
  patients: [
    {
      ...initialPatient,
    },
  ],
  comments: [],
  incidentID: null,
  incidentStatusID: null,
  incidentDispositionID: null,
  longitude: null,
  latitude: null,
  incidentCommonPlaceID: null,
  incidentTypeID: null,
  primaryResourceAgencyID: null,
};

// Typescript Magic
type PartialShape<T> = {
  [P in keyof T]?: yup.Schema;
};

const shape: PartialShape<CallTriageIncident> = {
  finalDestinationID: yup
    .string()
    .nullable()
    .when('incidentDispositionID', {
      is: (incidentDispositionID: number) =>
        incidentDispositionID === CallTriageIncidentDispositionID.DestinationAssigned,
      then: (schema) => schema.required('Destination required by Disposition'),
    }),
  requestedDestinationID: yup
    .string()
    .nullable()
    .when(['incidentDispositionID', 'requestedDestinationType'], {
      is: (incidentDispositionID: number, requestedDestinationType: RequestType | null) =>
        incidentDispositionID === CallTriageIncidentDispositionID.DestinationAssigned && !requestedDestinationType,
      then: (schema) => schema.required('Requested Destination required by Disposition'),
    }),
  requestedDestinationType: yup
    .string()
    .nullable()
    .when(['incidentDispositionID', 'requestedDestinationID'], {
      is: (incidentDispositionID: number, requestedDestinationID: string | null) =>
        incidentDispositionID === CallTriageIncidentDispositionID.DestinationAssigned && !requestedDestinationID,
      then: (schema) => schema.required('Requested Destination required by Disposition'),
    }),
  incidentDispositionID: yup
    .number()
    .required('Required')
    .when('finalDestinationID', {
      is: (finalDestinationID: string | null) => !finalDestinationID,
      then: (schema) =>
        schema.test(
          'finalDestinationNotNull',
          'Destination required by Disposition',
          (value) => value !== CallTriageIncidentDispositionID.DestinationAssigned
        ),
    })
    .when(['requestedDestinationID', 'requestedDestinationType'], {
      is: (requestedDestinationID: string | null, requestedDestinationType: RequestType | null) =>
        !requestedDestinationID && !requestedDestinationType,
      then: (schema) =>
        schema.test(
          'requestedDestinationNotNull',
          'Requested Destination required by Disposition',
          (value) => value !== CallTriageIncidentDispositionID.DestinationAssigned
        ),
    }),
  incidentStatusID: yup.number().required('Required'),
  incidentCommonPlaceID: yup.number().nullable(),
  incidentTypeID: yup.number().required('Required'),
  primaryResourceAgencyID: yup.number().required('Required'),
  primaryResourceID: yup.string().required('Required'),
  latitude: yup.number().required('Required'),
  longitude: yup.number().required('Required'),
  patients: yup.array().of(
    yup.object({
      age: yup.number().typeError('Must be a number').required('Required'),
      ageUnitsID: yup.number().positive().required('Required'),
      genderID: yup.number().positive().required('Required'),
    })
  ),
};

export const incidentSchema = yup.object<CallTriageIncident>().shape(shape, [
  ['incidentDispositionID', 'finalDestinationID'],
  ['incidentDispositionID', 'requestedDestinationType'],
  ['incidentDispositionID', 'requestedDestinationID'],
  ['requestedDestinationID', 'requestedDestinationType'],
]);

export interface IncidentOptions {
  genders: SignalOption<number>[];
  ageUnits: SignalOption<number>[];
  incidentStatuses: SignalOption<number>[];
  incidentDispositions: SignalOption<number>[];
  incidentTypes: SignalOption<number>[];
  incidentSubtypes: (SignalOption<number> & CallTriageIncidentSubtype)[];
  commonPlaces: (SignalOption<number> & CallTriageCommonPlace)[];
  agencies: SignalOption<number>[];
  destinations: (SignalOption<string> & Office)[];
}
