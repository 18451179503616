import Button, { ButtonProps } from 'react-bootstrap/Button';
import Modal, { ModalProps } from 'react-bootstrap/Modal';

interface SignalModalProps {
  id?: string;
  show: boolean;
  headerTitle?: string;
  children?: React.ReactNode;
  confirmText?: string;
  negateText: string;
  confirm?: () => void;
  negate?: () => void;
  toggleShow: () => void;
  modalProps?: ModalProps;
  confirmButtonProps?: ButtonProps;
  negateButtonProps?: ButtonProps;
}

const SignalModal = (props: SignalModalProps) => {
  const id = props.id ?? '0';

  return (
    <>
      <Modal
        {...props.modalProps}
        show={props.show}
        onHide={props.toggleShow}
        centered
        aria-describedby={`modal-body-${id}`}
        aria-labelledby={props.headerTitle ? `modal-title-${id}` : undefined}
      >
        {props.headerTitle && (
          <Modal.Header id={`modal-title-${id}`} closeButton>
            <Modal.Title>{props.headerTitle}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body id={`modal-body-${id}`}>{props.children}</Modal.Body>
        <Modal.Footer>
          {props.confirmText && props.confirm && (
            <>
              <Button
                variant="danger"
                onClick={() => {
                  props.toggleShow();
                  if (props.confirm) {
                    props.confirm();
                  }
                }}
                {...props.confirmButtonProps}
              >
                {props.confirmText}
              </Button>{' '}
            </>
          )}
          <Button
            variant="secondary"
            onClick={() => {
              props.toggleShow();
              if (props.negate) {
                props.negate();
              }
            }}
            {...props.negateButtonProps}
          >
            {props.negateText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SignalModal;
