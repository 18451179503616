import { faAmbulance } from '@imagetrend/fontawesome-pro/pro-solid-svg-icons';
import { LatLng, latLng, LatLngExpression } from 'leaflet';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from '../../common/appState/appStateHooks';
import { selectClientAlias } from '../../common/appState/currentClientSlice';
import { updateActiveStatus, updateStatusFilter } from '../../common/appState/dashboardSettings';
import { MaxUnitNameLength } from '../../common/constants';
import { Incident } from '../../models/Incident';
import StickyMainContent from '../common/stickyMainContent';
import IncidentCard from '../incidentCard/incidentCard';
import { useApiClient } from '../useApiClient/useApiClient';
import { useInterval } from '../useInterval/useInterval';
import './dashboard.scss';
import { ActiveStatus, FilterState, IncidentState, IncidentStateInformation } from './dashboardConsts';
import { ActiveMarker, ClosedMarker } from './markers';
import NoClients from './noClients';
import MapAttribution from './mapAttribution';

const incidentSorter = (a: Incident, b: Incident) => {
  // We want Dispatched incidents first, then Closed, so sort by status descending for now, and then by createdBy timestamp within each group
  // if new statuses are introduced, this will have to be changed to account for them
  if (
    IncidentStateInformation[a.status as IncidentState].order <
    IncidentStateInformation[b.status as IncidentState].order
  ) {
    return -1;
  } else if (
    IncidentStateInformation[a.status as IncidentState].order >
    IncidentStateInformation[b.status as IncidentState].order
  ) {
    return 1;
  } else {
    return new Date(b.createdByDispatchDateTime).getTime() - new Date(a.createdByDispatchDateTime).getTime();
  }
};

export default function EventDetails() {
  // const flame = '/images/fire-flame-curved-solid.svg';
  // const ambulance = '/images/truck-medical-solid.svg';

  const dispatch = useAppDispatch();

  const details = (e: any) => {
    sessionStorage.setItem('details', JSON.stringify(incidentList));
    let time = moment().format('HH:mm');
    let date = moment().format('MM/DD/yyyy');
    sessionStorage.setItem('time', time);
    sessionStorage.setItem('date', date);
  };

  const apiClient = useApiClient();
  const [incidentList, setIncidentList] = useState<Incident[]>([]);
  const [mapCenter, setMapCenter] = useState<LatLng>(latLng([30.2157, -95.514]));
  const [forceRecenter, setForceRecenter] = useState<boolean>(true);
  const [currentIncidentNumber, setCurrentIncidentNumber] = useState('');
  const intervalSeconds = 10;
  const [filter, setFilter] = useState<FilterState>(useAppSelector((state) => state.dashboardSettings.activeState));
  const [activeValue, setActiveValue] = useState<ActiveStatus>(
    useAppSelector((state) => state.dashboardSettings.incidentStatus)
  );
  const [emptyList, setEmptyList] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const clientAlias = useAppSelector(selectClientAlias);

  const handleSelectFilter = (e: FilterState[]) => {
    const filteredStates = e.reduce((a, b) => a | b, FilterState.None);
    dispatch(updateStatusFilter(filteredStates));
    setFilter(filteredStates);
  };

  const handleSelectActive = (e: ActiveStatus[]) => {
    const currentActiveStates = e.reduce((a, b) => a | b, ActiveStatus.None);
    dispatch(updateActiveStatus(currentActiveStates));
    setActiveValue(currentActiveStates);
  };

  const stateFilterOptions = [
    { value: FilterState.Dispatched, label: IncidentStateInformation[IncidentState.Dispatched].name },
    { value: FilterState.EnRoute, label: IncidentStateInformation[IncidentState.EnRoute].name },
    { value: FilterState.OnScene, label: IncidentStateInformation[IncidentState.OnScene].name },
    { value: FilterState.Transporting, label: IncidentStateInformation[IncidentState.Transporting].name },
    { value: FilterState.TransportCompleted, label: IncidentStateInformation[IncidentState.TransportCompleted].name },
    { value: FilterState.Closed, label: IncidentStateInformation[IncidentState.Closed].name },
  ];
  const defaultStateFilterOptions = stateFilterOptions.filter((value) => (value.value & filter) === value.value);
  const statusOptions = [
    {
      value: ActiveStatus.Active,
      label: 'Active',
    },
    {
      value: ActiveStatus.Inactive,
      label: 'Inactive',
    },
  ];
  const defaultStatusOptions = statusOptions.filter((value) => (value.value & activeValue) === value.value);

  let empty;
  if (emptyList) {
    empty = (
      <>
        <h4 className="mx-5 empty">There are no results for your filter selections.</h4>
      </>
    );
  }

  const hasAccessToIncidents = useCallback(() => {
    if (clientAlias.clientID === '0') {
      return false;
    } else {
      return true;
    }
  }, [clientAlias]);

  const updateIncidentData = useCallback(() => {
    let now = new Date();
    let yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);

    const loadIncidentData = async () => {
      if (!hasAccessToIncidents()) {
        return;
      }

      try {
        const data: { [key: string]: any } = {
          limit: 50,
          offset: 0,
          dateTo: now,
          dateFrom: yesterday,
          statuses: [],
        };
        if (activeValue === ActiveStatus.Active) {
          data.active = true;
        } else if (activeValue === ActiveStatus.Inactive) {
          data.active = false;
        }
        if ((filter & FilterState.Dispatched) === FilterState.Dispatched) {
          data.statuses.push(IncidentState.EnRoute);
        }
        if ((filter & FilterState.EnRoute) === FilterState.EnRoute) {
          data.statuses.push(IncidentState.EnRoute);
        }
        if ((filter & FilterState.OnScene) === FilterState.OnScene) {
          data.statuses.push(IncidentState.OnScene);
        }
        if ((filter & FilterState.Transporting) === FilterState.Transporting) {
          data.statuses.push(IncidentState.Transporting);
        }
        if ((filter & FilterState.TransportCompleted) === FilterState.TransportCompleted) {
          data.statuses.push(IncidentState.TransportCompleted);
        }
        if ((filter & FilterState.Closed) === FilterState.Closed) {
          data.statuses.push(IncidentState.Closed);
        }

        const queryResult = await apiClient.listIncidentsAsync(clientAlias, data);

        for (let i = 0; i < queryResult.length; i++) {
          let eventPriority = queryResult[i].eventPriority;
          if (!!eventPriority) {
            let n = eventPriority.indexOf('-');
            eventPriority = eventPriority.substring(-1, n !== -1 ? n : eventPriority.length).replaceAll('/', ' / ');
            queryResult[i].eventPriority = eventPriority;
          } else {
            queryResult[i].eventPriority = (queryResult[i].eventType ?? 'New Event').replaceAll('/', ' / ');
          }
        }

        if ((filter & FilterState.All) !== FilterState.All && (filter & FilterState.None) !== FilterState.None) {
          let flatResult = queryResult.flat();
          flatResult.sort(incidentSorter);
          setIncidentList((prevList) => {
            if (JSON.stringify(prevList) === JSON.stringify(flatResult)) {
              return prevList;
            }
            return flatResult;
          });
          flatResult.length < 1 ? setEmptyList(true) : setEmptyList(false);
        } else {
          setIncidentList((prevList) => {
            let newList = queryResult.sort(incidentSorter);
            if (JSON.stringify(prevList) === JSON.stringify(newList)) {
              return prevList;
            }
            return newList;
          });
          queryResult.length < 1 ? setEmptyList(true) : setEmptyList(false);
        }
        setIncidentList((prevList) => {
          if (JSON.stringify(prevList) === JSON.stringify(queryResult)) {
            return prevList;
          }
          return queryResult;
        });
      } catch (err) {
        console.error('Failed to fetch incident data', err);
        setErrorMessages(['Unable to load incidents']);
      }
    };

    loadIncidentData();
  }, [activeValue, apiClient, filter, setIncidentList, setErrorMessages, clientAlias, hasAccessToIncidents]);

  const updateMapCenter = useCallback(() => {
    const filteredList = incidentList.filter((incident) => {
      return incident.latitude && incident.longitude;
    });
    if (filteredList.length <= 0) {
      setCurrentIncidentNumber('');
      return;
    }
    let firstResult = filteredList[0];
    if (currentIncidentNumber !== firstResult.incidentNumber) {
      setCurrentIncidentNumber(firstResult.incidentNumber ?? '');
      setMapCenter(latLng(firstResult.latitude!, firstResult.longitude!));
      setForceRecenter(true);
    }
  }, [currentIncidentNumber, incidentList, setCurrentIncidentNumber, setMapCenter, setForceRecenter]);

  useEffect(updateIncidentData, [updateIncidentData]);

  useEffect(updateMapCenter, [updateMapCenter]);

  useInterval(updateIncidentData, intervalSeconds * 100);

  useEffect(() => {
    const handleClientSelect = async () => {
      if (!hasAccessToIncidents()) {
        return;
      }

      const queryResult = await apiClient.getClientSettings('', clientAlias.clientID);

      try {
        sessionStorage.setItem('clientObject', JSON.stringify(queryResult));
      } catch (err) {
        console.error('Failed to retrieve client settings', err);
      }
    };

    handleClientSelect();
  }, [apiClient, clientAlias, clientAlias.clientID, hasAccessToIncidents]);

  interface RecenterProps {
    center: LatLngExpression;
    forceRecenter: boolean;
  }

  const Recenter: React.FC<RecenterProps> = (props: RecenterProps) => {
    const map = useMap();
    useEffect(() => {
      if (props.forceRecenter) {
        setForceRecenter(false);
        map.setView(props.center);
      }
    }, [map, props.center, props.forceRecenter]);
    return null;
  };

  return (
    <>
      {errorMessages.length > 0 && (
        <Row className={'mx-1'}>
          <Col className="col-auto mx-auto">
            <Alert dismissible onClose={() => setErrorMessages([])} variant="danger" show={errorMessages.length > 0}>
              <ul>
                {errorMessages.map((errorMessage) => (
                  <li key={errorMessage}>{errorMessage}</li>
                ))}
              </ul>
            </Alert>
          </Col>
        </Row>
      )}
      {hasAccessToIncidents() && (
        <div>
          <StickyMainContent style={{ pointerEvents: 'none' }}>
            <Row className="mainContent-gray mx-0 pb-2">
              <Col>
                <h3 className="pageHeader">Live Time Incidents</h3>
              </Col>
              <Col>
                <Row style={{ pointerEvents: 'all' }}>
                  <Col className="pe-0">
                    <Select
                      options={stateFilterOptions}
                      name="Incident State"
                      className="basic-multi-select"
                      placeholder={'Incident State'}
                      isMulti
                      classNames={{
                        multiValue: (props) =>
                          'badge bg-' +
                          (Object.values(IncidentStateInformation).find((x) => x.filterState === props.data.value)
                            ?.bgColor ?? ''),
                        multiValueLabel: (props) =>
                          'text-wrap text-' +
                          (Object.values(IncidentStateInformation).find((x) => x.filterState === props.data.value)
                            ?.textColor ?? ''),
                        control: () => 'border-it-ems',
                      }}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        multiValueLabel: (props) => ({
                          colors: 'it-white',
                        }),
                      }}
                      onChange={(e) => handleSelectFilter(e.map((key) => key.value))}
                      defaultValue={defaultStateFilterOptions}
                    />
                  </Col>
                  <Col className="pe-0">
                    <Select
                      options={statusOptions}
                      name="Incident Status"
                      className="basic-multi-select"
                      placeholder={'Incident Status'}
                      isMulti
                      isClearable
                      classNames={{
                        control: () => 'border-it-ems',
                      }}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      onChange={(e) => handleSelectActive(e.map((key) => key.value))}
                      defaultValue={defaultStatusOptions}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Col md={12} lg={{ span: 6, offset: 6 }} style={{ pointerEvents: 'all' }}>
              <MapContainer center={mapCenter} zoom={11} scrollWheelZoom={true} className="map">
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {incidentList.map((data) => (
                  <>
                    {data.latitude && data.longitude && (
                      <Marker
                        key={data.incidentID}
                        position={[data.latitude, data.longitude]}
                        icon={
                          data.status === IncidentState.Closed
                            ? ClosedMarker({
                                className: 'text-' + IncidentStateInformation[data.status as IncidentState].bgColor,
                              })
                            : ActiveMarker({
                                className: 'text-' + IncidentStateInformation[data.status as IncidentState].bgColor,
                              })
                        }
                      >
                        <Popup>
                          {`${data.streetNumber === '0' || null ? '' : data.streetNumber} ${
                            data.streetPrefix == null ? '' : data.streetPrefix
                          } ${data.streetName == null ? '' : data.streetName} ${
                            data.streetType == null ? '' : data.streetType
                          } ${data.locationName ? `@ ${data.locationName}` : ''}`}
                          <br />
                          {`${data.city}, ${data.state}`}
                          <br />
                          {data.status} |{' '}
                          {data.unitNames.length < MaxUnitNameLength ? data.unitNames : 'Click for units'}
                        </Popup>
                      </Marker>
                    )}
                  </>
                ))}
                <Recenter center={mapCenter} forceRecenter={forceRecenter} />
                <MapAttribution />
              </MapContainer>
            </Col>
          </StickyMainContent>

          <Row className="mx-0">
            <Col lg={6} className={'signal-incident--col'}>
              {incidentList.map((data) => {
                return (
                  <IncidentCard
                    icon={faAmbulance}
                    iconProps={{
                      size: '2x',
                      className: 'text-it-black',
                    }}
                    divClassNames={'border-' + IncidentStateInformation[data.status as IncidentState].bgColor}
                    key={data.incidentID}
                    title={
                      data.eventPriority === '1 ' ||
                      data.eventPriority === '2 ' ||
                      data.eventPriority === '3 ' ||
                      data.eventPriority === '4 ' ||
                      data.eventPriority === undefined
                        ? 'Unspecified Event Type'
                        : data.eventPriority
                    }
                    responseStatus={data.status}
                    unit={data.unitNames.length < MaxUnitNameLength ? data.unitNames : 'Click for units'}
                    id={data.incidentNumber ?? 'Unknown'}
                    address={`${data.streetNumber === '0' || null ? '' : data.streetNumber} ${
                      data.streetPrefix == null ? '' : data.streetPrefix
                    } ${data.streetName == null ? '' : data.streetName} ${
                      data.streetType == null ? '' : data.streetType
                    } ${data.locationName ? `@ ${data.locationName}` : ''}`}
                    location={`${data.city}, ${data.state}`}
                    status="Not Posted"
                    time={moment(data.createdByDispatchDateTime).format('HH:mm')}
                    to={data.incidentID}
                    onClick={details}
                  />
                );
              })}
              {empty}
            </Col>
          </Row>
        </div>
      )}
      {!hasAccessToIncidents() && <NoClients></NoClients>}
    </>
  );
}
