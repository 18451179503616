// Loosely based on https://github.com/JedWatson/classnames

/**
 * A simple JavaScript utility for conditionally joining classNames together.
 *
 * @param classes Any number of arguments which can be a string or object.
 */
export default function classNames(...args: ClassValue[]): string {
  const classes: Array<string | number> = [];
  for (let i = 0; i < args.length; i++) {
    const arg = args[i];
    if (!arg) continue;

    if (typeof arg === 'string' || typeof arg === 'number') {
      classes.push(arg);
    } else if (Array.isArray(arg) && arg.length) {
      const inner = classNames(...arg);
      if (inner) {
        classes.push(inner);
      }
    } else if (typeof arg === 'object') {
      for (const key of Object.keys(arg)) {
        if ((arg as any)[key]) {
          classes.push(key);
        }
      }
    }
  }
  return classes.join(' ');
}

export type ClassValue = string | number | ClassDictionary | ClassArray | undefined | null | false;

interface ClassDictionary {
  [id: string]: boolean | undefined | null;
}

interface ClassArray extends Array<ClassValue> {}
