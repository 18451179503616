import { ImmerReducer } from 'use-immer';
import { ReducerAction } from '../../common/reducerAction';

export interface OffsetLimit {
  offset: number;
  limit: number;
}

export enum PaginationQuery {
  offset = 'offset',
  limit = 'limit',
}

export const DefaultPagination = {
  limit: 50,
  offset: 0,
};

export const PaginationReducer: ImmerReducer<OffsetLimit, ReducerAction<OffsetLimit>> = (draft, action) => {
  switch (action.type) {
    case PaginationQuery.offset:
      draft.offset = action.data.offset;
      break;
    case PaginationQuery.limit:
      draft.limit = action.data.limit;
      break;
  }
};
