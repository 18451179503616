import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faPencil, faXmark } from '@imagetrend/fontawesome-pro/pro-solid-svg-icons';
import { faFloppyDisk, faPrintMagnifyingGlass } from '@imagetrend/fontawesome-pro/sharp-solid-svg-icons';
import { useJsApiLoader } from '@react-google-maps/api';
import { Field, FieldArray, FormikErrors, FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../common/appState/appStateHooks';
import { selectClientId } from '../../../common/appState/currentClientSlice';
import routes from '../../../common/routesDefinitions';
import { SignalOption, SignalOptionGroup, getSignalOption, getSignalOptionFromGroup } from '../../../common/types';
import { CommentDateTimeFormatter, dateTimeUnknownUTCToLocal } from '../../../models/Helpers/DateHelper';
import { AuthenticatedSignalUser } from '../../../models/LoggedInUser';
import SignalForm from '../../common/signalForm';
import SignalModal from '../../common/signalModal';
import { SignalSingleSelect } from '../../common/signalSelect';
import { useApiClient } from '../../useApiClient/useApiClient';
import { haversine_distance } from '../destinations/functions';
import { libraries } from '../googleMaps/googleApiLoader';
import SignalAutocomplete from '../googleMaps/signalAutocomplete';
import {
  CallTriageComment,
  CallTriageCommonPlace,
  CallTriageIncident,
  CallTriagePatient,
  DestinationRequestTypeOptions,
  RequestType,
} from '../types';
import CallTriageIncidentAuditLog from './incidentAuditLog';
import { IncidentOptions, incidentSchema, initialIncident, initialPatient } from './incidentDetails.types';
import './incidentInformation.scss';

interface CallTriageIncidentInformationProps {
  isCreate: boolean;
  isEditable: boolean;
  setEditable: React.Dispatch<React.SetStateAction<boolean>>;
  options: IncidentOptions;
  googleMapsApiKey: string;
  formik: FormikProps<CallTriageIncident>;
}

const incidentStorageID = 'callTriageIncident';

enum SaveStatus {
  StartSaving,
  Saving,
  NotSaving,
}

const CallTriageIncidentInformation = ({
  isCreate,
  isEditable,
  setEditable,
  options,
  formik: {
    handleSubmit,
    handleChange,
    handleBlur,
    values: incident,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    setValues,
    ...formik
  },
  googleMapsApiKey,
}: CallTriageIncidentInformationProps) => {
  const apiClient = useApiClient();
  const clientId = useAppSelector(selectClientId);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const noteInputRef = useRef<HTMLInputElement>(null);
  const user = JSON.parse(sessionStorage.user) as AuthenticatedSignalUser;
  const [loadedIncident, setLoadedIncident] = useState<CallTriageIncident | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<SaveStatus>(SaveStatus.NotSaving);

  const { isLoaded: mapJsLoaded } = useJsApiLoader({
    libraries: libraries,
    id: 'google-map-api-script',
    googleMapsApiKey: googleMapsApiKey,
  });

  useEffect(() => {
    const handleClientSelect = async () => {
      const queryResult = await apiClient.getClientSettings('', clientId);

      try {
        sessionStorage.setItem('clientObject', JSON.stringify(queryResult));
      } catch (err) {
        console.error('Failed to retrieve client settings', err);
      }
    };

    handleClientSelect();
  }, [apiClient, clientId]);

  useEffect(() => {
    // Add client checking?
    const loadIncident = async () => {
      if (isCreate) {
        const callTriageIncident = sessionStorage.getItem(incidentStorageID);
        if (!callTriageIncident) return;
        const loadedIncident = JSON.parse(callTriageIncident);
        setValues(loadedIncident);
        return;
      }
      if (!id) return;
      const fetchedIncident = await apiClient.Incidents.getIncident(clientId, id);
      setLoadedIncident({
        ...initialIncident,
        ...fetchedIncident,
      });

      setValues({
        ...initialIncident,
        ...fetchedIncident,
      });
    };
    loadIncident();
  }, [apiClient, clientId, setValues, id, isCreate]);

  // Save the incident as you go.
  useEffect(() => {
    if (isCreate && JSON.stringify(incident) !== JSON.stringify(initialIncident)) {
      // @TODO: Add in client ID, time, and user checking
      sessionStorage.setItem(incidentStorageID, JSON.stringify(incident));
    }
  }, [incident, isCreate]);

  const locationFromLatLng = useMemo(() => {
    return `(${incident.latitude}, ${incident.longitude})`;
  }, [incident.latitude, incident.longitude]);

  const ensureCancel = () => {
    if (JSON.stringify(loadedIncident) === JSON.stringify(incident)) {
      cancelEdit();
    } else {
      setShowModal(true);
    }
  };

  const cancelEdit = () => {
    setValues({
      ...initialIncident,
      ...loadedIncident,
    });
    setEditable(false);
  };

  // This allows us to start saves in multiple ways without collision.
  useEffect(() => {
    const save = async () => {
      try {
        const comments = [...incident.comments];
        if (noteInputRef.current?.value) {
          const newNote: CallTriageComment = {
            comment: noteInputRef.current.value,
            createdBy: user.awareUserID,
            createdOnUtc: new Date(),
          };
          if (id) {
            const savedNote = await apiClient.Incidents.postIncidentNote(clientId, id, newNote);
            comments.push(savedNote);
          } else {
            comments.push(newNote);
          }
          // In case the save fails, we still have it in the state.
          setFieldValue('comments', comments);

          noteInputRef.current.value = '';
        }
        const savingIncident: CallTriageIncident = {
          ...incident,
          comments: comments,
        };
        if (isCreate) {
          savingIncident.startBy = user.awareUserID;
          savingIncident.startOnUtc = new Date();
          savingIncident.createdBy = user.awareUserID;
          savingIncident.createdOnUtc = new Date();
          savingIncident.patients.forEach((x) => {
            x.createdOnUtc = new Date();
            x.createdBy = user.awareUserID;
          });
          const completedStatus = options.incidentStatuses.find((x) => x.label === 'Completed')?.value;
          const newStatus = options.incidentStatuses.find((x) => x.label === 'New')?.value;
          savingIncident.incidentStatusID =
            incident.incidentStatusID === newStatus && completedStatus ? completedStatus : incident.incidentStatusID;
          const savedIncident = await apiClient.Incidents.postIncident(clientId, savingIncident);
          navigate(`${routes.callTriageIncident}/${savedIncident.incidentID}`);
          sessionStorage.setItem(incidentStorageID, JSON.stringify(initialIncident));
        } else {
          const completedStatus = options.incidentStatuses.find((x) => x.label === 'Completed')?.value;
          const updatedStatus = options.incidentStatuses.find((x) => x.label === 'Updated')?.value;
          savingIncident.incidentStatusID =
            incident.incidentStatusID === completedStatus && updatedStatus ? updatedStatus : incident.incidentStatusID;
          await apiClient.Incidents.putIncident(clientId, savingIncident);
          setLoadedIncident(savingIncident);
        }
        setEditable(false);
      } catch {
        throw new Error('Unable to save incident');
      } finally {
        setIsSaving(SaveStatus.NotSaving);
      }
    };

    if (isSaving === SaveStatus.StartSaving) {
      setIsSaving(SaveStatus.Saving);
      save();
    }
  }, [
    isSaving,
    apiClient.Incidents,
    clientId,
    id,
    incident,
    isCreate,
    navigate,
    options.incidentStatuses,
    setEditable,
    setFieldValue,
    user.awareUserID,
  ]);

  const readyToSave = useMemo(() => {
    return incidentSchema.isValidSync(incident);
  }, [incident]);

  const startSave = useCallback(() => {
    if (!isEditable || isSaving !== SaveStatus.NotSaving) return;
    if (readyToSave) {
      setIsSaving(SaveStatus.StartSaving);
    } else {
      // Okay, this is kinda cheating, since our save is embedded here, and our submit has nothing there.
      formik.submitForm();
    }
  }, [isEditable, isSaving, setIsSaving, readyToSave, formik]);

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      function isMac() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /macintosh|mac os x/i.test(userAgent);
      }

      if (!isEditable) return;

      if ((isMac() && e.metaKey && e.key === 's') || (!isMac() && e.ctrlKey && e.key === 's')) {
        e.preventDefault();
        startSave();
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // We only need to run this once, no matter what React thinks it thinks
  }, [isEditable, startSave]);

  useEffect(() => {
    const loadResources = async () => {
      const events = await apiClient.getAllEvents();
      const callTriageEvent = events.find((x) => {
        return x.name === 'Call Triage';
      });
      if (callTriageEvent) {
        setFieldValue('eventID', callTriageEvent.eventID);
      } else {
        throw Error('Unable to load Call Triage Event');
      }
    };
    loadResources();
  }, [apiClient, setFieldValue]);

  const handleIncidentType = (option: SignalOption<number> | null) => {
    const incidentTypeID = option?.value ?? null;
    if (incident.incidentTypeID !== incidentTypeID) {
      setFieldValue('incidentTypeID', incidentTypeID);
      setFieldValue('incidentSubtypeID', null);
    }
  };

  const availableSubTypes = useMemo(() => {
    return options.incidentSubtypes.filter((x) => x.incidentTypeID === incident.incidentTypeID);
  }, [incident.incidentTypeID, options.incidentSubtypes]);

  useEffect(() => {
    if (!noteInputRef.current) return;

    // Attach this to the specific node.
    const input = noteInputRef.current;

    const sendOnEnter = (event: KeyboardEvent) => {
      if (event.key !== 'Enter') return;

      const createNote = async () => {
        if (!noteInputRef.current?.value) return;

        const newNote: CallTriageComment = {
          comment: noteInputRef.current.value,
          createdBy: user.awareUserID,
          createdOnUtc: new Date(),
        };
        const notes: CallTriageComment[] = [];
        notes.push(...incident.comments);
        if (!isCreate) {
          if (!id) throw Error('Unable to save note');

          const savedNote = await apiClient.Incidents.postIncidentNote(clientId, id, newNote);
          notes.push(savedNote);

          if (loadedIncident) {
            setLoadedIncident({
              ...loadedIncident,
              comments: notes,
            });
          }
        } else {
          notes.push(newNote);
        }
        setFieldValue('comments', notes);
        noteInputRef.current.value = '';
      };
      createNote();
      event.preventDefault();
    };

    input.addEventListener('keyup', sendOnEnter);
    // On re-render, remove the event listener.
    return function cleanup() {
      input.removeEventListener('keyup', sendOnEnter);
    };
  });

  const handlePlaceChange = (place: google.maps.places.PlaceResult) => {
    setValues({
      ...incident,
      latitude: place.geometry?.location?.lat() ?? null,
      longitude: place.geometry?.location?.lng() ?? null,
      incidentLocation: place.formatted_address ?? '',
    });
  };

  const handleCommonPlaceChange = (commonPlace: (SignalOption<number> & CallTriageCommonPlace) | null) => {
    const newValues = {
      ...incident,
      incidentCommonPlaceID: commonPlace?.commonPlaceID || null,
    };
    if (commonPlace) {
      newValues.latitude = commonPlace.latitude;
      newValues.longitude = commonPlace.longitude;
    }
    setValues(newValues);
  };

  const sortedNotes = useMemo(() => {
    const comments = [...incident.comments];
    comments.forEach((comment) => {
      // Sets the first and last name on newly created comments.
      if (comment.createdBy === user.awareUserID) {
        comment.createdByFirstname = user.firstname;
        comment.createdByLastname = user.lastname;
      }
    });

    comments.sort((a, b) => {
      const a_date = dateTimeUnknownUTCToLocal(a.createdOnUtc);
      const b_date = dateTimeUnknownUTCToLocal(b.createdOnUtc);
      return b_date.getTime() - a_date.getTime();
    });
    return comments;
  }, [incident.comments, user.awareUserID, user.firstname, user.lastname]);

  const sortedDestinations = useMemo(() => {
    const destinations = [...options.destinations];
    if (incident.latitude != null && incident.longitude != null) {
      destinations.sort((a, b) =>
        haversine_distance(a, { latitude: incident.latitude!, longitude: incident.longitude! }) >
        haversine_distance(b, { latitude: incident.latitude!, longitude: incident.longitude! })
          ? 1
          : -1
      );
    }

    return destinations;
  }, [options.destinations, incident?.latitude, incident?.longitude]);

  const handleRequestedDestination = (selected: SignalOption<RequestType | string> | null) => {
    if (!selected) {
      setValues({
        ...incident,
        requestedDestinationID: null,
        requestedDestinationName: null,
        requestedDestinationType: null,
      });
    } else if (selected.value in RequestType) {
      setValues({
        ...incident,
        requestedDestinationID: null,
        requestedDestinationName: null,
        requestedDestinationType: selected.value as RequestType,
      });
    } else {
      const destination = sortedDestinations.find((x) => x.id === selected.value);
      setValues({
        ...incident,
        requestedDestinationID: destination?.id ?? null,
        requestedDestinationName: destination?.agencyName ?? null,
        requestedDestinationType: null,
      });
    }
  };

  const destinationTypes = useMemo(() => {
    const requestTypes = Object.keys(DestinationRequestTypeOptions).map((x) => ({
      value: parseInt(x),
      label: DestinationRequestTypeOptions[parseInt(x) as RequestType].label,
    }));
    const destinationTypeGroup: SignalOptionGroup<string | RequestType>[] = [];
    destinationTypeGroup.push({
      label: 'Specific Destination',
      options: sortedDestinations.map((x) => ({
        label: x.agencyName,
        value: x.id,
      })),
    });
    destinationTypeGroup.push({
      label: 'Generic Destination',
      options: requestTypes,
    });
    return destinationTypeGroup;
  }, [sortedDestinations]);

  const requestedDestination = incident.requestedDestinationType || incident.requestedDestinationID;

  return (
    <>
      <Col className={'d-flex mb-1'}>
        <h1 className="my-auto h5">Incident Information</h1>
        <Row className={'ms-auto g-1'}>
          {!isEditable && (
            <>
              {id && (
                <Col className="ms-auto col-auto">
                  <Button variant="it-dark-blue" color="it-eggshell" onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={faPrintMagnifyingGlass} /> Audit Log
                  </Button>
                  <SignalModal
                    id={'audit-log'}
                    show={showModal}
                    headerTitle={'Audit Log'}
                    negateText={'Close'}
                    toggleShow={() => setShowModal(false)}
                  >
                    <CallTriageIncidentAuditLog id={id} />
                  </SignalModal>
                </Col>
              )}
              <Col className="ms-auto col-auto">
                <Button className="border-it-black" variant={'it-golder'} onClick={() => setEditable(true)}>
                  <FontAwesomeIcon icon={faPencil} /> Edit
                </Button>
              </Col>
            </>
          )}
          {isEditable && (
            <>
              <Col className="ms-auto col-auto">
                <Button
                  variant={'success'}
                  onClick={() => startSave()}
                  disabled={!readyToSave || isSaving !== SaveStatus.NotSaving}
                >
                  <FontAwesomeIcon icon={faFloppyDisk} /> Save
                </Button>
              </Col>
              {!isCreate && (
                <Col className="ms-auto col-auto">
                  <Button variant={'danger'} onClick={() => ensureCancel()}>
                    <FontAwesomeIcon icon={faBan} /> Cancel
                  </Button>
                  <SignalModal
                    id={'unsaved'}
                    show={showModal}
                    headerTitle={'Unsaved Information'}
                    confirmText={'Cancel Anyway'}
                    negateText={'Do Not Cancel'}
                    confirm={() => {
                      cancelEdit();
                    }}
                    toggleShow={() => setShowModal(false)}
                  >
                    You have unsaved information.
                  </SignalModal>
                </Col>
              )}
            </>
          )}
        </Row>
      </Col>
      <Col>
        <Form>
          <Card className="mb-3" bg="it-gray">
            <Card.Body className="rounded">
              <Form.Group>
                <Row className="gy-3">
                  <Col className="col-auto">
                    <Form.Label htmlFor="incidentNumber">Incident #</Form.Label>
                    <Form.Control
                      id="incidentNumber"
                      name="incidentNumber"
                      disabled
                      htmlSize={11}
                      value={incident.incidentNumber}
                    />
                  </Col>
                  <Col className="col-auto">
                    <SignalForm.Label required htmlFor="incidentStatusID">
                      Incident Status
                    </SignalForm.Label>
                    <Field
                      id="incidentStatusID"
                      name="incidentStatusID"
                      component={SignalForm.Select}
                      isInvalid={!!errors.incidentStatusID && touched.incidentStatusID}
                      feedback={errors.incidentStatusID}
                      isClearable
                      isDisabled={!isEditable}
                      options={options.incidentStatuses}
                    />
                  </Col>
                  <Col className="col-auto">
                    <SignalForm.Label required htmlFor="incidentDispositionID">
                      Disposition
                    </SignalForm.Label>
                    <Field
                      id="incidentDispositionID"
                      name="incidentDispositionID"
                      component={SignalForm.Select}
                      isInvalid={!!errors.incidentDispositionID && touched.incidentDispositionID}
                      feedback={errors.incidentDispositionID}
                      isClearable
                      isDisabled={!isEditable}
                      options={options.incidentDispositions}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Card.Body>
          </Card>
          <Row className="gy-3 mb-3">
            <Col>
              <Form.Group>
                <SignalForm.Label required htmlFor="primaryResourceAgencyID">
                  Primary Agency
                </SignalForm.Label>
                <Field
                  id="primaryResourceAgencyID"
                  name="primaryResourceAgencyID"
                  component={SignalForm.Select}
                  isInvalid={!!errors.primaryResourceAgencyID && touched.primaryResourceAgencyID}
                  feedback={errors.primaryResourceAgencyID}
                  isDisabled={!isEditable}
                  options={options.agencies}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <SignalForm.Label required htmlFor="primaryResourceID">
                  Primary Resource ID
                </SignalForm.Label>
                <Field
                  as={Form.Control}
                  isInvalid={!!errors.primaryResourceID && touched.primaryResourceID}
                  id="primaryResourceID"
                  name="primaryResourceID"
                  disabled={!isEditable}
                  placeholder="Primary Resource ID"
                />
                <Form.Control.Feedback type="invalid">{errors.primaryResourceID}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {isEditable ? (
            <Tabs
              justify
              fill
              variant="pills"
              defaultActiveKey={
                !!incident.incidentCommonPlaceID && !incident.incidentLocation ? 'common-place' : 'location'
              }
            >
              <Tab className="mb-3" eventKey="location" title={'Address'}>
                <Form.Group>
                  <SignalForm.Label htmlFor="incidentLocation">Address</SignalForm.Label>
                  <SignalAutocomplete handlePlaceChange={handlePlaceChange} mapLoaded={mapJsLoaded}>
                    <Form.Control
                      id="incidentLocation"
                      name="incidentLocation"
                      disabled={!isEditable}
                      placeholder={incident.latitude && incident.longitude ? locationFromLatLng : 'Enter a Location'}
                      value={incident.incidentLocation}
                      onChange={handleChange}
                    />
                  </SignalAutocomplete>
                </Form.Group>
              </Tab>
              <Tab className="mb-3" eventKey="common-place" title="Common Place">
                <Form.Group>
                  <SignalForm.Label htmlFor="incidentCommonPlaceID">Common Place</SignalForm.Label>
                  <SignalSingleSelect
                    name="incidentCommonPlaceID"
                    inputId="incidentCommonPlaceID"
                    isClearable
                    isInvalid={!!errors.incidentCommonPlaceID && touched.incidentCommonPlaceID}
                    feedback={errors.incidentCommonPlaceID}
                    value={getSignalOption(incident.incidentCommonPlaceID, options.commonPlaces)}
                    options={options.commonPlaces}
                    onChange={(x) =>
                      handleCommonPlaceChange(x as (SignalOption<number> & CallTriageCommonPlace) | null)
                    }
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </Tab>
            </Tabs>
          ) : (
            <>
              {!!incident.incidentCommonPlaceID && !incident.incidentLocation ? (
                <Form.Group className="mb-3">
                  <SignalForm.Label htmlFor="incidentCommonPlaceID">Common Place</SignalForm.Label>
                  <Field
                    id="incidentCommonPlaceID"
                    name="incidentCommonPlaceID"
                    component={SignalForm.Select}
                    isInvalid={!!errors.incidentCommonPlaceID && touched.incidentCommonPlaceID}
                    feedback={errors.incidentCommonPlaceID}
                    isDisabled={!isEditable}
                    options={options.commonPlaces}
                  />
                </Form.Group>
              ) : (
                <Form.Group className="mb-3">
                  <SignalForm.Label htmlFor="incidentLocation">Location</SignalForm.Label>
                  <SignalAutocomplete handlePlaceChange={handlePlaceChange} mapLoaded={mapJsLoaded}>
                    <Form.Control
                      id="incidentLocation"
                      name="incidentLocation"
                      disabled={!isEditable}
                      placeholder={incident.latitude && incident.longitude ? locationFromLatLng : 'Enter a Location'}
                      value={incident.incidentLocation}
                      onChange={handleChange}
                    />
                  </SignalAutocomplete>
                </Form.Group>
              )}
            </>
          )}
          <Form.Group className="mb-3">
            <SignalForm.Label required htmlFor="incidentTypeID">
              Incident Type
            </SignalForm.Label>
            <Field
              id="incidentTypeID"
              name="incidentTypeID"
              component={SignalForm.Select}
              isInvalid={!!errors.incidentTypeID && touched.incidentTypeID}
              feedback={errors.incidentTypeID}
              isDisabled={!isEditable}
              options={options.incidentTypes}
              onChange={handleIncidentType}
            />
          </Form.Group>
          {availableSubTypes.length > 0 && (
            <Form.Group className="mb-3">
              <SignalForm.Label htmlFor="incidentSubtypeID">Incident Subtype</SignalForm.Label>
              <Field
                id="incidentSubtypeID"
                name="incidentSubtypeID"
                component={SignalForm.Select}
                isDisabled={!isEditable}
                options={availableSubTypes}
              />
            </Form.Group>
          )}
          <FieldArray name="patients">
            {({ replace, push, remove }) => (
              <>
                {incident.patients.map(
                  (patient, index) =>
                    !patient.deleted && (
                      <Row className="gy-3 mb-3" key={`patient-${index}`} id={`call-triage-patient-${index}`}>
                        <Col className="col-xs-12 col-sm-6 col-lg-3">
                          <Form.Group>
                            <SignalForm.Label required htmlFor={`patients.${index}.age`}>
                              Age
                            </SignalForm.Label>
                            <Field
                              as={Form.Control}
                              isInvalid={
                                !!(
                                  errors?.patients &&
                                  errors.patients[index] &&
                                  (errors.patients[index] as FormikErrors<CallTriagePatient>).age
                                ) &&
                                touched.patients &&
                                touched.patients[index] &&
                                touched.patients[index].age
                              }
                              id={`patients.${index}.age`}
                              name={`patients.${index}.age`}
                              disabled={!isEditable}
                              placeholder="Age"
                              value={patient.age ?? ''}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors?.patients &&
                                errors.patients[index] &&
                                (errors.patients[index] as FormikErrors<CallTriagePatient>).age}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col className="col-auto">
                          <Form.Group>
                            <SignalForm.Label required htmlFor={`patients.${index}.ageUnitsID`}>
                              Units
                            </SignalForm.Label>
                            <Field
                              id={`patients.${index}.ageUnitsID`}
                              name={`patients.${index}.ageUnitsID`}
                              isInvalid={
                                !!(
                                  errors?.patients &&
                                  errors.patients[index] &&
                                  (errors.patients[index] as FormikErrors<CallTriagePatient>).ageUnitsID
                                ) &&
                                touched.patients &&
                                touched.patients[index] &&
                                touched.patients[index].ageUnitsID
                              }
                              feedback={
                                errors?.patients &&
                                errors.patients[index] &&
                                (errors.patients[index] as FormikErrors<CallTriagePatient>).ageUnitsID
                              }
                              component={SignalForm.Select}
                              isDisabled={!isEditable}
                              options={options.ageUnits}
                              value={getSignalOption(patient.ageUnitsID, options.ageUnits)}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="col-auto">
                          <Form.Group>
                            <SignalForm.Label required htmlFor={`patients.${index}.genderID`}>
                              Gender
                            </SignalForm.Label>
                            <Field
                              id={`patients.${index}.genderID`}
                              name={`patients.${index}.genderID`}
                              isInvalid={
                                !!(
                                  errors?.patients &&
                                  errors.patients[index] &&
                                  (errors.patients[index] as FormikErrors<CallTriagePatient>).genderID
                                ) &&
                                touched.patients &&
                                touched.patients[index] &&
                                touched.patients[index].genderID
                              }
                              feedback={
                                errors?.patients &&
                                errors.patients[index] &&
                                (errors.patients[index] as FormikErrors<CallTriagePatient>).genderID
                              }
                              component={SignalForm.Select}
                              isDisabled={!isEditable}
                              options={options.genders}
                              value={getSignalOption(patient.genderID, options.genders)}
                            />
                          </Form.Group>
                        </Col>
                        {isEditable && (
                          <>
                            <Col className="col-auto">
                              <div className="mt-2 pt-3">
                                <Button
                                  variant="danger"
                                  onClick={() => {
                                    if (patient.incidentPatientID !== 0) {
                                      replace(index, { ...patient, deleted: true });
                                    } else {
                                      remove(index);
                                    }
                                  }}
                                  title="Remove Patient"
                                >
                                  <FontAwesomeIcon icon={faXmark} />
                                </Button>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                    )
                )}
                {isEditable && (
                  <Row className="mb-3">
                    <Col className="col-auto my-1">
                      <Button variant="it-dark-blue" color="it-eggshell" onClick={() => push({ ...initialPatient })}>
                        Add Patient
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </FieldArray>
          <Form.Group className="mb-3">
            <SignalForm.Label htmlFor="requestedDestination">Requested Destination</SignalForm.Label>
            <SignalSingleSelect
              inputId="requestedDestination"
              isClearable
              value={getSignalOptionFromGroup(requestedDestination, destinationTypes)}
              onChange={(x) => handleRequestedDestination(x as SignalOption<RequestType | string> | null)}
              isInvalid={
                !!errors.requestedDestinationID && !!errors.requestedDestinationType && touched.requestedDestinationType
              }
              feedback={errors.requestedDestinationID || errors.requestedDestinationType}
              onBlur={() => handleBlur('requestedDestinationType')}
              isDisabled={!isEditable}
              options={destinationTypes}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="finalDestinationName">
              {/* Fox Only, No Items */}
              Final Destination
            </Form.Label>
            <Form.Control
              id="finalDestinationName"
              disabled
              value={incident.finalDestinationName ?? ''}
              placeholder="No destination selected"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="add-comment">Notes</Form.Label>
            <Form.Control id="add-comment" ref={noteInputRef} placeholder="Note" aria-label="Note" />
          </Form.Group>
          <Form.Group>
            <Table striped>
              <tbody>
                {sortedNotes.map((note, index) => {
                  const name = `${note.createdByFirstname} ${note.createdByLastname}`.trim();
                  return (
                    <tr key={`commentRow-${index}`}>
                      <td>
                        {CommentDateTimeFormatter.format(dateTimeUnknownUTCToLocal(note.createdOnUtc))} ({name}) -{' '}
                        {note.comment}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Form.Group>
        </Form>
      </Col>
    </>
  );
};

export default CallTriageIncidentInformation;
