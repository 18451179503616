import Col from 'react-bootstrap/Col';
import { chunk } from '../../models/Helpers/ArrayHelper';
import './destinationTransport.scss';
import { DestinationTransport } from './types';

interface DestinationTransportsProps {
  destinationId: string;
  transports: DestinationTransport[];
}

const DestinationTransports = ({ destinationId, transports }: DestinationTransportsProps) => {
  return (
    <div className="p-3">
      {chunk(transports, 2).map((arrayChunk, chunkIndex) => (
        <div
          key={`destTransport-${destinationId}-${chunkIndex}`}
          className="d-flex justify-content-between transport-count"
        >
          {arrayChunk.map((item, itemIndex) => (
            <>
              <Col xs={3} className="d-flex align-items-center ps-3">
                {item.name}
              </Col>
              <Col
                xs={3}
                className={
                  itemIndex === 0
                    ? 'd-flex align-items-center pe-3 text-end border-end justify-content-end'
                    : 'd-flex align-items-center pe-3 text-end justify-content-end'
                }
              >
                {item.incidentCount}
              </Col>
            </>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DestinationTransports;
