import { DivIcon } from 'leaflet';

interface MarkerProps {
  className: string,
}

export function ActiveMarker(props: MarkerProps) {
  return new DivIcon({
    html: `<i class="fa-solid fa-location-dot fa-2x ${props.className}"></i>`,
    iconSize: [25, 25],
    className: 'pin-container',
  });
}
export function ClosedMarker(props: MarkerProps) {
  return new DivIcon({
    html: `<i class="fa-solid fa-location-pin fa-2x ${props.className}"></i>`,
    iconSize: [25, 25],
    className: 'pin-container',
  });
}