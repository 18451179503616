import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit';
import availableClientsReducer from './availableClientsSlice';
import callTriageSettingsReducer from './callTriageSettings';
import clientModulesReducer from './clientModulesSlice';
import currentClientReducer from './currentClientSlice';
import dashboardSettingsReducer from './dashboardSettings';
import showNavReducer from './navVisibleSlice';
import rightNavActionsReducer from './rightNavActionsSlice';

const rootReducer = combineReducers({
  availableClients: availableClientsReducer,
  callTriageSettings: callTriageSettingsReducer,
  clientModules: clientModulesReducer,
  currentClient: currentClientReducer,
  dashboardSettings: dashboardSettingsReducer,
  rightNavActions: rightNavActionsReducer,
  showNav: showNavReducer,
});

const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export default setupStore;
