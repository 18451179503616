import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faCircleXmark,
  faPersonRunning,
  faCheckSquare,
  faXmark,
  faPencil,
} from '@imagetrend/fontawesome-pro/pro-solid-svg-icons';

export function ArrivedIcon(props: Partial<FontAwesomeIconProps>) {
  return <FontAwesomeIcon {...props} icon={faHouse} color={'rgb(126, 0, 126)'} />;
}

export function UnavailableIcon(props: Partial<FontAwesomeIconProps>) {
  return <FontAwesomeIcon {...props} icon={faCircleXmark} color={'rgb(244, 0, 0)'} />;
}

export function RespondingIcon(props: Partial<FontAwesomeIconProps>) {
  return <FontAwesomeIcon {...props} icon={faPersonRunning} color={'rgb(28, 156, 2)'} />;
}

export function ClosedIcon(props: Partial<FontAwesomeIconProps>) {
  return <FontAwesomeIcon {...props} icon={faCheckSquare} color={'rgb(17, 48, 187)'} />;
}

export function CancelIcon(props: Partial<FontAwesomeIconProps>) {
  return <FontAwesomeIcon {...props} icon={faXmark} color={'rgb(244, 218, 0)'} />;
}

export function PencilIcon(props: Partial<FontAwesomeIconProps>) {
  return <FontAwesomeIcon {...props} icon={faPencil} color={'rgb(244, 218, 0)'} />;
}
