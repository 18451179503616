import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import api from '../../common/api';
import Logo from '../logo/logo';

export default function ForgotPassword2() {
  const [userPassword, setUserPassword] = useState({
    password: '',
  });
  const [userPasswordConfirm, setUserPasswordConfirm] = useState({
    passwordConfirm: '',
  });
  const [userCode, setUserCode] = useState({
    code: '',
  });

  const [showAlert, setShowAlert] = useState(false);

  const handlePasswordChange = (event: any) => {
    setUserPassword({ ...userPassword, password: event.target.value });
  };
  const handlePasswordConfirmChange = (event: any) => {
    setUserPasswordConfirm({ ...userPasswordConfirm, passwordConfirm: event.target.value });
  };
  const handleCodeChange = (event: any) => {
    setUserCode({ ...userCode, code: event.target.value });
  };

  async function postData(url = '', data = {}) {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  const handleFormSubmit = (event: any) => {
    event.preventDefault();

    postData(`${api.baseAwareUrl}/user/resetPassword`, {
      code: userCode.code,
      password: userPassword.password,
    }).then((data) => {
      if (data.success) {
        alert('Password reset successfully!');
        window.location.href = '/';
      } else {
        setShowAlert(true);
      }
    });
    setUserPassword({ password: '' });
    setUserPasswordConfirm({ passwordConfirm: '' });
    setUserCode({ code: '' });
  };

  return (
    <div>
      <Row className="mx-0">
        <Col className="textRight">
          <Logo />
        </Col>
      </Row>
      <div className="container">
        <div className="row justify-content-center mx-0">
          <div className="col-xl-5 col-lg-12 col-md-9">
            <div className="form-bg o-hidden form-border shadow-lg my-5">
              <div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <Form onSubmit={(e) => handleFormSubmit(e)}>
                        <Alert dismissible onClose={() => setShowAlert(false)} show={showAlert} variant="danger">
                          Incorrect email address or reset code.
                        </Alert>
                        <p className="form-title">Enter your new password</p>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="password"
                            name="password"
                            onChange={handlePasswordChange}
                            value={userPassword.password}
                            required
                          />
                        </Form.Group>
                        <p className="form-title">Confirm your new password</p>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="password"
                            name="passwordConfirm"
                            onChange={handlePasswordConfirmChange}
                            value={userPasswordConfirm.passwordConfirm}
                            required
                          />
                        </Form.Group>
                        <p className="form-title">Verification Code</p>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            name="userCode"
                            onChange={handleCodeChange}
                            value={userCode.code}
                            required
                          />
                        </Form.Group>
                        <Row className="my-1">
                          <Col>
                            <div className="textLeft">
                              <Link to="/" className="small">
                                Back to Login
                              </Link>
                            </div>
                          </Col>
                          <Col>
                            <div className="textRight">
                              <Link to="/forgot" className="small">
                                I don't have a code
                              </Link>
                            </div>
                          </Col>
                        </Row>
                        <div className="row mb-1 mt-5 mx-1">
                          <Button type="submit" name="login" variant="primary" className="col-12">
                            CONFIRM PASSWORD RESET
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
