import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './appStore';

interface ReducerAction<T> {
  type: string;
  payload: T;
}

export enum RightNavItems {
  addCallTriageIncidentButton,
}

interface RightNavState {
  navActions: RightNavItems[];
}

const initialState: RightNavState = {
  navActions: [],
};

export const rightNavActionsSlice = createSlice({
  name: 'rightNavActions',
  initialState,
  reducers: {
    updateRightNavActions: (state, action: ReducerAction<RightNavItems[]>) => {
      state.navActions = action.payload;
    },
  },
});

export const { updateRightNavActions } = rightNavActionsSlice.actions;

export const selectRightNavActions = (state: RootState) => state.rightNavActions.navActions;

export default rightNavActionsSlice.reducer;
