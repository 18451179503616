import './StepConnector.css';

import React from 'react';

import clsx from '../classnames';

export interface StepConnectorProps {
  /**
   * True if this is the connector before the currently active step.
   */
  active?: boolean;

  /**
   * True if this is the connector before a completed step.
   */
  completed?: boolean;

  /**
   * True if this is the connector before a disabled step.
   */
  disabled?: boolean;

  /**
   * True if this is the connector before the first step of a stepper.
   */
  first?: boolean;
}

const StepConnector: React.FC<StepConnectorProps> = (props) => {
  const { active = false, completed = false, disabled = false, first = false } = props;
  return (
    <div
      className={clsx(
        'stepper-step-connector',
        active && 'active',
        completed && 'completed',
        disabled && 'disabled',
        first && 'first'
      )}
    >
      <span className="stepper-step-connector-line" />
    </div>
  );
};
export default StepConnector;
