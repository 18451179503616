import { GoogleMap, GoogleMapProps, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect, useRef } from 'react';
import { CallTriageIncident } from '../types';
import { libraries } from './googleApiLoader';

const containerStyle = {
  height: '70vh',
};

const center = {
  lat: 32.1574,
  lng: -82.9071,
};

type SignalMapProps = {
  isEditable?: boolean;
  incident: CallTriageIncident;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  googleMapsApiKey: string;
  onMapLoaded?: (map: google.maps.Map) => void;
} & GoogleMapProps;

function SignalMap({ isEditable, incident, setFieldValue, googleMapsApiKey, onMapLoaded, ...props }: SignalMapProps) {
  const { isLoaded: mapJsLoaded } = useJsApiLoader({
    libraries: libraries,
    id: 'google-map-api-script',
    googleMapsApiKey: googleMapsApiKey,
  });
  const mapInstance = useRef<google.maps.Map>();

  const onLoad = React.useCallback(
    (map: google.maps.Map) => {
      const loadMap = (position: GeolocationPosition) => {
        if (!isEditable) return;
        const bounds = new window.google.maps.LatLng({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        map.setCenter(bounds);
      };

      navigator.geolocation.getCurrentPosition(loadMap);
      if (onMapLoaded) {
        onMapLoaded(map);
      }

      mapInstance.current = map;
    },
    [onMapLoaded, isEditable]
  );

  const onUnmount = React.useCallback((map: google.maps.Map) => {
    mapInstance.current = undefined;
  }, []);

  // watch for location changes via auto complete and recenter the map to selected location
  useEffect(() => {
    if (!mapInstance.current) {
      return;
    }

    if (incident.latitude && incident.longitude) {
      mapInstance.current.setCenter({ lat: incident.latitude, lng: incident.longitude });
    }
  }, [incident.latitude, incident.longitude]);

  return mapJsLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
        }}
        onClick={(e) => {
          if (!isEditable || !e.latLng) {
            return;
          }
          if (!setFieldValue) {
            return;
          }
          setFieldValue('latitude', e.latLng.lat());
          setFieldValue('longitude', e.latLng.lng());
        }}
        {...props}
      >
        {incident.latitude && incident.longitude && (
          <Marker
            position={{
              lat: incident.latitude,
              lng: incident.longitude,
            }}
          />
        )}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default React.memo(SignalMap);
