import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@imagetrend/fontawesome-pro/pro-solid-svg-icons';
import {
  faHeadSideBrain,
  faHeartPulse,
  faSquare1,
  faSquare2,
  faSquare3,
  faSquare4,
} from '@imagetrend/fontawesome-pro/sharp-solid-svg-icons';
import Stack from 'react-bootstrap/Stack';
import { STEMILevelID, StrokeLevelID, TraumaLevelID } from '../../../models/ResourceBridge/Office';

const NoLevelIcon = () => {
  return <FontAwesomeIcon color="red" icon={faXmark} size="2x" />;
};

interface BaseIconProps<T> {
  title: string;
  level: T;
}

interface TraumaIconProps extends BaseIconProps<TraumaLevelID> {}

const TraumaIcon = ({ level, title }: TraumaIconProps) => {
  return (
    <>
      <Stack className="text-center" title={!!title ? title : 'No trauma services available'}>
        {level === TraumaLevelID.Level1 ? (
          <FontAwesomeIcon className="text-it-green" icon={faSquare1} size="2x" />
        ) : level === TraumaLevelID.Level2 ? (
          <FontAwesomeIcon className="text-it-green" icon={faSquare2} size="2x" />
        ) : level === TraumaLevelID.Level3 ? (
          <FontAwesomeIcon className="text-orange" icon={faSquare3} size="2x" />
        ) : level === TraumaLevelID.Level4 ? (
          <FontAwesomeIcon className="text-orange" icon={faSquare4} size="2x" />
        ) : (
          <NoLevelIcon />
        )}
        Trauma
      </Stack>
    </>
  );
};

interface CardiacIconProps extends BaseIconProps<STEMILevelID> {}

const CardiacIcon = ({ level, title }: CardiacIconProps) => {
  return (
    <>
      <Stack className="text-center" title={!!title ? title : 'No cardiac services available'}>
        {level === STEMILevelID.Level1 ? (
          <FontAwesomeIcon className="text-it-green" icon={faHeartPulse} size="2x" />
        ) : (
          <NoLevelIcon />
        )}
        Cardiac
      </Stack>
    </>
  );
};

interface StrokeIconProps extends BaseIconProps<StrokeLevelID> {}

const StrokeIcon = ({ level, title }: StrokeIconProps) => {
  return (
    <>
      <Stack className="text-center" title={!!title ? title : 'No stroke services available'}>
        {level === StrokeLevelID.Level1 ? (
          <FontAwesomeIcon className="text-it-green" icon={faHeadSideBrain} size="2x" />
        ) : level === StrokeLevelID.Level2 ? (
          <FontAwesomeIcon className="text-it-orange" icon={faHeadSideBrain} size="2x" />
        ) : (
          <NoLevelIcon />
        )}
        Stroke
      </Stack>
    </>
  );
};

const StatusIcons = {
  Trauma: TraumaIcon,
  Cardiac: CardiacIcon,
  Stroke: StrokeIcon,
};

export default StatusIcons;
