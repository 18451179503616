import { useMap } from 'react-leaflet';

const MapAttribution = () => {
  const map = useMap();
  map.attributionControl.setPrefix('');

  return <></>;
};

export default MapAttribution;
