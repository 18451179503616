import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@imagetrend/fontawesome-pro/sharp-regular-svg-icons';
import { Icon } from 'leaflet';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectClientId } from '../../common/appState/currentClientSlice';
import { MaxUnitNameLength } from '../../common/constants';
import routes from '../../common/routesDefinitions';
import { constructAddress } from '../../models/Helpers/AddressHelper';
import { Incident } from '../../models/Incident';
import HeaderTitle from '../headerTitle/headerTitle';
import ResponderMode from '../responderMode/responderMode';
import UnitCards from '../unitCard/unitCards';
import { useApiClient } from '../useApiClient/useApiClient';
import { useInterval } from '../useInterval/useInterval';

export default function IncidentDetails(): JSX.Element {
  const apiClient = useApiClient();
  // @TODO: Remove this.
  const clientObject = JSON.parse(sessionStorage.clientObject);
  const { id } = useParams();
  const clientID = useSelector(selectClientId);

  const [incident, setIncident] = useState<Incident>();

  sessionStorage.setItem('incident', id ?? '0');

  const intervalSeconds = 10;
  const [notes, setNotes] = useState([
    { dispatchCommentId: 1, commentDateTime: moment(), comment: 'Loading incident data...' },
  ]);

  const updateNotesData = useCallback(() => {
    const updateNotes = async () => {
      // we can only have notes if we're viewing an incident
      if (!sessionStorage.incident) {
        return;
      }

      if (clientObject.privacyModeFlag === false) {
        try {
          const queryResult = await apiClient.getNotesAsync({ limit: 50 });
          const reverse = queryResult.reverse();
          setNotes(reverse);
        } catch (err) {
          console.error('Failed to fetch incident data', err);
        }
      } else {
        console.log('Privacy mode enabled');
      }
    };

    updateNotes();
  }, [clientObject.privacyModeFlag, apiClient]);

  const inactiveUnits = useMemo(() => {
    return incident?.units.filter((x) => x.clearDateTime) ?? [];
  }, [incident?.units]);

  const activeUnits = useMemo(() => {
    return incident?.units.filter((x) => !x.clearDateTime) ?? [];
  }, [incident?.units]);

  useInterval(updateNotesData, intervalSeconds * 1000);

  const pin = new Icon({
    iconUrl: '/images/location-dot-solid.svg',
    iconSize: [25, 25],
  });

  useEffect(
    () => {
      const loadIncident = async () => {
        if (!id) {
          throw new Error('Unable to load incident');
        }
        return apiClient.getIncidentById(clientID, id);
      };
      loadIncident().then((incident) => {
        setIncident(incident);
      });
    },
    // TODO:  Stop ClientID from being modifiable on certain pages
    // TODO:  Add in active and inactive units.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      apiClient,
      // clientID,
      id,
    ]
  );

  const [hideSwitch, setHideSwitch] = useState(true);
  const onUse = () => {
    setHideSwitch(!hideSwitch);
  };

  let notesColumn;
  if (clientObject.privacyModeFlag === true) {
    notesColumn = (
      <>
        <Col></Col>
      </>
    );
  } else {
    notesColumn = (
      <>
        <Col>
          <div className={'timelineCard' + (hideSwitch ? ' d-print-none' : '')}>
            <Row className="mx-0">
              <Col>
                <p className="timelineCardTitle">Incident Timeline</p>
              </Col>
              <Col className="d-print-none">
                <Form className="responderSwitch" id="hide-switch">
                  <Form.Check type="switch" onChange={onUse} checked={hideSwitch} label="Privacy Mode" />
                </Form>
              </Col>
            </Row>
            {notes.length > 0 &&
              notes.map((data) => {
                return (
                  <div className="timelineTimeText mt-1 mx-1" key={data.dispatchCommentId}>
                    <Row className="mt-3">
                      <Col className="col-3">
                        <p className="mt-3">{moment(data.commentDateTime).format('MM/DD/yyyy  HH:mm:ss')}</p>
                      </Col>
                      <Col>
                        <p className={'mt-3 mx-2 timelineSubText' + (hideSwitch ? ' blurry-text' : '')}>
                          {data.comment}
                        </p>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            {notes.length === 0 && (
              <Row className="mt-5 mx-0">
                <Row className="mt-3 mx-0">
                  <p>There are no incident notes at this time.</p>
                </Row>
              </Row>
            )}
          </div>
        </Col>
      </>
    );
  }

  return incident ? (
    <>
      <HeaderTitle title={incident.eventPriority ?? 'New Event'} backTo={routes.dashboard}>
        <Button variant="secondary" className="d-print-none" onClick={window.print}>
          <FontAwesomeIcon icon={faPrint}></FontAwesomeIcon>
        </Button>
      </HeaderTitle>
      <Row className="mt-3 mx-0 d-print-block">
        <Col s={12} className="detailsCol1">
          <div className="incidentCard">
            <Row className="incidentCardTitle mx-0">
              <p className="mx-3 mt-3" id="infoTitle">
                Incident Information
              </p>
            </Row>
            <Row className="mt-2 mx-0">
              <Col className="mx-3">
                <p className="incidentCardText" id="infoText">
                  Incident Date/Time:
                </p>
              </Col>
              <Col>
                <p className="incidentCardText" id="infoText">
                  {moment(incident.createdByDispatchDateTime).format('MM/DD/yyyy   HH:mm')}
                </p>
              </Col>
            </Row>
            <Row className="mt-2 mx-0">
              <Col className="mx-3">
                <p className="incidentCardText" id="infoText">
                  Incident Number:
                </p>
              </Col>
              <Col>
                <p className="incidentCardText" id="infoText">
                  {incident.incidentNumber}
                </p>
              </Col>
            </Row>
            <Row className="mt-2 mx-0">
              <Col className="mx-3">
                <p className="incidentCardText" id="infoText">
                  Incident Status:
                </p>
              </Col>
              <Col>
                <p className="incidentCardText" id="infoText">
                  {incident.active ? 'Active' : 'Inactive'}
                </p>
              </Col>
            </Row>
            <Row className="mt-2 mx-0">
              <Col className="mx-3">
                <p className="incidentCardText" id="infoText">
                  Incident State:
                </p>
              </Col>
              <Col>
                <p className="incidentCardText" id="infoText">
                  {incident.status === 'Transport Completed' ? 'Transported' : incident.status}
                </p>
              </Col>
            </Row>
            <Row className="mt-2 mx-0">
              <Col className="mx-3">
                <p className="incidentCardText" id="infoText">
                  Incident Type:
                </p>
              </Col>
              <Col>
                <p className="incidentCardText" id="infoText">
                  {incident.eventType}
                </p>
              </Col>
            </Row>
            <Row className="mt-2 mx-0">
              <Col className="mx-3">
                <p className="incidentCardText" id="infoText">
                  Incident Disposition:
                </p>
              </Col>
              <Col>
                <p className="incidentCardText" id="infoText">
                  {incident.incidentDisposition}
                </p>
              </Col>
            </Row>
          </div>

          <div className="locationCard mt-3">
            <Row className="locationCardTitle mx-0">
              <p className="mx-4 mt-3">Incident Location</p>
            </Row>
            <Row className="mt-2 mx-0">
              <Col className="mx-4">
                {incident.locationName === 'STAGE' ? (
                  <>
                    <p className="locationCardText textLeft">
                      {`${constructAddress({
                        streetNumber: incident.streetNumber,
                        streetPrefix: incident.streetPrefix,
                        streetName: incident.streetName,
                        streetType: incident.streetType,
                        streetSuffix: incident.streetSuffix,
                      })}`}
                    </p>
                    <p style={{ color: 'red', fontWeight: 'bold' }}>@ STAGE</p>
                  </>
                ) : (
                  <>
                    <p className="locationCardText textLeft">
                      {`${constructAddress({
                        streetNumber: incident.streetNumber,
                        streetPrefix: incident.streetPrefix,
                        streetName: incident.streetName,
                        streetType: incident.streetType,
                        streetSuffix: incident.streetSuffix,
                        locationName: incident.locationName,
                      })}`}
                    </p>
                  </>
                )}
                <p className="locationCardText">{`${constructAddress({
                  city: incident.city,
                  state: incident.state,
                })}`}</p>
              </Col>
            </Row>
            <Row className="mt-2 mx-0">
              <Col className="mx-4">
                <p className="locationCardText">Cross Street 1:</p>
              </Col>
              <Col className="mx-3">
                <p className="locationCardText">{incident.crossStreet1}</p>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col className="mx-4">
                <p className="locationCardText">Cross Street 2:</p>
              </Col>
              <Col className="mx-3">
                <p className="locationCardText">{incident.crossStreet2}</p>
              </Col>
            </Row>
            <Row className="mt-2 mx-0">
              <Col className="mx-4">
                <p className="locationCardText">Map Page:</p>
              </Col>
              <Col className="mx-3">
                <p className="locationCardText">{incident.mapPage}</p>
              </Col>
            </Row>
          </div>
          <Col>
            <UnitCards units={activeUnits} active={true} />

            <UnitCards units={inactiveUnits} active={false} />
          </Col>
        </Col>

        <Col>
          <MapContainer
            center={[incident.latitude ?? 0, incident.longitude ?? 0]}
            zoom={15}
            scrollWheelZoom={true}
            className="incident-map d-print-none"
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {incident.latitude && incident.longitude && (
              <Marker key={incident.incidentID} position={[incident.latitude, incident.longitude]} icon={pin}>
                <Popup>
                  {`${incident.streetNumber === '0' || null ? '' : incident.streetNumber} ${
                    incident.streetPrefix == null ? '' : incident.streetPrefix
                  } ${incident.streetName == null ? '' : incident.streetName} ${
                    incident.streetType == null ? '' : incident.streetType
                  } ${incident.locationName ? `@ ${incident.locationName}` : ''}`}
                  <br />
                  {`${incident.city}, ${incident.state}`}
                  <br />
                  {incident.status === 'Transport Completed' ? 'Transported' : incident.status} |{' '}
                  {incident.unitNames.length < MaxUnitNameLength
                    ? incident.unitNames
                    : 'Check active/inactive resources'}
                </Popup>
              </Marker>
            )}
          </MapContainer>
          {clientObject.responderModeFlag === true && <ResponderMode />}
        </Col>
        {notesColumn}
      </Row>
    </>
  ) : (
    <>
      <div className="h-100 d-flex justify-content-center align align-items-center">
        <Spinner /> Loading
      </div>
    </>
  );
}
