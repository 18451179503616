import React from "react";

// Based on https://overreacted.io/making-setinterval-declarative-with-react-hooks/

export function useInterval(callback: (...args: any[]) => void, delay: number) {
    const savedCallback = React.useRef(callback);

    // Remember the latest callback.
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    React.useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (typeof delay === "number") {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
