import { FieldProps } from 'formik/dist/Field';
import Form from 'react-bootstrap/Form';
import { FormLabelProps } from 'react-bootstrap/FormLabel';
import { SignalOption, getSignalOption } from '../../common/types';
import { SignalSelectProps, SignalSingleSelect } from './signalSelect';

type SignalFormLabelProps = {
  required?: boolean;
} & FormLabelProps;

const SignalFormLabel = ({ required, children, ...props }: SignalFormLabelProps) => {
  return (
    <Form.Label {...props}>
      {children}
      {required ? <span className="d-inline text-danger"> *</span> : <></>}
    </Form.Label>
  );
};

type SignalFormSingleSelectProps<T> = {
  options: SignalOption<T>[];
} & SignalSelectProps &
  FieldProps;

const SignalFormSingleSelect = <T extends unknown>({
  options,
  field,
  form,
  id,
  ...props
}: SignalFormSingleSelectProps<T>) => {
  return (
    <SignalSingleSelect
      name={field.name}
      isMulti={false}
      isClearable
      value={getSignalOption(field.value, options)}
      onChange={(option) => form.setFieldValue(field.name, (option as SignalOption<T>)?.value ?? null)}
      onBlur={() => form.setFieldTouched(field.name, true)}
      options={options}
      inputId={id}
      {...props}
    />
  );
};

const SignalForm = {
  Label: SignalFormLabel,
  Select: SignalFormSingleSelect,
};

export default SignalForm;
