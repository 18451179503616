import api from '../../common/api';
import ClientAlias from '../../common/clientAlias';
import { Client } from '../../models/Client';
import { ClientUser } from '../../models/ClientUser';
import { ClientUserInvitationRequest } from '../../models/ClientUserInvitationRequest';
import { getData, postData } from '../../models/Helpers/FetchHelper';
import { AuthenticatedAwareUser, AuthenticatedSignalUser } from '../../models/LoggedInUser';
import UserRole from '../../models/UserRole';

enum LoginStatus {
  Success,
  Failed,
}

class LoginResponse {
  errorMessages: string[] = [];
  status: LoginStatus | null = null;

  fail(errorMessage?: string) {
    this.status = LoginStatus.Failed;
    if (errorMessage) {
      this.addErrorMessage(errorMessage);
    }
  }

  succeed(errorMessage?: string) {
    this.status = LoginStatus.Success;
    if (errorMessage) {
      this.addErrorMessage(errorMessage);
    }
  }

  addErrorMessage(errorMessage: string) {
    this.errorMessages.push(errorMessage);
  }

  ok(): boolean {
    return this.status === LoginStatus.Success;
  }
}

interface LoginData {
  searchParams?: ClientUserInvitationRequest;
  emailAddress: string;
  password: string;
}

export default async function login(loginData: LoginData): Promise<LoginResponse> {
  const loginResponse = new LoginResponse();
  const awareDataResponse = await postData(`${api.baseUrl}/api/authentication/awareUserLogin`, {
    emailAddress: loginData.emailAddress,
    password: loginData.password,
  });

  if (!awareDataResponse.ok) {
    loginResponse.fail('Invalid email or password');
    return loginResponse;
  }

  const awareData = (await awareDataResponse.json()) as AuthenticatedAwareUser;

  if (!awareData.user.activeFlag) {
    loginResponse.fail('Your Signal account is not currently active. Please contact an administrator.');
    return loginResponse;
  }

  // Stores the user object for calls to Aware for further functionality
  let user: AuthenticatedSignalUser = {
    token: awareData.token,
    emailAddress: awareData.user.emailAddress,
    verified: awareData.user.verified,
    active: awareData.user.activeFlag,
    admin: awareData.user.adminFlag,
    awareUserID: awareData.user.awareUserID,
    firstname: awareData.user.firstname,
    lastname: awareData.user.lastname,
  };

  // activate potential client invitation before retrieving client list
  if (loginData.searchParams?.action === 'activation') {
    await postData(
      `${api.baseUrl}/api/users/clientuserinvitations/activate`,
      {
        clientCode: loginData.searchParams.clientCode,
        invitationCode: loginData.searchParams.invitationCode,
      },
      awareData.token
    );
  }
  // setup a function which setupSession can invoke to retrieve clients for either a regular user or admin
  let getClientUsers = user.admin ? getClientsForAdmin : getClientsForUser;

  await setupSession(
    user,
    getClientUsers,
    () => loginResponse.succeed(),
    () => loginResponse.fail()
  );

  return loginResponse;
}

const sortClients = (clients: { clientID: string; clientName: string }[]) => {
  if (clients.length === 1) {
    return clients;
  }

  return clients.sort((a, b) => {
    if (a.clientName === b.clientName) {
      return 0;
    }

    if (a.clientName < b.clientName) {
      return -1;
    }

    return 1;
  });
};

const getClientsForUser = async (accessToken: string) => {
  const clientUsersResponse = await getData(`${api.baseUrl}/api/users/clientUsers`, accessToken);

  let adminClients: ClientUser[] = [];

  if (clientUsersResponse.ok) {
    const users = (await clientUsersResponse.json()) as ClientUser[];

    // Only keep Approved ClientUser objects
    const mappedUsers = users
      .filter((u) => u.clientUserStateID === 2)
      .map((u) => ({ clientID: u.clientID, clientName: u.clientName, clientUserRole: u.clientUserRole } as ClientUser));

    adminClients = mappedUsers;
  }

  return sortClients(adminClients);
};

// retrieves clients for a super user
const getClientsForAdmin = async (accessToken: string) => {
  let adminClientResponse = await getData(`${api.baseUrl}/api/clients/all`, accessToken);

  let adminClients: ClientUser[] = [];
  if (adminClientResponse.ok) {
    const clients = (await adminClientResponse.json()) as Client[];
    adminClients = clients.map((c) => ({
      clientID: c.clientID!,
      clientName: c.name!,
      clientUserRole: UserRole.admin,
    })) as ClientUser[];
  }

  return sortClients(adminClients);
};

const setupSession = async (
  user: AuthenticatedSignalUser,
  getClients: (accessToken: string) => Promise<ClientAlias[]>,
  onSuccess: () => void,
  onFailure: () => void
) => {
  sessionStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('accessToken', user.token);

  const clients = await getClients(user.token);

  if (clients.length === 0) {
    onSuccess();
    sessionStorage.setItem('clientList', JSON.stringify([]));
    sessionStorage.setItem('clientID', '0');
    sessionStorage.setItem('clientAlias', JSON.stringify({ clientID: '0', clientName: 'Associate Your Account' }));
    return;
  }

  // this client list is consumed by the Redux store so we may receive notifications upon changes to it
  // we need to store it here so we can populate the Redux store from it.
  sessionStorage.setItem('clientList', JSON.stringify(clients));

  const clientAlias = {
    clientID: clients[0].clientID!,
    clientName: clients[0].clientName!,
  };

  sessionStorage.setItem('clientID', clients[0].clientID!);
  sessionStorage.setItem('clientAlias', JSON.stringify(clientAlias));

  onSuccess();
};
