import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import { useAppSelector } from '../../common/appState/appStateHooks';
import { selectClientAlias } from '../../common/appState/currentClientSlice';
import { ReducerAction } from '../../common/reducerAction';
import '../../listTable.css';
import { ClientSettings } from '../../models/ClientSettings';
import { ClientUser } from '../../models/ClientUser';
import { ClientUserInvitation } from '../../models/ClientUserInvitation';
import { getPaginationShowingString } from '../../models/Helpers/PaginationHelper';
import UserRole from '../../models/UserRole';
import { useApiClient } from '../useApiClient/useApiClient';
import ManageInvitesTab from './manageInvitesTab';
import ManageUsersTab from './manageUsersTab';
import './personnel.css';

interface PaginationState {
  cuPageNumber: number;
  cuTotalPages: number;
  cuiPageNumber: number;
  cuiTotalPages: number;
}

interface UsersState {
  all: ClientUser[];
  active: ClientUser[];
  invited: ClientUserInvitation[];
}

enum ReducerActions {
  createNewInvitation = 'createNewInvitation',
  hideInvitationModal = 'hideInvitationModal',
  showInvitationModel = 'showInvitationModal',
  updateActiveUsers = 'updateActiveUsers',
  updateAllUsers = 'updateAllUsers',
  updateInvitedUsers = 'updateInvitedUsers',
  updateMultiInvitationEmailAddresses = 'updateMultiInvitationEmailAddresses',
  updateSingleInvitation = 'updateInvitation',
  updateUnparsedMultiInviteEmailAddresses = 'updateUnparsedMultiInviteEmailAddresses',
  updateInvitationModal = 'updateInvitationModal',
  updateActiveInvitationModalTab = 'updateActiveInvitationModalTab',
  updateClientUsersPageNumber = 'updateClientUsersPageNumber',
  updateClientUsersTotalPages = 'updateClientUsersTotalPages',
  updateClientInvitesPageNumber = 'updateClientInvitesPageNumber',
  updateClientInvitesTotalPages = 'updateClientInvitesTotalPages',
}

const maxResultsToEverGet = 1000;
const defaultPageLength = 50;

const initialUsersState: UsersState = {
  all: [],
  active: [],
  invited: [],
};

const initialPaginationState: PaginationState = {
  cuPageNumber: 1,
  cuTotalPages: 5,
  cuiPageNumber: 1,
  cuiTotalPages: 5,
};

const usersReducer: ImmerReducer<UsersState, ReducerAction<UsersState>> = (draft, action) => {
  switch (action.type) {
    case ReducerActions.updateAllUsers:
      draft.all = action.data.all;
      break;
    case ReducerActions.updateInvitedUsers:
      draft.invited = action.data.invited;
      break;
  }
};

const paginationReducer: ImmerReducer<PaginationState, ReducerAction<number>> = (draft, action) => {
  switch (action.type) {
    case ReducerActions.updateClientUsersPageNumber:
      draft.cuPageNumber = action.data;
      break;
    case ReducerActions.updateClientUsersTotalPages:
      draft.cuTotalPages = action.data;
      break;
    case ReducerActions.updateClientInvitesPageNumber:
      draft.cuiPageNumber = action.data;
      break;
    case ReducerActions.updateClientInvitesTotalPages:
      draft.cuiTotalPages = action.data;
      break;
  }
};

export default function Personnel() {
  const apiClient = useApiClient();
  const user = JSON.parse(sessionStorage.user);
  const clientAlias = useAppSelector(selectClientAlias);

  const [client, setClient] = useState<ClientSettings>();
  const [users, dispatchUsers] = useImmerReducer(usersReducer, initialUsersState);
  const [pagination, dispatchPagination] = useImmerReducer(paginationReducer, initialPaginationState);

  useEffect(() => {
    const getSettings = async () => {
      const queryResult = await apiClient.getClientSettings('', clientAlias.clientID);
      setClient(queryResult);
    };
    getSettings();
  }, [setClient, clientAlias, apiClient]);

  // Get all Invitations, count the Total:
  const getAllClientUserInvitations = useCallback(() => {
    const fetchInvitations = async () => {
      try {
        const clientUsersInvListResult = await apiClient.getClientUserInvitationsForClientID(
          { limit: maxResultsToEverGet, offset: 0 },
          clientAlias.clientID
        );

        dispatchUsers({
          type: ReducerActions.updateInvitedUsers,
          data: { all: [], invited: clientUsersInvListResult, active: [] },
        });
      } catch (err) {
        console.error('Failed to fetch clientuserinvitation data for clientuser count', err);
      }
    };

    fetchInvitations();
  }, [apiClient, clientAlias.clientID, dispatchUsers]);
  useEffect(getAllClientUserInvitations, [getAllClientUserInvitations]);

  const handlePaginationClickUsers = (page: number) => {
    dispatchPagination({ type: ReducerActions.updateClientUsersPageNumber, data: page });
  };
  const handlePaginationPrevClickUsers = (event: any) => {
    if (pagination.cuPageNumber - 1 >= 1) {
      dispatchPagination({ type: ReducerActions.updateClientUsersPageNumber, data: pagination.cuPageNumber - 1 });
    }
  };
  const handlePaginationNextClickUsers = (event: any) => {
    if (pagination.cuPageNumber + 1 <= pagination.cuTotalPages) {
      dispatchPagination({ type: ReducerActions.updateClientUsersPageNumber, data: pagination.cuPageNumber + 1 });
    }
  };

  useEffect(() => {
    dispatchPagination({
      type: ReducerActions.updateClientUsersTotalPages,
      data: Math.ceil(users.all.length / defaultPageLength),
    });
  }, [users.all, dispatchPagination]);

  // Get all Client Users, count the Total and the Active:
  useEffect(() => {
    const fetchClientUsers = async () => {
      try {
        const clientUsersListResult = await apiClient.getClientUsersForClientID(
          { limit: maxResultsToEverGet, offset: 0 },
          clientAlias.clientID
        );

        const updatedAllUsers = { active: [], invited: [], all: clientUsersListResult };

        dispatchUsers({
          type: ReducerActions.updateAllUsers,
          data: updatedAllUsers,
        });
      } catch (error: any) {
        console.error('Failed to fetch clientUser data for clientUser count', JSON.stringify(error));
      }
    };

    fetchClientUsers();
  }, [clientAlias.clientID, apiClient, dispatchUsers]);

  const activeClientUserCount = useMemo(() => {
    return users.all.filter((cu) => cu.clientUserStateID === 2)?.length ?? 0;
  }, [users.all]);

  // Get Client Users for Display on the page:
  const visibleClientUsers = useMemo(() => {
    if (users.all.length === 0) {
      return [];
    }
    return users.all.slice(
      (pagination.cuPageNumber - 1) * defaultPageLength,
      pagination.cuPageNumber * defaultPageLength
    );
  }, [users.all, pagination.cuPageNumber]);

  // Update the ClientUser object in the API and refresh the ClientUserList on the page
  const updateClientUser = React.useCallback(
    (clientUser: any) => {
      const userUpdater = async () => {
        try {
          await apiClient.putClientUser(clientUser, clientAlias.clientID);
        } catch (err) {
          console.error('Failed to update ClientUser data', err);
        }
      };

      userUpdater();
    },
    [apiClient, clientAlias.clientID]
  );

  const atUserLimit = useMemo(() => {
    return !!client?.userLimit && activeClientUserCount >= client?.userLimit;
  }, [client?.userLimit, activeClientUserCount]);

  // Update ClientUserStateID of a ClientUser, send PUT to API, refresh data
  const handleUserStateChange = (event: any, clientUser: ClientUser, overrideUserStateID?: number) => {
    if (atUserLimit && clientUser.clientUserStateID !== 2) {
      return;
    }
    if (user.awareUserID === clientUser.awareUserID) {
      return;
    }

    const allUsersIndex = users.all.findIndex((u) => u.clientUserID === clientUser.clientUserID);

    if (allUsersIndex === -1) {
      return;
    }

    const newClientUser = { ...clientUser };
    newClientUser.clientUserStateID =
      overrideUserStateID != null ? overrideUserStateID : newClientUser.clientUserState === 'Approved' ? 4 : 2;

    // 4 = Disabled (Inactive), 2 = Approved (Active)
    newClientUser.clientUserState = clientUser.clientUserState === 'Approved' ? 'Disabled' : 'Approved';

    const updatedUsers = [...users.all];

    updatedUsers[allUsersIndex] = newClientUser;

    dispatchUsers({
      type: ReducerActions.updateAllUsers,
      data: { ...users, all: updatedUsers },
    });

    updateClientUser(newClientUser);
  };

  // Update ClientUserRoleID of a ClientUser, send PUT to API, refresh data
  const handleUserRoleChange = (event: any, clientUser: any) => {
    if (user.awareUserID === clientUser.awareUserID) {
      return;
    }

    const allUsersIndex = users.all.findIndex((u) => u.clientUserID === clientUser.clientUserID);
    if (allUsersIndex === -1) {
      return;
    }

    const newClientUser = { ...clientUser };

    newClientUser.clientUserRoleID = newClientUser.clientUserRole === 'User' ? 2 : 1;
    newClientUser.clientUserRole = newClientUser.clientUserRole === 'User' ? UserRole.admin : UserRole.user;

    const updatedAllUsers = [...users.all];

    // update the user whose role changed in our reducer state
    updatedAllUsers[allUsersIndex] = newClientUser;
    dispatchUsers({
      type: ReducerActions.updateAllUsers,
      data: { ...users, all: updatedAllUsers },
    });

    // save the updated user
    updateClientUser(newClientUser);
  };

  return (
    <>
      <div className="incidentBG">
        <Row>
          <Col>
            <h3 className="pageHeader">Personnel</h3>
          </Col>
        </Row>
        <p>
          Manage your personnel on this page. You can activate or inactivate personnel, or invite more personnel to
          ImageTrend Signal.
        </p>
        <Tabs defaultActiveKey="users" transition={false} id="noanim-tab-example" className="pt-1">
          <Tab eventKey="users" title="Manage Users">
            <ManageUsersTab
              activeClientUserCount={activeClientUserCount}
              displayPaginationSummary={() =>
                getPaginationShowingString(pagination.cuPageNumber, defaultPageLength, users.all.length)
              }
              pagination={{
                currentPage: pagination.cuPageNumber,
                totalPages: pagination.cuTotalPages,
                onPageClick: handlePaginationClickUsers,
                onPrevClick: handlePaginationPrevClickUsers,
                onNextClick: handlePaginationNextClickUsers,
              }}
              handleUserRoleChange={handleUserRoleChange}
              handleUserStateChange={handleUserStateChange}
              visibleClientUsers={visibleClientUsers}
              userLimit={client?.userLimit ?? 0}
            />
          </Tab>
          <Tab eventKey="invitations" title="Manage Invitations">
            <ManageInvitesTab atUserLimit={atUserLimit} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
