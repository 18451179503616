import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faUser } from '@imagetrend/fontawesome-pro/pro-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { useAppDispatch, useAppSelector } from '../../common/appState/appStateHooks';
import { selectAvailableClients } from '../../common/appState/availableClientsSlice';
import { selectClientAlias, updateClientAlias } from '../../common/appState/currentClientSlice';
import Logo from '../logo/logo';
import { useApiClient } from '../useApiClient/useApiClient';

export default function Navigation() {
  function logout() {
    sessionStorage.clear();
    window.location.href = '/';
  }

  const clientAlias = useAppSelector(selectClientAlias);
  const apiClient = useApiClient();
  const clientList = useAppSelector(selectAvailableClients);
  const dispatch = useAppDispatch();

  const handleSelectClient = (e: any) => {
    const clientID = e.target.attributes.value.nodeValue;
    const clientName = e.target.attributes[1].nodeValue;

    const client = {
      clientID: clientID,
      clientName: clientName,
    };

    // persist to session storage in case the user refreshes the page
    sessionStorage.setItem('clientAlias', JSON.stringify(client));

    // persist new selected client to global app state so we may react to it changing
    // in other components, like the Personnel compononent for example
    dispatch(updateClientAlias(client));

    apiClient
      .getClientSettings('', clientAlias.clientID)
      .then((queryResult: any) => {
        sessionStorage.setItem('clientID', queryResult.clientID ?? '');
        sessionStorage.setItem('clientObject', JSON.stringify(queryResult));
      })
      .catch((err: any) => {
        console.error('Failed to retrieve client settings', err);
      });
  };

  // const intervalSeconds = 10;

  // const updateClientList = React.useCallback(() => {
  //   if (typeof clients === 'string') {
  //     setClientList([]);
  //   } else {
  //   setClientList(clients);
  //   }
  // }, [clients]);

  // useInterval(updateClientList, intervalSeconds * 100);

  return (
    <>
      <Navbar className="topnav" expand={'md'} style={{ overflowX: 'clip' }}>
        <Container fluid className={'justify-content-stretch'}>
          <Navbar.Brand>
            <Logo />
          </Navbar.Brand>
          <NavDropdown
            title={
              <h1 className="text-truncate" style={{ display: 'inline-block' }}>
                {clientAlias.clientName}
              </h1>
            }
          >
            {clientList &&
              clientList.length > 0 &&
              clientList.map((data) => {
                return (
                  <NavDropdown.Item
                    value={data.clientID}
                    data-mssg={data.clientName}
                    onClick={handleSelectClient}
                    key={data.clientID}
                    id="dropText"
                  >
                    {data.clientName}
                  </NavDropdown.Item>
                );
              })}
          </NavDropdown>
          <NavDropdown
            className="d-print-none"
            title={<FontAwesomeIcon icon={faUser} size="3x" title="User Menu" />}
            align={'end'}
          >
            {/* <NavDropdown.Item value={'User Voice'} data-mssg={'User Voice'} key={2}>
              <p className="userNavDropdown">User Voice</p>
            </NavDropdown.Item>
            <NavDropdown.Item value={'University'} data-mssg={'University'} key={3}>
              <p className="userNavDropdown">University</p>
            </NavDropdown.Item> */}
            <NavDropdown.Item className="text-start" value={'Log Out'} data-mssg={'Log Out'} onClick={logout} key={1}>
              <span>
                <FontAwesomeIcon icon={faRightFromBracket} /> Log Out
              </span>
            </NavDropdown.Item>
          </NavDropdown>
        </Container>
      </Navbar>
    </>
  );
}
