import { Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Select, { createFilter } from 'react-select';
import { useAppSelector } from '../../common/appState/appStateHooks';
import { selectClientId } from '../../common/appState/currentClientSlice';
import { ClientSettings } from '../../models/ClientSettings';
import HeaderTitle from '../headerTitle/headerTitle';
import { useApiClient } from '../useApiClient/useApiClient';
import { selectClientModules } from '../../common/appState/clientModulesSlice';
import modules from '../feature/modules';

type Settings = Pick<ClientSettings, 'privacyModeFlag' | 'responderModeFlag' | 'timeZoneID'>;

enum ReducerActionType {
  privacyModeFlag = 'privacyModeFlag',
  responderModeFlag = 'responderModeFlag',
  timeZoneID = 'timeZoneID',
  reset = 'reset',
}

interface ReducerAction {
  type: ReducerActionType;
  payload: Settings;
}

const settingsReducer: Reducer<Settings, ReducerAction> = (state, action) => {
  switch (action.type) {
    case ReducerActionType.privacyModeFlag:
      return {
        ...state,
        privacyModeFlag: action.payload.privacyModeFlag,
      };
    case ReducerActionType.responderModeFlag:
      return {
        ...state,
        responderModeFlag: action.payload.responderModeFlag,
      };
    case ReducerActionType.timeZoneID:
      return {
        ...state,
        timeZoneID: action.payload.timeZoneID,
      };
    case ReducerActionType.reset:
      return init(action.payload);
    default:
      break;
  }
  return state;
};

const init = (settings: Settings): Settings => {
  return {
    privacyModeFlag: settings.privacyModeFlag,
    responderModeFlag: settings.responderModeFlag,
    timeZoneID: settings.timeZoneID,
  };
};

export default function AdminSettings() {
  const clientAliasID = useAppSelector(selectClientId);
  const apiClient = useApiClient();

  const [showCallTriageSettings, setShowCallTriageSettings] = useState(false);

  const clientModules = useAppSelector(selectClientModules);

  useEffect(() => {
    const callTriageIndex = clientModules.enabledModules.findIndex((cm) => cm.name === modules.callTriage);

    if (callTriageIndex > -1) {
      if (!showCallTriageSettings) {
        setShowCallTriageSettings(true);
      }
    } else {
      if (showCallTriageSettings) {
        setShowCallTriageSettings(false);
      }
    }
  }, [clientModules, showCallTriageSettings, setShowCallTriageSettings]);

  const initialSettings: Settings = {
    privacyModeFlag: false,
    responderModeFlag: false,
    timeZoneID: '',
  };
  const [settings, dispatch] = useReducer(settingsReducer, initialSettings, init);
  const [clientSettings, setClientSettings] = useState<ClientSettings>({});
  const [timeZoneOptions, setTimeZoneOptions] = useState<{ label: string; value: any }[]>([]);

  const loadTimeZones = useCallback(async () => {
    const timezoneList = await apiClient.getTimeZoneList();
    setTimeZoneOptions(timezoneList.map((tz) => ({ label: tz.text!, value: tz.value })));
  }, [apiClient]);

  useEffect(() => {
    loadTimeZones();
  }, [loadTimeZones]);

  const changeSettings = useCallback(
    (settings: ClientSettings) => {
      const newSettings = { ...clientSettings, ...settings };
      const putSettings = async () => {
        return await apiClient.putSettings('', clientAliasID, newSettings);
      };
      try {
        putSettings();
      } catch {
        throw Error('Unable to save settings');
      }
    },
    [apiClient, clientAliasID, clientSettings]
  );

  const onResponderSwitchAction = (responderModeFlag: boolean) => {
    const newSettings = {
      ...settings,
      responderModeFlag,
    };
    dispatch({
      type: ReducerActionType.responderModeFlag,
      payload: newSettings,
    });
    changeSettings(newSettings);
  };

  const onPrivacySwitchAction = (privacyModeFlag: boolean) => {
    const newSettings = {
      ...settings,
      privacyModeFlag,
    };
    dispatch({
      type: ReducerActionType.privacyModeFlag,
      payload: newSettings,
    });
    changeSettings(newSettings);
  };

  const onTimeZoneChange = (timeZoneID: string) => {
    const newSettings = {
      ...settings,
      timeZoneID,
    };
    dispatch({
      type: ReducerActionType.timeZoneID,
      payload: newSettings,
    });
    changeSettings(newSettings);
  };

  useEffect(() => {
    const handleClientSelect = async () => {
      try {
        const queryResult = await apiClient.getClientSettings('', clientAliasID);
        setClientSettings(queryResult);
        dispatch({
          type: ReducerActionType.reset,
          payload: queryResult,
        });
      } catch (err) {
        console.error('Failed to retrieve client settings', err);
      }
    };

    handleClientSelect();
  }, [apiClient, clientAliasID, setClientSettings]);

  return (
    <>
      <HeaderTitle title={'Settings'} />
      <div className="incidentBG h-100">
        <Form className="mx-3">
          {!showCallTriageSettings && (
            <>
              <Form.Group className="mt-3">
                <Form.Check
                  type="switch"
                  id="responder-mode-switch"
                  onChange={(event) => onResponderSwitchAction(event.target.checked)}
                  checked={settings.responderModeFlag}
                  label="Responder Mode"
                />
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Check
                  type="switch"
                  id="privacy-switch"
                  onChange={(event) => onPrivacySwitchAction(event.target.checked)}
                  checked={settings.privacyModeFlag}
                  label="Disable CAD Comments"
                />
              </Form.Group>
            </>
          )}
          <Form.Group className="mt-3">
            <Form.Label>Time Zone</Form.Label>
            <Select
              filterOption={createFilter({ ignoreAccents: false })}
              options={timeZoneOptions}
              value={timeZoneOptions.find((timezone) => timezone.value === settings.timeZoneID)}
              onChange={(event) => onTimeZoneChange(event?.value)}
            />
          </Form.Group>
        </Form>
      </div>
    </>
  );
}
