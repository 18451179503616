import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import { useAppSelector } from '../../common/appState/appStateHooks';
import { selectClientAlias } from '../../common/appState/currentClientSlice';
import { ReducerAction } from '../../common/reducerAction';
import { ClientUserInvitation } from '../../models/ClientUserInvitation';
import { Unique } from '../../models/Helpers/ArrayHelper';
import { isEmailValid } from '../../models/Helpers/EmailHelper';
import { getPaginationItems, getPaginationShowingString } from '../../models/Helpers/PaginationHelper';
import ClientUserInvitationCard from '../clientUserInvitationCard/clientUserInvitationCard';
import { useApiClient } from '../useApiClient/useApiClient';

enum InvitationTabName {
  single = 'single',
  multi = 'multi',
}

type NewInvitation = {
  clientID: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
};

interface InvitationState {
  activeModalTabName: string;
  invitedUsers: ClientUserInvitation[];
  multiInvitationEmailAddresses: string[];
  singleInvitation: NewInvitation;
  showInvitationModal: boolean;
  unparsedMultiInviteEmailAddresses: string;
}

interface PaginationState {
  currentPage: number;
  totalPages: number;
}

enum ReducerActions {
  createNewInvitation = 'createNewInvitation',
  hideInvitationModal = 'hideInvitationModel',
  showInvitationModal = 'showInvitationModel',
  updateActiveInvitationModalTab = 'updateInvitationModalTab',
  updateMultiInvitationEmailAddresses = 'updateMultiInvitationEmailAddresses',
  updateSingleInvitation = 'updateInvitation',
  updateInvitationModal = 'updateInvitationModal',
  updateInvitedUsers = 'updateInvitedUsers',
  updateUnparsedMultiInviteEmailAddresses = 'updateUnparsedMultiInviteEmailAddresses',
  updateCurrentPage = 'updateCurrentPage',
  updateTotalPages = 'updateTotalPages',
}

const paginationReducer: ImmerReducer<PaginationState, ReducerAction<number>> = (draft, action) => {
  switch (action.type) {
    case ReducerActions.updateCurrentPage:
      draft.currentPage = action.data;
      break;
    case ReducerActions.updateTotalPages:
      draft.totalPages = action.data;
      break;
  }
};

const initialPaginationState: PaginationState = {
  currentPage: 1,
  totalPages: 5,
};

const maxResultsToEverGet = 1000;
const defaultPageLength = 50;

const ManageInvitesTab = (props: { atUserLimit: boolean }) => {
  const [isSending, setIsSending] = useState(false);
  const clientAlias = useAppSelector(selectClientAlias);

  const initialInvitationState: InvitationState = useMemo(
    () => ({
      activeModalTabName: InvitationTabName.single,
      invitedUsers: [],
      multiInvitationEmailAddresses: [],
      singleInvitation: {
        clientID: clientAlias.clientID,
        firstName: '',
        lastName: '',
        emailAddress: '',
      },
      showInvitationModal: false,
      unparsedMultiInviteEmailAddresses: '',
    }),
    [clientAlias.clientID]
  );

  const invitationReducer: ImmerReducer<InvitationState, ReducerAction<InvitationState>> = (draft, action) => {
    switch (action.type) {
      case ReducerActions.createNewInvitation:
        draft.singleInvitation = { clientID: clientAlias.clientID, firstName: '', lastName: '', emailAddress: '' };
        break;
      case ReducerActions.hideInvitationModal:
        draft.showInvitationModal = false;
        break;
      case ReducerActions.showInvitationModal:
        draft.showInvitationModal = true;
        break;
      case ReducerActions.updateInvitedUsers:
        draft.invitedUsers = action.data.invitedUsers;
        break;
      case ReducerActions.updateSingleInvitation:
        draft.singleInvitation = { ...action.data.singleInvitation };
        break;
      case ReducerActions.updateMultiInvitationEmailAddresses:
        draft.multiInvitationEmailAddresses = action.data.multiInvitationEmailAddresses;
        break;
      case ReducerActions.updateUnparsedMultiInviteEmailAddresses:
        draft.unparsedMultiInviteEmailAddresses = action.data.unparsedMultiInviteEmailAddresses;
        break;
      case ReducerActions.updateInvitationModal:
        draft.showInvitationModal = action.data.showInvitationModal;
        break;
      case ReducerActions.updateActiveInvitationModalTab:
        draft.activeModalTabName = action.data.activeModalTabName;
        break;
    }
  };

  const [invitation, dispatchInvitation] = useImmerReducer(invitationReducer, initialInvitationState);
  const [pagination, dispatchPagination] = useImmerReducer(paginationReducer, initialPaginationState);
  const apiClient = useApiClient();

  const getAllClientUserInvitations = () => {
    const fetchInvitations = async () => {
      try {
        const clientUsersInvListResult = await apiClient.getClientUserInvitationsForClientID(
          { limit: maxResultsToEverGet, offset: 0 },
          clientAlias.clientID
        );

        // we only care about updating the invited users, which is why initialInvitationState is spread here
        dispatchInvitation({
          type: ReducerActions.updateInvitedUsers,
          data: { ...initialInvitationState, invitedUsers: clientUsersInvListResult },
        });
      } catch (err) {
        console.error('Failed to fetch clientuserinvitation data for clientuser count', err);
      }
    };

    fetchInvitations();
  };

  useEffect(getAllClientUserInvitations, [clientAlias.clientID, apiClient, initialInvitationState, dispatchInvitation]);

  //update page count from total count
  useEffect(() => {
    dispatchPagination({
      type: ReducerActions.updateTotalPages,
      data: Math.ceil(invitation.invitedUsers.length / defaultPageLength),
    });
  }, [invitation.invitedUsers, dispatchPagination]);

  const visibleInvitations = useMemo(() => {
    if (invitation.invitedUsers.length === 0) {
      return [];
    }

    return invitation.invitedUsers.slice(
      (pagination.currentPage - 1) * defaultPageLength,
      pagination.currentPage * defaultPageLength
    );
  }, [invitation.invitedUsers, pagination.currentPage]);

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const updatedInvitation: NewInvitation = { ...invitation.singleInvitation, firstName: event.target.value };
    dispatchInvitation({
      type: ReducerActions.updateSingleInvitation,
      data: { ...invitation, singleInvitation: updatedInvitation },
    });
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const updatedInvitation: NewInvitation = { ...invitation.singleInvitation, lastName: event.target.value };
    dispatchInvitation({
      type: ReducerActions.updateSingleInvitation,
      data: { ...invitation, singleInvitation: updatedInvitation },
    });
  };

  const handleEmailAddressChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const updatedInvitation: NewInvitation = {
      ...invitation.singleInvitation,
      emailAddress: event.target.value,
    };
    dispatchInvitation({
      type: ReducerActions.updateSingleInvitation,
      data: { ...invitation, singleInvitation: updatedInvitation },
    });
  };

  const handleInvitationEmailsChange = (event: any) => {
    dispatchInvitation({
      type: ReducerActions.updateUnparsedMultiInviteEmailAddresses,
      data: { ...invitation, unparsedMultiInviteEmailAddresses: event.target.value },
    });
    let maxInvitationsAllowed = 50;
    let emailAddressTextArea = event.target.value
      .replaceAll('<', '')
      .replaceAll('>', '')
      .replaceAll('\t', ' ')
      .replace(/(\r\n|\n|\r)/gm, '');
    let emailsAddressesFromEmailsList: string[] = emailAddressTextArea.split(/,| |;/).filter(function (e: any) {
      return e;
    });
    emailsAddressesFromEmailsList = Unique(emailsAddressesFromEmailsList.filter((email) => isEmailValid(email)));
    if (emailsAddressesFromEmailsList.length > maxInvitationsAllowed) {
      emailsAddressesFromEmailsList = emailsAddressesFromEmailsList.slice(0, maxInvitationsAllowed);
    }

    dispatchInvitation({
      type: ReducerActions.updateMultiInvitationEmailAddresses,
      data: { ...invitation, multiInvitationEmailAddresses: emailsAddressesFromEmailsList },
    });
  };

  const hideInviteModal = () => {
    dispatchInvitation({ type: ReducerActions.hideInvitationModal, data: invitation });
  };

  const handleInvitationSend = async (e: any) => {
    e.preventDefault();
    if (
      invitation.activeModalTabName === 'single' &&
      (invitation.singleInvitation.emailAddress === '' || !isEmailValid(invitation.singleInvitation.emailAddress))
    ) {
      // TODO: Validation error on single tab
      return;
    } else if (
      invitation.activeModalTabName === 'multi' &&
      (!invitation.multiInvitationEmailAddresses || invitation.multiInvitationEmailAddresses.length === 0)
    ) {
      // TODO: Validation error on multi tab
      return;
    } else if (invitation.activeModalTabName !== 'single' && invitation.activeModalTabName !== 'multi') {
      return;
    }

    setIsSending(true);

    if (invitation.activeModalTabName === 'single') {
      try {
        await apiClient.postClientUserInvitation(invitation.singleInvitation);

        getAllClientUserInvitations();
        dispatchInvitation({ type: ReducerActions.createNewInvitation, data: invitation });
      } catch (error: any) {
        console.error('Failed to send single invitation.', error);
      } finally {
        setIsSending(false);
      }
    } else if (invitation.activeModalTabName === 'multi') {
      let invitationList = invitation.multiInvitationEmailAddresses.map((invitation) => {
        return {
          clientID: clientAlias.clientID,
          firstname: '',
          lastname: '',
          emailAddress: invitation,
        };
      });

      try {
        await apiClient.postClientUserInvitationList(clientAlias.clientID, invitationList);

        getAllClientUserInvitations();

        if (invitation.multiInvitationEmailAddresses.length !== 0) {
          dispatchInvitation({
            type: ReducerActions.updateMultiInvitationEmailAddresses,
            data: { ...invitation, multiInvitationEmailAddresses: [] },
          });
        }

        dispatchInvitation({
          type: ReducerActions.updateUnparsedMultiInviteEmailAddresses,
          data: { ...invitation, unparsedMultiInviteEmailAddresses: '' },
        });
        setIsSending(false);
      } catch (error: any) {
        console.error('Failed to send multiple invitations.', error);
      } finally {
        setIsSending(false);
      }
    }
  };

  const updateClientUserInvitation = async (clientUserInvitation: ClientUserInvitation) => {
    try {
      await apiClient.putClientUserInvitation(clientUserInvitation);
      getAllClientUserInvitations();
    } catch (error: any) {
      console.error('Failed to update ClientUserInvitation data', error);
    }
  };

  const handleInviteEnabledClick = (event: any, clientUserInvitation: ClientUserInvitation) => {
    const newClientUserInvitation = { ...clientUserInvitation };
    newClientUserInvitation.active = !newClientUserInvitation.active;
    updateClientUserInvitation(newClientUserInvitation);
  };

  const handleInviteResendClick = (clientUserInvitation: ClientUserInvitation) => {
    apiClient.resendClientUserInvitation(clientUserInvitation);
  };

  const onNextPageClick = () => {
    if (pagination.currentPage + 1 <= pagination.totalPages) {
      dispatchPagination({ type: ReducerActions.updateCurrentPage, data: pagination.currentPage + 1 });
    }
  };

  const onPrevPageClick = () => {
    if (pagination.currentPage - 1 >= 1) {
      dispatchPagination({ type: ReducerActions.updateCurrentPage, data: pagination.currentPage - 1 });
    }
  };

  const onPageClick = (page: number) => {
    dispatchPagination({ type: ReducerActions.updateCurrentPage, data: page });
  };

  return (
    <>
      <div className="white-bg">
        {!props.atUserLimit && (
          <Button
            variant="primary"
            onClick={() => dispatchInvitation({ type: ReducerActions.showInvitationModal, data: invitation })}
          >
            Create new Invitation(s)
          </Button>
        )}
        <Modal size="lg" show={invitation.showInvitationModal} onHide={() => hideInviteModal()}>
          <Modal.Header closeButton>
            <Modal.Title>Send Invitation(s)</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              id="invitation-tabs"
              className="mb-3"
              onSelect={(k) =>
                k != null &&
                dispatchInvitation({
                  type: ReducerActions.updateActiveInvitationModalTab,
                  data: { ...invitation, activeModalTabName: k },
                })
              }
              activeKey={invitation.activeModalTabName}
            >
              <Tab eventKey="single" title="Single Invitation">
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.firstname">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      value={invitation.singleInvitation.firstName}
                      onChange={handleFirstNameChange}
                      autoFocus
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.lastname">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      value={invitation.singleInvitation.lastName}
                      onChange={handleLastNameChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.emailAddress">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      value={invitation.singleInvitation.emailAddress}
                      onChange={handleEmailAddressChange}
                    />
                  </Form.Group>
                </Form>
              </Tab>
              <Tab eventKey="multi" title="Multiple">
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.invitationEmails">
                    <Form.Label>Email Addresses</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={invitation.unparsedMultiInviteEmailAddresses}
                      placeholder="Enter email addresses separated by a space, comma, or semicolon. (Maximum of 50 emails)"
                      onChange={handleInvitationEmailsChange}
                    ></Form.Control>
                  </Form.Group>
                </Form>
                <span>
                  {invitation.multiInvitationEmailAddresses.map((inv) => (
                    <>
                      <Badge pill key={inv}>
                        {inv}
                      </Badge>
                      <span>&nbsp;</span>
                    </>
                  ))}
                </span>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => hideInviteModal()}>
              Close
            </Button>
            {isSending ? (
              <Button variant="primary" onClick={handleInvitationSend}>
                Sending...
                <Spinner as="span" variant="light" size="sm" role="status" aria-hidden="true" animation="border" />
              </Button>
            ) : (
              <Button variant="primary" onClick={handleInvitationSend}>
                Send Invitation(s)
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
      {visibleInvitations.length > 0 ? (
        <div className="wrapper white-bg">
          <h4>Existing Invitations</h4>
          <span className="text-normal">Click a button to Resend an Email Invitation, or Disable one.</span>
          <Table striped bordered hover className="list-table table table-striped" aria-label="Invited Users">
            <thead>
              <tr>
                <th>Status</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Actions</th>
                <th>Client Code</th>
                <th>Invitation Code</th>
                <th>Accepted On</th>
              </tr>
            </thead>
            <tbody>
              {visibleInvitations.map((data) => {
                return (
                  <ClientUserInvitationCard
                    key={data.clientUserInvitationID}
                    clientUserInvitationID={data.clientUserInvitationID}
                    firstname={data.firstname}
                    lastname={data.lastname}
                    emailAddress={data.emailAddress}
                    clientCode={data.clientCode}
                    invitationCode={data.invitationCode}
                    acceptedAwareUserID={data.acceptedAwareUserID}
                    acceptedOnUtc={data.acceptedOnUtc}
                    modifiedOnUtc={moment(data.modifiedOnUtc).format('HH:mm')}
                    handleDisableEnableClick={(e) => {
                      handleInviteEnabledClick(e, data);
                    }}
                    handleResendClick={(e) => handleInviteResendClick(data)}
                    active={data.active}
                  />
                );
              })}
            </tbody>
          </Table>
          <span>
            {getPaginationShowingString(pagination.currentPage, defaultPageLength, invitation.invitedUsers.length)}
          </span>
          {pagination.totalPages > 1 ? (
            <Pagination>
              <Pagination.Prev onClick={() => onPrevPageClick()} />
              {getPaginationItems(pagination.currentPage, pagination.totalPages, onPageClick)}
              <Pagination.Next onClick={() => onNextPageClick()} />
            </Pagination>
          ) : (
            <span></span>
          )}
        </div>
      ) : (
        <div className="wrapper white-bg">
          <h4>Existing Invitations</h4>
          <span>No existing invitations found.</span>
        </div>
      )}
    </>
  );
};

export default ManageInvitesTab;
