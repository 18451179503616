function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (_m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function blackOrWhite(hex?: string) {
  if (!hex) return 'it-white';
  const rgb = hexToRgb(hex) ?? { r: 0, g: 0, b: 0 };
  const total = rgb.r + rgb.g + rgb.b;
  return total - 382 > 0 ? 'it-black' : 'it-white';
}
