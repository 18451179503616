import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './appStore';
import ClientAlias from '../clientAlias';

const initialState: ClientAlias[] = [];

export const availableClientsSlice = createSlice({
  name: 'availableClients',
  initialState,
  reducers: {
    updateAvailableClients: (_state, action: PayloadAction<ClientAlias[]>) => action.payload,
  },
});

export const { updateAvailableClients } = availableClientsSlice.actions;

export const selectAvailableClients = (state: RootState) => state.availableClients;

export default availableClientsSlice.reducer;
