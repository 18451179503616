export async function postData(url = '', data = {}, token?: string) {
  const headers: { [key: string]: string } = {
    'Content-type': 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const requestInfo: RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(data),
  };
  const response = await fetch(url, requestInfo);

  return response;
}

export async function putData(url = '', data = {}, token?: string) {
  const headers: { [key: string]: string } = {
    'Content-type': 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const requestInfo: RequestInit = {
    method: 'PUT',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(data),
  };
  const response = await fetch(url, requestInfo);

  return response;
}

export async function getData(url = '', token: String) {
  const headers: { [key: string]: string } = {
    'Content-type': 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  });
  return response;
}
