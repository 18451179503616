import { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TokenContext from '../../common/tokenContext';

const Authenticated = ({ children }: PropsWithChildren<{}>) => {
  const [renderChildren, setRenderChildren] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.accessToken) {
      const tokenContext = new TokenContext(sessionStorage.accessToken);
      tokenContext.useToken(
        (token) => {
          if (!renderChildren) {
            setRenderChildren(true);
          }
          return Promise.resolve();
        },
        () => {
          navigate('/');
          return Promise.resolve();
        }
      );
    }
  }, [renderChildren, navigate]);

  if (renderChildren) {
    return <>{children}</>;
  }

  return <></>;
};

export default Authenticated;
