import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@imagetrend/fontawesome-pro/pro-solid-svg-icons';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Reducer, useEffect, useReducer } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Stack from 'react-bootstrap/Stack';
import { Link } from 'react-router-dom';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import api from '../../common/api';
import { useAppSelector } from '../../common/appState/appStateHooks';
import { selectClientId } from '../../common/appState/currentClientSlice';
import { ReducerAction } from '../../common/reducerAction';
import routes from '../../common/routesDefinitions';
import { SignalOption, getSignalOptions } from '../../common/types';
import { dateTimeUTCToLocal, dateToDateString, dateToTimeString } from '../../models/Helpers/DateHelper';
import { postData } from '../../models/Helpers/FetchHelper';
import QueryResult from '../../models/QueryResult';
import { DateSelector } from '../common/dateSelector';
import { SignalMultiSelect } from '../common/signalSelect';
import SignalTable from '../common/signalTable';
import { IncidentDateRange, IncidentDateRangeType, SingleDateRangeOptions } from '../incidentSearch/IncidentDateRange';
import SignalPagination from '../pagination/pagination';
import { OffsetLimit, PaginationReducer } from '../pagination/types';
import { useApiClient } from '../useApiClient/useApiClient';
import './eventLog.css';
import CallTriageRightHandNav from './rightHandNav';
import CallTriageTopNav from './topNav';

interface EventLogTable {
  incidentID: string;
  startOnUtc: Date;
  incidentStatusID: string;
  status: string;
  incidentNumber: string;
  incidentTypeID: string;
  type: string;
  incidentSubtypeID: string;
  subtype: string;
  primaryResourceAgencyID: string;
  agencyName: string;
  primaryResourceID: string;
  incidentDispositionID: string;
  disposition: string;
  initialDestinationName: string;
  finalDestinationName: string;
  createdBy: string;
  createdByFirstname: string;
  createdByLastname: string;
}

type EventLogParameters = {
  dateTo: string;
  dateFrom: string;
  incidentDateOption: IncidentDateRangeType;
  primaryResourceID?: string;
  primaryResourceAgencyIDs: number[];
  incidentTypeIDs: number[];
  destinationNames: string[];
} & OffsetLimit;

// Search actions:
enum EventLogActions {
  incidentDateOption = 'incidentDateOption',
  customDate = 'fromAndToDate',
  setIncidentType = 'setIncidentType',
  setDestinationName = 'setDestinationName',
  setAgency = 'setAgency',
  setPrimaryResourceID = 'setPrimaryResourceID',
  all = 'all',
  toDate = 'toDate',
  fromDate = 'fromDate',
}

// manages which search options have been provided
const eventLogSearchReducer: ImmerReducer<EventLogParameters, ReducerAction<EventLogParameters>> = (draft, action) => {
  switch (action.type) {
    case EventLogActions.incidentDateOption:
      draft.incidentDateOption = action.data.incidentDateOption;
      const { dateFrom, dateTo } = IncidentDateRange[action.data.incidentDateOption].getDate(
        true,
        action.data.dateFrom,
        action.data.dateTo
      );
      draft.dateTo = dateTo;
      draft.dateFrom = dateFrom;
      break;
    case EventLogActions.fromDate:
      draft.dateFrom = action.data.dateFrom;
      break;
    case EventLogActions.toDate:
      draft.dateTo = action.data.dateTo;
      break;
    case EventLogActions.customDate:
      draft.dateFrom = action.data.dateFrom;
      draft.dateTo = action.data.dateTo;
      break;
    case EventLogActions.setIncidentType:
      draft.incidentTypeIDs = action.data.incidentTypeIDs;
      break;
    case EventLogActions.setDestinationName:
      draft.destinationNames = action.data.destinationNames;
      break;
    case EventLogActions.setAgency:
      draft.primaryResourceAgencyIDs = action.data.primaryResourceAgencyIDs;
      break;
    case EventLogActions.setPrimaryResourceID:
      draft.primaryResourceID = action.data.primaryResourceID;
      break;
    case EventLogActions.all:
      draft.incidentDateOption = action.data.incidentDateOption;
      let resetDate = IncidentDateRange[action.data.incidentDateOption].getDate(
        true,
        action.data.dateFrom,
        action.data.dateTo
      );
      draft.dateTo = resetDate.dateTo;
      draft.dateFrom = resetDate.dateFrom;
      draft.incidentTypeIDs = action.data.incidentTypeIDs;
      draft.destinationNames = action.data.destinationNames;
      draft.primaryResourceAgencyIDs = action.data.primaryResourceAgencyIDs;
      draft.primaryResourceID = action.data.primaryResourceID;
      break;
    default:
      PaginationReducer(draft, action);
      break;
  }
};

// query actions which may be handled by the query reducer
enum QueryActions {
  autoRetrieve = 'autoRetrieve',
  showLoadingIndicator = 'showLoadingIndicator',
}

type QueryState = { enableAutoFetch: boolean; showLoadingIndicator: boolean };

// manages query options, primarily auto-fetching of results; when a search is invoked via the
// Go button, we enable auto fetch which tells react-query to auto-retrieve results based on its
// configured defaults
const queryReducer: ImmerReducer<QueryState, ReducerAction<QueryState>> = (draft, action) => {
  switch (action.type) {
    case QueryActions.autoRetrieve:
      draft.enableAutoFetch = action.data.enableAutoFetch;
      break;
  }
};

const initialQueryState: QueryState = {
  enableAutoFetch: false,
  showLoadingIndicator: false,
};

enum EventLogOptionsAction {
  setOptions = 'setOptions',
}

interface EventLogOptions {
  types: SignalOption<number>[];
  agencies: SignalOption<number>[];
  destinationNames: SignalOption<string>[];
}

const initialOptions: EventLogOptions = {
  types: [],
  agencies: [],
  destinationNames: [],
};

const optionsReducer: Reducer<EventLogOptions, ReducerAction<EventLogOptions>> = (state, action) => {
  switch (action.type) {
    case EventLogOptionsAction.setOptions:
      return {
        ...state,
        types: action.data.types,
        agencies: action.data.agencies,
        destinationNames: action.data.destinationNames,
      };
  }
  return state;
};

const CallTriageEventLog = () => {
  const apiClient = useApiClient();
  const defaultDateOption = IncidentDateRangeType.Past2Hours;
  const initialState: EventLogParameters = {
    dateFrom: IncidentDateRange[defaultDateOption].getDate(true).dateFrom,
    dateTo: IncidentDateRange[defaultDateOption].getDate(true).dateTo,
    incidentDateOption: defaultDateOption,
    primaryResourceID: '',
    primaryResourceAgencyIDs: [],
    incidentTypeIDs: [],
    destinationNames: [],
    limit: 10,
    offset: 0,
  };
  const columnHelper = createColumnHelper<EventLogTable>();
  const clientID = useAppSelector(selectClientId);
  const [search, updateSearchOptions] = useImmerReducer(eventLogSearchReducer, initialState);
  const [queryConfig, updateQueryConfig] = useImmerReducer(queryReducer, initialQueryState);
  const [options, updateSelectOptions] = useReducer(optionsReducer, initialOptions);

  const searchIncidents = async () => {
    let dateTo = search.dateTo;
    // due to date rounding assumptions, append as assuming EOD.
    if (!dateTo.includes('T')) {
      dateTo += 'T23:59:59.9999999';
    }

    var searchResponse = await postData(
      `${api.baseUrl}/api/clients/${clientID}/incidents/search`,
      { ...search, dateTo },
      sessionStorage.accessToken
    );

    updateQueryConfig({
      type: QueryActions.showLoadingIndicator,
      data: { ...queryConfig, showLoadingIndicator: false },
    });

    if (searchResponse.ok) {
      const queryResult = (await searchResponse.json()) as QueryResult<EventLogTable>;
      return queryResult;
    }

    throw new Error('Unable to load incident search results');
  };

  const handleTypeChange = (typeIDs: number[]) => {
    updateSearchOptions({
      type: EventLogActions.setIncidentType,
      data: {
        ...search,
        incidentTypeIDs: typeIDs,
      },
    });
  };

  const handleDestinationChange = (dests: string[]) => {
    updateSearchOptions({
      type: EventLogActions.setDestinationName,
      data: {
        ...search,
        destinationNames: dests,
      },
    });
  };

  const handleAgencyChange = (agencyIDs: number[]) => {
    updateSearchOptions({
      type: EventLogActions.setAgency,
      data: {
        ...search,
        primaryResourceAgencyIDs: agencyIDs,
      },
    });
  };

  const handlePrimaryResourceIDChange = (resourceID: string) => {
    updateSearchOptions({
      type: EventLogActions.setPrimaryResourceID,
      data: {
        ...search,
        primaryResourceID: resourceID,
      },
    });
  };

  const handleFromDateChange = (dateString: string) => {
    updateSearchOptions({
      type: EventLogActions.fromDate,
      data: {
        ...search,
        dateFrom: dateString,
      },
    });
  };

  const handleToDateChange = (dateString: string) => {
    updateSearchOptions({
      type: EventLogActions.toDate,
      data: {
        ...search,
        dateTo: dateString,
      },
    });
  };

  useEffect(() => {
    const loadResources = async () => {
      const rTypes = await apiClient.getCallTriageIncidentTypes();
      const rAgencies = await apiClient.getCallTriageAgencies(clientID);
      const destination = await apiClient.getDestinations();

      updateSelectOptions({
        type: EventLogOptionsAction.setOptions,
        data: {
          types: rTypes.map((t) => ({
            value: t.incidentTypeID,
            label: t.name,
          })),
          agencies: rAgencies.map((t) => ({
            value: t.agencyID,
            label: t.name,
          })),
          destinationNames: destination
            .sort((a, b) => (a.agencyName.toLowerCase() > b.agencyName.toLowerCase() ? 1 : -1))
            .map((t) => ({
              value: t.agencyName,
              label: t.agencyName,
            })),
        },
      });
    };
    loadResources();
  }, [apiClient, updateSelectOptions, clientID]);

  const {
    status,
    data: searchResults,
    refetch,
  } = useQuery(['eventLogSearchResults'], searchIncidents, {
    enabled: queryConfig.enableAutoFetch,
    refetchInterval: 10000,
  });

  const searchAndEnableAutoRefetch = () => {
    updateQueryConfig({
      type: QueryActions.showLoadingIndicator,
      data: { ...queryConfig, showLoadingIndicator: true },
    });
    refetch(); // fire off the search query
    updateQueryConfig({
      type: QueryActions.autoRetrieve,
      data: { ...queryConfig, enableAutoFetch: true },
    }); // enable auto fetching of query results (live updates)
  };

  // Run the search on page load
  useEffect(() => {
    updateQueryConfig({
      type: QueryActions.showLoadingIndicator,
      data: { ...queryConfig, showLoadingIndicator: true },
    });
    refetch(); // fire off the search query
    updateQueryConfig({
      type: QueryActions.autoRetrieve,
      data: { ...queryConfig, enableAutoFetch: true },
    }); // enable auto fetching of query results (live updates)
  }, [search, queryConfig, refetch, updateQueryConfig]);

  const resetFilter = () => {
    updateSearchOptions({
      type: EventLogActions.all,
      data: {
        ...initialState,
      },
    });
  };

  const resultColumns: ColumnDef<EventLogTable, any>[] = [
    columnHelper.accessor(
      (row) => dateToDateString(row.startOnUtc != null ? dateTimeUTCToLocal(row.startOnUtc) : new Date()),
      {
        header: 'Incident Date',
        id: 'incidentDate',
      }
    ),
    columnHelper.accessor(
      (row) => dateToTimeString(row.startOnUtc != null ? dateTimeUTCToLocal(row.startOnUtc) : new Date()),
      {
        header: 'Incident Time',
        id: 'incidentTime',
      }
    ),
    columnHelper.accessor('status', { header: 'Incident Status' }),
    columnHelper.accessor('incidentNumber', { header: 'Incident Number' }),
    columnHelper.accessor('type', { header: 'Incident Type' }),
    columnHelper.accessor('subtype', { header: 'Incident Sub Type' }),
    columnHelper.accessor('agencyName', { header: 'Primary Resource Agency' }),
    columnHelper.accessor('primaryResourceID', { header: 'Primary Resource ID' }),
    columnHelper.accessor('disposition', { header: 'Disposition' }),
    columnHelper.accessor('finalDestinationName', { header: 'Destination' }),
    columnHelper.accessor((row) => row.createdByFirstname + ' ' + row.createdByLastname, {
      header: 'Incident Logged By',
    }),
    {
      header: '',
      cell: ({ row }) => (
        <>
          <Link to={`${routes.callTriageIncident}/${row.original.incidentID}`}>
            <Button variant="secondary">
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </Link>
        </>
      ),
      id: 'incidentActions',
    },
  ];

  return (
    <>
      <CallTriageRightHandNav />
      <div className="h-100">
        <div className="mainContent-gray pb-3">
          <CallTriageTopNav />
          <Form>
            <Row className="align-items-end m-1">
              <DateSelector
                className="my-1"
                utc
                options={SingleDateRangeOptions}
                defaultValue={search.incidentDateOption}
                toDateValue={search.dateTo}
                fromDateValue={search.dateFrom}
                handleFromDateChange={handleFromDateChange}
                handleToDateChange={handleToDateChange}
              />
              <Col className="col-auto my-1">
                <Form.Group>
                  <SignalMultiSelect
                    placeholder="Incident Types"
                    aria-label="Incident Types"
                    defaultValue={options.types[0]}
                    value={getSignalOptions(search.incidentTypeIDs, options.types)}
                    options={options.types}
                    onChange={(x) => handleTypeChange((x as SignalOption<number>[]).map((y) => y.value))}
                  />
                </Form.Group>
              </Col>
              <Col className="col-auto my-1">
                <Form.Group>
                  <SignalMultiSelect
                    placeholder="Destination Names"
                    aria-label="Destination Names"
                    value={getSignalOptions(search.destinationNames, options.destinationNames)}
                    options={options.destinationNames}
                    onChange={(x) => handleDestinationChange((x as SignalOption<string>[]).map((y) => y.value))}
                  />
                </Form.Group>
              </Col>
              <Col className="col-auto my-1">
                <Form.Group>
                  <SignalMultiSelect
                    placeholder="Primary Resource Agencies"
                    aria-label="Primary Resource Agencies"
                    defaultValue={options.agencies[0]}
                    value={getSignalOptions(search.primaryResourceAgencyIDs, options.agencies)}
                    options={options.agencies}
                    onChange={(x) => handleAgencyChange((x as SignalOption<number>[]).map((y) => y.value))}
                  />
                </Form.Group>
              </Col>
              <Col className="col-auto my-1">
                <Form.Group>
                  <Form.Control
                    placeholder="Primary Resource ID"
                    aria-label="Primary Resource ID"
                    value={search.primaryResourceID}
                    onChange={(e) => handlePrimaryResourceIDChange(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col className="col-auto my-1">
                <Stack direction="horizontal" gap={2}>
                  <Button
                    variant="it-dark-blue"
                    color="it-eggshell"
                    onClick={searchAndEnableAutoRefetch}
                    disabled={queryConfig.showLoadingIndicator}
                  >
                    {queryConfig.showLoadingIndicator && <Spinner animation="border" role="status" />}Search
                  </Button>
                  <Button variant="danger" onClick={resetFilter}>
                    Reset
                  </Button>
                </Stack>
              </Col>
            </Row>
          </Form>
        </div>
        {queryConfig.showLoadingIndicator && <Spinner animation="border" />}
        {status === 'error' && <Alert>Something went wrong loading search results</Alert>}
        {status === 'success' && searchResults && searchResults.totalRows > 0 && (
          <Col className="col-auto">
            <SignalTable
              tableClassName="tableFixHead"
              columns={resultColumns}
              data={searchResults.results}
              striped={true}
            />
            <SignalPagination
              paginationQuery={search}
              updatePaginationQuery={updateSearchOptions}
              total={searchResults.totalRows}
            />
          </Col>
        )}
      </div>
    </>
  );
};

export default CallTriageEventLog;
