import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import { ReducerAction } from '../../common/reducerAction';
import {
  getPaginationItems,
  getPaginationItems2,
  getPaginationShowingString,
} from '../../models/Helpers/PaginationHelper';
import { OffsetLimit, PaginationQuery } from './types';

interface SignalPaginationProps<R extends OffsetLimit> {
  paginationQuery: R;
  updatePaginationQuery: React.Dispatch<ReducerAction<R>>;
  total: number;
  short?: boolean;
}

const SignalPagination = <R extends OffsetLimit>({
  paginationQuery,
  updatePaginationQuery,
  total,
  short,
}: SignalPaginationProps<R>) => {
  const currentPage = paginationQuery.offset / paginationQuery.limit + 1;
  const totalPages = Math.ceil(total / paginationQuery.limit);

  const handleOnChange = (offset: number) => {
    updatePaginationQuery({
      type: PaginationQuery.offset,
      data: {
        ...paginationQuery,
        offset,
      },
    });
  };

  return (
    <>
      <Row className="mx-1">
        <p>{getPaginationShowingString(currentPage, paginationQuery.limit, total)}</p>
      </Row>
      {totalPages > 1 ? (
        <Pagination>
          <Pagination.Prev
            disabled={currentPage <= 1}
            onClick={() => handleOnChange(paginationQuery.offset - paginationQuery.limit)}
          />
          {!short &&
            getPaginationItems(currentPage, totalPages, (e) => handleOnChange((e - 1) * paginationQuery.limit))}
          {short &&
            getPaginationItems2(currentPage, totalPages, (e) => handleOnChange((e - 1) * paginationQuery.limit))}
          <Pagination.Next
            disabled={currentPage >= totalPages}
            onClick={() => handleOnChange(paginationQuery.offset + paginationQuery.limit)}
          />
        </Pagination>
      ) : (
        <span></span>
      )}
    </>
  );
};

export default SignalPagination;
