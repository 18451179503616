import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import api from '../../../common/api';
import { useAppSelector } from '../../../common/appState/appStateHooks';
import { selectClientId } from '../../../common/appState/currentClientSlice';
import { ReducerAction } from '../../../common/reducerAction';
import QueryResult from '../../../models/QueryResult';
import Report from '../reportBase';
import { DefaultReportParameters, DefaultReportReducer, DefaultUtcReportInitialState } from '../reportParameters';

type ReportResponse = {
  destinationName: string;
  medical: number;
  trauma: number;
  behavioralHealth: number;
  traumaActivation: number;
  stroke: number;
  obstetrics: number;
  burn: number;
  stemi: number;
  clinic: number;
  cardiacArrest: number;
  mci: number;
  total: number;
  percentage: string;
};

type ReportParameters = {} & DefaultReportParameters;

const ReportInitialState: ReportParameters = {
  ...DefaultUtcReportInitialState,
};

const ReportReducer: ImmerReducer<ReportParameters, ReducerAction<ReportParameters>> = (draft, action) => {
  switch (action.type) {
    default:
      DefaultReportReducer(draft, action);
      break;
  }
};

const CallTriageTransportsByDestinationReport = (): JSX.Element => {
  const currentClientId = useAppSelector(selectClientId);
  const columnHelper = createColumnHelper<ReportResponse>();
  const [reportQuery, updateReportQuery] = useImmerReducer(ReportReducer, ReportInitialState);

  const resultColumns: (queryResult: QueryResult<ReportResponse>) => ColumnDef<ReportResponse, any>[] = (
    queryResult
  ) => [
    columnHelper.accessor('destinationName', {
      header: 'Destination',
      footer: queryResult.totalResult?.destinationName,
    }),
    columnHelper.accessor('medical', { header: 'Medical', footer: queryResult.totalResult?.medical?.toString() }),
    columnHelper.accessor('trauma', { header: 'Trauma', footer: queryResult.totalResult?.trauma?.toString() }),
    columnHelper.accessor('behavioralHealth', {
      header: 'Behavioral Health',
      footer: queryResult.totalResult?.behavioralHealth?.toString(),
    }),
    columnHelper.accessor('traumaActivation', {
      header: 'Trauma Activation',
      footer: queryResult.totalResult?.traumaActivation?.toString(),
    }),
    columnHelper.accessor('stroke', { header: 'Stroke', footer: queryResult.totalResult?.stroke?.toString() }),
    columnHelper.accessor('obstetrics', {
      header: 'Obstetrics',
      footer: queryResult.totalResult?.obstetrics?.toString(),
    }),
    columnHelper.accessor('burn', { header: 'Burn', footer: queryResult.totalResult?.burn?.toString() }),
    columnHelper.accessor('stemi', { header: 'STEMI', footer: queryResult.totalResult?.stemi?.toString() }),
    columnHelper.accessor('clinic', { header: 'Clinic', footer: queryResult.totalResult?.clinic?.toString() }),
    columnHelper.accessor('cardiacArrest', {
      header: 'Cardiac Arrest',
      footer: queryResult.totalResult?.cardiacArrest?.toString(),
    }),
    columnHelper.accessor('mci', { header: 'MCI', footer: queryResult.totalResult?.mci?.toString() }),
    columnHelper.accessor('total', { header: 'Total', footer: queryResult.totalResult?.total?.toString() }),
    columnHelper.accessor('percentage', { header: '% of Total', footer: queryResult.totalResult?.percentage }),
  ];

  return (
    <>
      <Report.Base
        title={'Call Triage - Total Count of Transports by Destination'}
        columns={resultColumns}
        fetchUrl={`${api.baseUrl}/api/clients/${currentClientId}/reports/callTriage/transportsByDestination`}
        reportQuery={reportQuery}
        updateReportQuery={updateReportQuery}
        showFooter={true}
        utc={true}
      ></Report.Base>
    </>
  );
};

export default CallTriageTransportsByDestinationReport;
