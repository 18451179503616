import './Stepper.css';

import React from 'react';

import { StepProps } from './Step';

export interface StepperProps {
  /**
   * Set the active step (zero based index).
   * Set to -1 to disable all the steps.
   */
  activeStep?: number;

  /**
   * If set the `Stepper` will not assist in controlling steps for linear flow.
   */
  nonLinear?: boolean;

  /**
   * Two or more `<Step />` components.
   */
  children: React.ReactNode;
}

const Stepper: React.FC<StepperProps> = (props) => {
  const { activeStep = 0, nonLinear = false, children } = props;

  const childrenArray = React.Children.toArray(children);
  const steps = childrenArray.map((step, index) => {
    if (!React.isValidElement(step)) {
      return null;
    }

    const stepProps: Partial<StepProps> = {
      index,
      last: index + 1 === childrenArray.length,
      active: false,
      completed: false,
      disabled: false,
    };
    if (activeStep === index) {
      stepProps.active = true;
    } else if (activeStep > index) {
      stepProps.completed = true;
    } else if (!nonLinear && activeStep < index) {
      stepProps.disabled = true;
    }

    return React.cloneElement(step, { ...stepProps, ...step.props });
  });

  return <div className="stepper">{steps}</div>;
};
export default Stepper;
