import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@imagetrend/fontawesome-pro/sharp-regular-svg-icons/faDownload';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Stack from 'react-bootstrap/Stack';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import { ReducerAction } from '../../common/reducerAction';
import routes from '../../common/routesDefinitions';
import { postData } from '../../models/Helpers/FetchHelper';
import QueryResult from '../../models/QueryResult';
import { DateSelector } from '../common/dateSelector';
import SignalTable from '../common/signalTable';
import HeaderTitle from '../headerTitle/headerTitle';
import SignalPagination from '../pagination/pagination';
import { PaginationQuery } from '../pagination/types';
import { DefaultReportInitialState, DefaultReportParameterActions, DefaultReportParameters } from './reportParameters';

// query actions which may be handled by the query reducer
enum QueryActions {
  autoRetrieve = 'autoRetrieve',
  showLoadingIndicator = 'showLoadingIndicator',
}

type QueryState = { enableAutoFetch: boolean; showLoadingIndicator: boolean };

// manages query options, primarily auto-fetching of results; when a search is invoked via the
// Go button, we enable auto fetch which tells react-query to auto-retrieve results based on its
// configured defaults
const queryReducer: ImmerReducer<QueryState, ReducerAction<QueryState>> = (draft, action) => {
  switch (action.type) {
    case QueryActions.autoRetrieve:
      draft.enableAutoFetch = action.data.enableAutoFetch;
      break;
  }
};

const initialQueryState: QueryState = {
  enableAutoFetch: false,
  showLoadingIndicator: false,
};

interface ReportBaseProps<T, R extends DefaultReportParameters> {
  title: string;
  columns: ColumnDef<T>[] | ((queryResult: QueryResult<T>) => ColumnDef<T>[]);
  fetchUrl: string;
  children?: React.ReactNode;
  reportQuery: R;
  updateReportQuery: React.Dispatch<ReducerAction<R>>;
  showFooter?: boolean;
  utc?: boolean;
}

const ReportBase = <T, R extends DefaultReportParameters>({
  title,
  reportQuery,
  updateReportQuery,
  showFooter = false,
  ...props
}: ReportBaseProps<T, R>) => {
  const [queryConfig, updateQueryConfig] = useImmerReducer(queryReducer, initialQueryState);

  const getReportResults = async () => {
    let dateTo = reportQuery.dateTo;
    // due to date rounding assumptions, append as assuming EOD.
    if (!dateTo.includes('T')) {
      dateTo += 'T23:59:59.9999999';
    }

    var searchResponse = await postData(props.fetchUrl, { ...reportQuery, dateTo }, sessionStorage.accessToken);

    updateQueryConfig({
      type: QueryActions.showLoadingIndicator,
      data: { ...queryConfig, showLoadingIndicator: false },
    });

    if (searchResponse.ok) {
      return (await searchResponse.json()) as QueryResult<T>;
    }

    throw new Error('Unable to load incident report results');
  };

  const handleFromDateChange = (dateString: string) => {
    updateReportQuery({
      type: DefaultReportParameterActions.fromDate,
      data: {
        ...reportQuery,
        dateFrom: dateString,
      },
    });
  };

  const handleToDateChange = (dateString: string) => {
    updateReportQuery({
      type: DefaultReportParameterActions.toDate,
      data: {
        ...reportQuery,
        dateTo: dateString,
      },
    });
  };

  // search result querying
  const {
    status,
    data: searchResults,
    refetch,
  } = useQuery([title], getReportResults, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetch();
  }, [reportQuery.offset, refetch]);

  const downloadCsvReport = () => {
    const { offset, limit, ...data } = reportQuery;
    fetch(`${props.fetchUrl}/raw`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.accessToken}`,
        'Accept': 'text/csv',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error('Unable to download file.');
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `${title}.csv`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  };

  return (
    <>
      <HeaderTitle title={title} backTo={routes.reporting}>
        <Button variant="secondary" title={'Download CSV'} onClick={downloadCsvReport}>
          <FontAwesomeIcon icon={faDownload} /> Download CSV
        </Button>
      </HeaderTitle>
      <div className="pb-1">
        <Form>
          <Row className="align-items-end mx-1">
            <DateSelector
              defaultValue={DefaultReportInitialState.dateOption}
              toDateValue={reportQuery.dateTo}
              fromDateValue={reportQuery.dateFrom}
              handleFromDateChange={handleFromDateChange}
              handleToDateChange={handleToDateChange}
              utc={props.utc}
            />
            {props.children}
            <Col className={'mt-2 col-auto'}>
              <Stack direction="horizontal" gap={2}>
                <Button
                  onClick={() => {
                    updateReportQuery({
                      type: PaginationQuery.offset,
                      data: {
                        ...reportQuery,
                        offset: 0,
                      },
                    });
                    updateQueryConfig({
                      type: QueryActions.showLoadingIndicator,
                      data: { ...queryConfig, showLoadingIndicator: true },
                    });
                    refetch();
                    updateQueryConfig({
                      type: QueryActions.autoRetrieve,
                      data: { ...queryConfig, enableAutoFetch: true },
                    });
                  }}
                  disabled={queryConfig.showLoadingIndicator}
                >
                  {queryConfig.showLoadingIndicator && <Spinner animation="border" role="status" />}Search
                </Button>
              </Stack>
            </Col>
          </Row>
        </Form>
      </div>
      {queryConfig.showLoadingIndicator && <Spinner animation="border" />}
      {status === 'error' && <Alert>Something went wrong loading search results</Alert>}
      {status === 'success' && searchResults && (
        <Col className="col-auto tableFixHead">
          <SignalTable
            columns={props.columns instanceof Array ? props.columns : props.columns(searchResults)}
            data={searchResults.results}
            striped={true}
            showFooter={showFooter}
          />
          <SignalPagination
            paginationQuery={reportQuery}
            updatePaginationQuery={updateReportQuery}
            total={searchResults.totalRows}
          />
        </Col>
      )}
    </>
  );
};

const Report = {
  Base: ReportBase,
};
export default Report;
