import Form from 'react-bootstrap/Form';
import ReactSelect from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';

export type SignalSelectProps = {
  isInvalid?: boolean;
  isValid?: boolean;
  feedback?: string;
} & StateManagerProps<unknown, false>;

const SignalSingleSelect = ({ isInvalid, isValid, feedback, id, ...props }: SignalSelectProps) => {
  return (
    <>
      <ReactSelect
        {...props}
        isSearchable
        aria-invalid={isInvalid}
        classNames={{
          control: (props) => {
            return `${props.className ?? ''} ${
              isValid === true && !props.isFocused
                ? 'border-success'
                : isInvalid === true && !props.isFocused
                ? 'border-danger'
                : ''
            }`;
          },
          container: (props) => {
            return `${props.className ?? ''} ${isInvalid === true && !props.isFocused ? 'is-invalid' : ''}`;
          },
        }}
        styles={{
          menu: (base) => ({
            ...base,
            width: 'max-content',
            minWidth: '100%',
            zIndex: 9999,
          }),
        }}
      />
      {feedback && <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>}
    </>
  );
};

export type SignalMultiSelectProps = {
  isInvalid?: boolean;
  isValid?: boolean;
  feedback?: string;
} & StateManagerProps<unknown, true>;

const SignalMultiSelect = ({ isInvalid, isValid, feedback, ...props }: SignalMultiSelectProps) => {
  return (
    <>
      <ReactSelect
        {...props}
        isSearchable
        isMulti
        classNames={{
          control: (props) => {
            return `${props.className ?? ''} ${
              isValid === true && !props.isFocused
                ? 'border-success'
                : isInvalid === true && !props.isFocused
                ? 'border-danger'
                : ''
            }`;
          },
          container: (props) => {
            return `${props.className ?? ''} ${isInvalid === true && !props.isFocused ? 'is-invalid' : ''}`;
          },
        }}
        styles={{
          menu: (base) => ({
            ...base,
            width: 'max-content',
            minWidth: '100%',
            zIndex: 9999,
          }),
        }}
      />
      {feedback && <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>}
    </>
  );
};

export { SignalSingleSelect, SignalMultiSelect };
