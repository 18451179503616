import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ReactSelect from 'react-select';
import { useAppSelector } from '../../common/appState/appStateHooks';
import { selectClientId } from '../../common/appState/currentClientSlice';
import { ReducerAction } from '../../common/reducerAction';
import { SignalOption, getSignalOptions } from '../../common/types';
import { SignalMultiSelect, SignalSingleSelect } from '../common/signalSelect';
import { ResponderStatus, ResponderStatusKey } from '../responderMode/types';
import { useApiClient } from '../useApiClient/useApiClient';
import { DefaultReportParameters } from './reportParameters';
import { ResponseAction, ResponseActionToResponderStatusKey } from './types';

interface QueryProps<R extends DefaultReportParameters> {
  reportQuery: R;
  updateReportQuery: React.Dispatch<ReducerAction<R>>;
}

enum QueryParametersEnum {
  IncidentNumber = 'incidentNumber',
  ResponseActions = 'responseActions',
  ResponderStatuses = 'responderStatuses',
  UserIDs = 'userIDs',
  DestinationIDs = 'DestinationIDs',
}

const IncidentNumber = <R extends DefaultReportParameters & { incidentNumber: string }>({
  reportQuery,
  updateReportQuery,
}: QueryProps<R>) => {
  const handleIncidentNumberChange = (incidentNumber: string) => {
    updateReportQuery({
      type: QueryParametersEnum.IncidentNumber,
      data: {
        ...reportQuery,
        incidentNumber,
      },
    });
  };

  return (
    <>
      <Col className="col-auto">
        <Form.Group>
          <Form.Control
            id="incident-number"
            placeholder="Incident Number"
            value={reportQuery.incidentNumber}
            onChange={(e) => handleIncidentNumberChange(e.target.value)}
          />
        </Form.Group>
      </Col>
    </>
  );
};

const ResponseActions = <R extends DefaultReportParameters & { responseActions: ResponseAction[] }>({
  reportQuery,
  updateReportQuery,
}: QueryProps<R>) => {
  const handleOnChange = (responseActions: ResponseAction[]) => {
    updateReportQuery({
      type: QueryParametersEnum.ResponseActions,
      data: {
        ...reportQuery,
        responseActions,
      },
    });
  };

  return (
    <>
      <Col className="col-auto">
        <Form.Group>
          <ReactSelect
            className="basic-multi-select"
            isMulti
            isClearable
            placeholder={'Response Action'}
            options={Object.values(ResponseAction).map((response) => ({
              label: response,
              value: response,
            }))}
            onChange={(e) => handleOnChange(e.map((key) => key.value))}
            styles={{
              menu: (base) => ({
                ...base,
                width: 'max-content',
                minWidth: '100%',
                zIndex: 9999,
              }),
            }}
          ></ReactSelect>
        </Form.Group>
      </Col>
    </>
  );
};

const ResponderStatuses = <R extends DefaultReportParameters & { responderStatuses: ResponderStatusKey[] }>({
  reportQuery,
  updateReportQuery,
}: QueryProps<R>) => {
  const [responseAction, setResponseAction] = useState<ResponseAction | null>(null);

  const handleOnChange = (responderStatuses: ResponderStatusKey[]) => {
    updateReportQuery({
      type: QueryParametersEnum.ResponderStatuses,
      data: {
        ...reportQuery,
        responderStatuses,
      },
    });
    setResponseAction(null);
  };

  const handleResponseActionChange = (response: ResponseAction) => {
    if (responseAction === response) {
      setResponseAction(null);
      updateReportQuery({
        type: QueryParametersEnum.ResponderStatuses,
        data: {
          ...reportQuery,
          responderStatuses: [],
        },
      });
    } else {
      const statuses = ResponseActionToResponderStatusKey[response].statuses;
      statuses.map((x) => x as ResponderStatusKey);
      setResponseAction(response);
      updateReportQuery({
        type: QueryParametersEnum.ResponderStatuses,
        data: {
          ...reportQuery,
          responderStatuses: ResponseActionToResponderStatusKey[response].statuses,
        },
      });
    }
  };

  return (
    <>
      <Col className="col-auto">
        <Form.Group>
          <ReactSelect
            className="basic-multi-select"
            isMulti
            isClearable
            placeholder={'Response Action'}
            value={reportQuery.responderStatuses.map((responderStatus) => ({
              label: ResponderStatus[responderStatus],
              value: responderStatus,
            }))}
            options={(Object.keys(ResponderStatus) as ResponderStatusKey[]).map((responderStatus) => ({
              label: ResponderStatus[responderStatus],
              value: responderStatus,
            }))}
            onChange={(e) => handleOnChange(e.map((key) => key.value as ResponderStatusKey))}
            styles={{
              menu: (base) => ({
                ...base,
                width: 'max-content',
                minWidth: '100%',
                zIndex: 9999,
              }),
            }}
          ></ReactSelect>
        </Form.Group>
      </Col>
      <Col className="col-auto">
        <Form.Group>
          <ButtonGroup key="response-status">
            {(Object.keys(ResponseAction) as (keyof typeof ResponseAction)[]).map((key) => {
              return (
                <Button
                  onClick={() => handleResponseActionChange(ResponseAction[key])}
                  key={key}
                  active={responseAction === ResponseAction[key]}
                >
                  {ResponseAction[key]}
                </Button>
              );
            })}
          </ButtonGroup>
        </Form.Group>
      </Col>
    </>
  );
};

const maxResultsToEverGet = 1000;

const Personnel = <R extends DefaultReportParameters & { userIDs: string[] }>({
  reportQuery,
  updateReportQuery,
}: QueryProps<R>) => {
  const clientID = useAppSelector(selectClientId);
  const apiClient = useApiClient();
  const [users, setUsers] = useState<SignalOption<string>[]>([]);

  // Get all Client Users, count the Total and the Active:
  useEffect(() => {
    const fetchClientUsers = async () => {
      try {
        const clientUsersListResult = await apiClient.getClientUsersForClientID(
          {
            limit: maxResultsToEverGet,
            offset: 0,
          },
          clientID
        );

        setUsers(
          clientUsersListResult.map((x) => ({
            value: x.awareUserID,
            label: `${x.firstname} ${x.lastname}`.trim(),
          }))
        );
      } catch (error: any) {
        console.error('Failed to fetch clientUser data for clientUser count', JSON.stringify(error));
      }
    };

    fetchClientUsers();
  }, [clientID, apiClient, setUsers]);

  const handleOnChange = (userIDs: string[]) => {
    updateReportQuery({
      type: QueryParametersEnum.UserIDs,
      data: {
        ...reportQuery,
        userIDs,
      },
    });
  };

  return (
    <>
      <Col className="col-auto">
        <Form.Group>
          <SignalMultiSelect
            isClearable
            placeholder={'Personnel'}
            value={getSignalOptions(reportQuery.userIDs, users)}
            options={users}
            onChange={(x) => handleOnChange((x as SignalOption<string>[]).map((y) => y.value))}
          />
        </Form.Group>
      </Col>
    </>
  );
};

const Destinations = <R extends DefaultReportParameters & { destinationIDs: string[] }>({
  reportQuery,
  updateReportQuery,
}: QueryProps<R>) => {
  const clientID = useAppSelector(selectClientId);
  const apiClient = useApiClient();
  const [destinations, setDestinations] = useState<SignalOption<string>[]>([]);

  // Get all Destinations
  useEffect(() => {
    const fetchClientUsers = async () => {
      try {
        const clientUsersListResult = await apiClient.getDestinations();

        setDestinations(
          clientUsersListResult
            .sort((a, b) => a.agencyName.toLowerCase().localeCompare(b.agencyName.toLowerCase()))
            .map((x) => ({
              value: x.id,
              label: x.agencyName,
            }))
        );
      } catch (error: any) {
        console.error('Failed to fetch clientUser data for clientUser count', JSON.stringify(error));
      }
    };

    fetchClientUsers();
  }, [clientID, apiClient, setDestinations]);

  const handleOnChange = (destinationID: string | null) => {
    const destinationIDs = destinationID ? [destinationID] : [];
    updateReportQuery({
      type: QueryParametersEnum.DestinationIDs,
      data: {
        ...reportQuery,
        destinationIDs,
      },
    });
  };

  return (
    <>
      <Col className="col-auto">
        <Form.Group>
          <SignalSingleSelect
            isClearable
            placeholder={'Destination'}
            value={getSignalOptions(reportQuery.destinationIDs, destinations)}
            options={destinations}
            onChange={(x) => handleOnChange((x as SignalOption<string> | null)?.value ?? null)}
          />
        </Form.Group>
      </Col>
    </>
  );
};

const ReportQuery = {
  IncidentNumber,
  ResponseActions,
  ResponderStatuses,
  Personnel,
  Destinations,
};

export default ReportQuery;
export { QueryParametersEnum };
