// a date formatting helper to ensure leading digits are displayed
export const DisplayDateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

// data really likes its yyyy-MM-dd...
export const DataDateFormatter = new Intl.DateTimeFormat('fr-CA', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

export const DateTimeFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  timeZoneName: 'short',
  hour12: false,
});

// If we want to remove the comma:
// M/dd/Y H:mm:ss TT
export const CommalessDateTimeFormatter = {
  format: (d: Date) => {
    const x = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
    const y = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
    return x.format(d) + ' ' + y.format(d);
  },
};

// M/dd/Y, H:mm:ss TT
export const CommentDateTimeFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  second: '2-digit',
  hour12: true,
});

// a time formatting helper to ensure 2 digits are displayed for both time components
export const timeFormatter = new Intl.DateTimeFormat('en-US', {
  hour: '2-digit',
  minute: '2-digit',
});

const typeIncidentDateString = (notADate: Date) => {
  // when returning incident json from the API via fetch, without doing work to
  // manually construct an Incident, the dates are actually strings and we need to
  // retype it to get TypeScript to play nicely. That's what this method is for.
  return notADate as unknown as string;
};

// a helper function to create a correctly formatted incident date column from mismatch types described above
export const dateToDateString = (dispatchDateTime: Date): string => {
  if (!dispatchDateTime) return '';
  const incidentDateString = typeIncidentDateString(dispatchDateTime);
  const incidentDate = DisplayDateFormatter.format(new Date(incidentDateString));

  return incidentDate;
};

// a helper function to create a correctly formatted incident time column from mismatch types described above
export const dateToTimeString = (dispatchDateTime: Date): string => {
  if (!dispatchDateTime) return '';
  const incidentDateTimeString = typeIncidentDateString(dispatchDateTime);
  const incidentDateTime = timeFormatter.format(new Date(incidentDateTimeString));

  return incidentDateTime;
};

// helper function to convert a UTC Date to a Local Date
export const dateTimeUTCToLocal = (dateTimeString: Date): Date => {
  if (!dateTimeString) return dateTimeString;
  var newDate = new Date(dateTimeString);
  newDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60 * 1000);
  return newDate;
};

export const dateTimeUnknownUTCToLocal = (date: Date | string): Date => {
  if (typeof date === 'string') {
    if (date.endsWith('Z')) {
      return new Date(date);
    }
    return new Date(`${date}Z`);
  }
  return date;
};
