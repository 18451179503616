import Row from 'react-bootstrap/Row';
import { Units } from '../../models/Units';
import UnitCard from './unitCard';

interface UnitCardsProps {
  units: Units[];
  active: boolean;
}

export default function UnitCards({ units, active }: UnitCardsProps): JSX.Element {
  return (
    <>
      <p className="mt-3 text-large">{active ? 'Active' : 'Inactive'} Resources</p>
      {units.length > 0 &&
        units.map((data, index) => {
          return (
            <UnitCard
              unitName={data.unitName}
              index={index}
              arrivalDateTime={data.arrivalDateTime}
              enrouteDateTime={data.enrouteDateTime}
              dispatchDateTime={data.dispatchDateTime}
              destinationName={data.destinationName}
              clearDateTime={data.clearDateTime}
            />
          );
        })}
      {units.length === 0 && (
        <Row className="mx-4 mt-4 text-center">
          <p>There are no {active ? 'active' : 'inactive'} units.</p>
        </Row>
      )}
    </>
  );
}
