import api from '../../common/api';
import { postData } from '../../models/Helpers/FetchHelper';
import GoogleMapsConfiguration from '../../models/GooglsMapsConfiguration';

export class ConfigurationApiClient {
  async getGoogleMapsApiKey() {
    const googleConfigResponse = await postData(
      `${api.baseUrl}/api/configuration/google`,
      undefined,
      sessionStorage.accessToken
    );

    if (googleConfigResponse.ok) {
      const googleConfig = (await googleConfigResponse.json()) as GoogleMapsConfiguration;

      if (!googleConfig.apiKey) {
        throw new Error('Google Maps API key unconfigured');
      }

      return googleConfig.apiKey;
    }

    throw new Error('Google Maps API key unconfigured');
  }
}

const defaultConfigApiClient = new ConfigurationApiClient();

export default defaultConfigApiClient;
