import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import api from '../../../common/api';
import { useAppSelector } from '../../../common/appState/appStateHooks';
import { selectClientId } from '../../../common/appState/currentClientSlice';
import { ReducerAction } from '../../../common/reducerAction';
import { dateToDateString, dateToTimeString } from '../../../models/Helpers/DateHelper';
import { ResponderStatusKey } from '../../responderMode/types';
import Report from '../reportBase';
import { DefaultReportInitialState, DefaultReportParameters, DefaultReportReducer } from '../reportParameters';
import ReportQuery, { QueryParametersEnum } from '../reportQueries';

type ReportResponse = {
  fullName: string;
  awareUserID: string;
  incidentNumber: string;
  eventPriority: string;
  incidentAddress: string;
  incidentDateTime: Date;
  responseAction: string;
  status: string;
};

type ReportParameters = {
  responderStatuses: ResponderStatusKey[];
  incidentNumber: string;
  userIDs: string[];
} & DefaultReportParameters;

const ReportInitialState: ReportParameters = {
  ...DefaultReportInitialState,
  responderStatuses: [],
  incidentNumber: '',
  userIDs: [],
};

const ReportReducer: ImmerReducer<ReportParameters, ReducerAction<ReportParameters>> = (draft, action) => {
  switch (action.type) {
    case QueryParametersEnum.ResponderStatuses:
      draft.responderStatuses = action.data.responderStatuses;
      break;
    case QueryParametersEnum.IncidentNumber:
      draft.incidentNumber = action.data.incidentNumber;
      break;
    case QueryParametersEnum.UserIDs:
      draft.userIDs = action.data.userIDs;
      break;
    default:
      DefaultReportReducer(draft, action);
      break;
  }
};

const ResponderStatusByIncidentReport = (): JSX.Element => {
  const currentClientId = useAppSelector(selectClientId);
  const [reportQuery, updateReportQuery] = useImmerReducer(ReportReducer, ReportInitialState);

  const columnHelper = createColumnHelper<ReportResponse>();
  const resultColumns: ColumnDef<ReportResponse, any>[] = [
    columnHelper.accessor((row) => dateToDateString(row.incidentDateTime), {
      header: 'Incident Date',
      id: 'incidentDate',
    }),
    columnHelper.accessor((row) => dateToTimeString(row.incidentDateTime), {
      header: 'Incident Time',
      id: 'incidentTime',
    }),
    columnHelper.accessor('incidentNumber', { header: 'Incident Number' }),
    columnHelper.accessor('eventPriority', { header: 'Incident Type' }),
    columnHelper.accessor('fullName', { header: 'Personnel Name' }),
    columnHelper.accessor('incidentAddress', { header: 'Incident Address' }),
    columnHelper.accessor('responseAction', { header: 'Response Action' }),
    columnHelper.accessor('status', { header: 'Final Response Status' }),
  ];

  return (
    <>
      <Report.Base
        title={'Responder - Responder Status by Incident'}
        columns={resultColumns}
        fetchUrl={`${api.baseUrl}/api/clients/${currentClientId}/reports/responder/statusByIncident`}
        reportQuery={reportQuery}
        updateReportQuery={updateReportQuery}
      >
        <ReportQuery.Personnel reportQuery={reportQuery} updateReportQuery={updateReportQuery} />
        <ReportQuery.ResponderStatuses reportQuery={reportQuery} updateReportQuery={updateReportQuery} />
        <ReportQuery.IncidentNumber reportQuery={reportQuery} updateReportQuery={updateReportQuery} />
      </Report.Base>
    </>
  );
};

export default ResponderStatusByIncidentReport;
