import { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../common/appState/appStateHooks';
import { selectClientModules } from '../../common/appState/clientModulesSlice';
import routes from '../../common/routesDefinitions';
import modules from '../feature/modules';
import HeaderTitle from '../headerTitle/headerTitle';

export default function ReportsPage() {
  const [showCallTriageNav, setShowCallTriageNav] = useState(false);

  const clientModules = useAppSelector(selectClientModules);

  useEffect(() => {
    const callTriageIndex = clientModules.enabledModules.findIndex((cm) => cm.name === modules.callTriage);

    if (callTriageIndex > -1) {
      if (!showCallTriageNav) {
        setShowCallTriageNav(true);
      }
    } else {
      if (showCallTriageNav) {
        setShowCallTriageNav(false);
      }
    }
  }, [clientModules, showCallTriageNav]);

  return (
    <div>
      <HeaderTitle title={'Reporting'} />
      {!showCallTriageNav && (
        <Card>
          <Card.Header>Responder Standard Reports</Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item as={Link} to={routes.responderActionByPersonnelReport}>
              Total Count of Responses by Personnel
            </ListGroup.Item>
            <ListGroup.Item as={Link} to={routes.responderStatusByPersonnelReport}>
              Count of Response Statuses by Personnel
            </ListGroup.Item>
            <ListGroup.Item as={Link} to={routes.responderStatusByIncidentReport}>
              Responder Status by Incident
            </ListGroup.Item>
          </ListGroup>
        </Card>
      )}
      {showCallTriageNav && (
        <Card>
          <Card.Header>Call Triage Standard Reports</Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item as={Link} to={routes.callTriageTransportsByAgencyReport}>
              Total Count of Transports by Agency
            </ListGroup.Item>
            <ListGroup.Item as={Link} to={routes.callTriageTransportsByDestinationReport}>
              Total Count of Transports by Destination
            </ListGroup.Item>
            <ListGroup.Item as={Link} to={routes.callTriageDispositionsByUserReport}>
              Total Count of Dispositions by User
            </ListGroup.Item>
          </ListGroup>
        </Card>
      )}
    </div>
  );
}
