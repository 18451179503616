export interface SignalOption<T> {
  label: string;
  value: T;
}

export interface SignalOptionGroup<T> {
  label?: string;
  options: SignalOption<T>[];
}

export function getSignalOptionFromGroup<T>(id: T | null, arr: SignalOptionGroup<T>[]) {
  if (arr.length === 0) return null;
  return arr.flatMap((x) => x.options).find((x) => x.value === id) ?? null;
}

export function getSignalOption<T>(id: T, arr: SignalOption<T>[]) {
  if (arr.length === 0) return null;
  return arr.find((x) => x.value === id) ?? null;
}

export function getSignalOptions<T>(ids: T[], arr: SignalOption<T>[]) {
  if (arr.length === 0) return [];
  if (ids.length === 0) return [];
  return arr.filter((x) => ids.indexOf(x.value) !== -1);
}

export function createSignalOption(value: string | undefined | null): SignalOption<string> | null {
  return value
    ? {
        value: value,
        label: value,
      }
    : null;
}

export function createSignalOptions<T extends string>(arr: T[] | undefined | null): SignalOption<T>[] {
  return (
    arr?.map((value) => ({
      value: value,
      label: value,
    })) ?? []
  );
}
