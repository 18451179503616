import moment from 'moment';

// function as labels
export enum IncidentDateRangeType {
  Today = 'Today',
  Week = 'This Week',
  Month = 'This Month',
  Year = 'This Year',
  Past7Days = 'Past 7 Days',
  Yesterday = 'Yesterday',
  LastWeek = 'Last Week',
  LastMonth = 'Last Month',
  SpecificDate = 'Specific Date',
  Custom = 'Custom Date Range',
  Past2Hours = 'Past 2 Hours',
  Past6Hours = 'Past 6 Hours',
  Past12Hours = 'Past 12 Hours',
}

export const MultiDateRangeOptions = [
  IncidentDateRangeType.Today,
  IncidentDateRangeType.Week,
  IncidentDateRangeType.Month,
  IncidentDateRangeType.Year,
  IncidentDateRangeType.Past7Days,
  IncidentDateRangeType.Yesterday,
  IncidentDateRangeType.LastWeek,
  IncidentDateRangeType.LastMonth,
  IncidentDateRangeType.SpecificDate,
  IncidentDateRangeType.Custom,
];

export const SingleDateRangeOptions = [
  IncidentDateRangeType.Past2Hours,
  IncidentDateRangeType.Past6Hours,
  IncidentDateRangeType.Past12Hours,
  IncidentDateRangeType.Today,
  IncidentDateRangeType.Yesterday,
  IncidentDateRangeType.SpecificDate,
  IncidentDateRangeType.Custom,
];

export interface IncidentDateRangeValue {
  getDate(
    isUTC?: boolean,
    fromDate?: string | undefined,
    toDate?: string | undefined
  ): {
    dateFrom: string;
    dateTo: string;
  };
}

export const IncidentDateRange: {
  [key in IncidentDateRangeType]: IncidentDateRangeValue;
} = {
  [IncidentDateRangeType.Today]: {
    getDate(isUTC) {
      const toDate = moment(),
        fromDate = moment();
      toDate.endOf('day');
      fromDate.startOf('day');
      if (isUTC) {
        toDate.utc();
        fromDate.utc();
      }
      const dateFrom = fromDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      const dateTo = toDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      return {
        dateTo,
        dateFrom,
      };
    },
  },
  [IncidentDateRangeType.Week]: {
    getDate(isUTC) {
      const toDate = moment(),
        fromDate = moment();
      toDate.endOf('week');
      fromDate.startOf('week');
      if (isUTC) {
        toDate.utc();
        fromDate.utc();
      }
      const dateFrom = fromDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      const dateTo = toDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      return {
        dateTo,
        dateFrom,
      };
    },
  },
  [IncidentDateRangeType.Month]: {
    getDate(isUTC) {
      const toDate = moment(),
        fromDate = moment();
      toDate.endOf('month');
      fromDate.startOf('month');
      if (isUTC) {
        toDate.utc();
        fromDate.utc();
      }
      const dateFrom = fromDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      const dateTo = toDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      return {
        dateTo,
        dateFrom,
      };
    },
  },
  [IncidentDateRangeType.Year]: {
    getDate(isUTC) {
      const toDate = moment(),
        fromDate = moment();
      toDate.endOf('year');
      fromDate.startOf('year');
      if (isUTC) {
        toDate.utc();
        fromDate.utc();
      }
      const dateFrom = fromDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      const dateTo = toDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      return {
        dateTo,
        dateFrom,
      };
    },
  },
  [IncidentDateRangeType.Past7Days]: {
    getDate(isUTC) {
      const toDate = moment(),
        fromDate = moment();
      fromDate.subtract(6, 'day');
      toDate.endOf('day');
      fromDate.startOf('day');
      if (isUTC) {
        toDate.utc();
        fromDate.utc();
      }
      const dateFrom = fromDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      const dateTo = toDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      return {
        dateTo,
        dateFrom,
      };
    },
  },
  [IncidentDateRangeType.Yesterday]: {
    getDate(isUTC) {
      const toDate = moment(),
        fromDate = moment();
      toDate.subtract(1, 'day');
      fromDate.subtract(1, 'day');
      toDate.endOf('day');
      fromDate.startOf('day');
      if (isUTC) {
        toDate.utc();
        fromDate.utc();
      }
      const dateFrom = fromDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      const dateTo = toDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      return {
        dateTo,
        dateFrom,
      };
    },
  },
  [IncidentDateRangeType.LastWeek]: {
    getDate(isUTC) {
      const toDate = moment(),
        fromDate = moment();
      toDate.subtract(1, 'week');
      fromDate.subtract(1, 'week');
      toDate.endOf('week');
      fromDate.startOf('week');
      if (isUTC) {
        toDate.utc();
        fromDate.utc();
      }
      const dateFrom = fromDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      const dateTo = toDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      return {
        dateTo,
        dateFrom,
      };
    },
  },
  [IncidentDateRangeType.LastMonth]: {
    getDate(isUTC) {
      const toDate = moment(),
        fromDate = moment();
      toDate.subtract(1, 'month');
      fromDate.subtract(1, 'month');
      toDate.endOf('month');
      fromDate.startOf('month');
      if (isUTC) {
        toDate.utc();
        fromDate.utc();
      }
      const dateFrom = fromDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      const dateTo = toDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      return {
        dateTo,
        dateFrom,
      };
    },
  },
  [IncidentDateRangeType.SpecificDate]: {
    getDate(isUTC, fromDate) {
      const dateTo = moment(fromDate, ['MM/DD/YYYY', 'YYYY-MM-DD']),
        dateFrom = moment(fromDate, ['MM/DD/YYYY', 'YYYY-MM-DD']);
      dateTo.endOf('day');
      dateFrom.startOf('day');
      if (isUTC) {
        dateTo.utc();
        dateFrom.utc();
      }
      const fromDateString = dateFrom.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      const toDateString = dateTo.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      return {
        dateTo: toDateString,
        dateFrom: fromDateString,
      };
    },
  },
  [IncidentDateRangeType.Custom]: {
    getDate(isUTC, fromDate, toDate) {
      const dateTo = moment(toDate, ['MM/DD/YYYY', 'YYYY-MM-DD']),
        dateFrom = moment(fromDate, ['MM/DD/YYYY', 'YYYY-MM-DD']);
      dateTo.endOf('day');
      dateFrom.startOf('day');
      if (isUTC) {
        dateTo.utc();
        dateFrom.utc();
      }
      const fromDateString = dateFrom.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      const toDateString = dateTo.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      return {
        dateTo: toDateString,
        dateFrom: fromDateString,
      };
    },
  },
  [IncidentDateRangeType.Past2Hours]: {
    getDate(isUTC) {
      const date = isUTC === true ? moment().utc() : moment();
      const dateTo = date.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      const dateFrom = date.subtract(2, 'hour').format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      return {
        dateTo,
        dateFrom,
      };
    },
  },
  [IncidentDateRangeType.Past6Hours]: {
    getDate(isUTC) {
      const date = isUTC === true ? moment().utc() : moment();
      const dateTo = date.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      const dateFrom = date.subtract(6, 'hour').format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      return {
        dateTo,
        dateFrom,
      };
    },
  },
  [IncidentDateRangeType.Past12Hours]: {
    getDate(isUTC) {
      const date = isUTC === true ? moment().utc() : moment();
      const dateTo = date.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      const dateFrom = date.subtract(12, 'hour').format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      return {
        dateTo,
        dateFrom,
      };
    },
  },
};
