export interface Bed {
  id: string;
  name: string;
  description: string;
  total_beds: number;
  available_staffed: number;
  licensed: number;
  licensed_occupied: number;
  contact: string;
  contact_id: string;
  havbed_type: string;
  updated_on: string;
}

export interface MCI_Capability {
  level: string;
  current: number;
  capacity: number;
  updated_on: string;
  css: string;
}

export interface Regional_Category {
  type: string;
  category: string;
  changed_on: string;
}

export interface Agency_Category {
  name: string;
}

export interface Service_Category {
  name: string;
}

export interface Specialty {
  id: string;
  option_id: string;
  name: string;
  changed_on: string;
  availability_begins: string;
  availability_ends: string;
  comment: string;
  availability_status: string;
  color: string;
}

export interface Diversion {
  id: string;
  name: string;
  status: string;
  color: string;
  updated_on_utc: string | null;
  updated_on_local: string;
  updated_by: string;
  changed_for_hours: string;
  changed_for_minutes: string;
  status_reason_id: string;
  status_reason: string;
  status_comments: string;
}

export interface Nedoc {
  id: string;
  nedocs_score: number;
  description: string;
  created_on_clientTime: string | null;
  color: string;
}

export enum TraumaLevelID {
  NotAvailable = 'e50cac13-1077-4852-af8e-03926d6f136b',
  Level1 = '1c3057bf-9d51-4757-832b-1d7f87be8eda',
  Level2 = '365d1cf3-af3f-4d17-97fe-616ba87f8677',
  Level3 = 'f87e3c16-ae0c-4d04-be4f-44188973c43f',
  Level4 = '4b1ffd5a-0351-4938-a655-cba695bc4a67',
}

export enum StrokeLevelID {
  // Not Applicable
  NotApplicable = '5faec693-dbe2-46ee-a164-e022b64c8a67',
  // Comprehensive
  Level1 = '6c43975c-e166-4e4b-9e99-d220cdb27003',
  // Primary
  Level2 = 'adfd3d0f-f858-480f-a0ff-712d22363c74',
  // Thrombectomy-Capable
  Level3 = '52996fab-c941-49c4-b784-e4bd28f78653',
}

export enum STEMILevelID {
  Level1 = '30ccb274-0a5b-4965-98bf-0402aa371078',
  // LevelX = "75c3e879-2fa6-42c9-b603-073a89a893cb",
  // LevelY = "ee7782e4-1aa0-4dce-9a73-590d58b0b730",
}

export interface Office {
  id: string;
  pa_id: string;
  agencyName: string;
  type: string;
  typeID: string;
  agency_id: string;
  destCode: string;
  traumaLevelID: TraumaLevelID;
  traumaLevel: string;
  pA_StrokeLevelID: StrokeLevelID;
  pA_StrokeLevel: string;
  pA_STEMILevelID: STEMILevelID;
  pA_STEMILevel: string;
  city: string;
  state: string;
  postalCode: string;
  county?: string;
  address?: string;
  address2: string;
  longitude: number;
  latitude: number;
  eocStatus: string;
  region: string;
  regionId: string;
  beds: Bed[];
  mci_capabilities: MCI_Capability[];
  regional_categories: Regional_Category[];
  agency_categories: Agency_Category[];
  service_categories: Service_Category[];
  specialties: Specialty[];
  diversion: Partial<Diversion>;
  nedoc: Partial<Nedoc>;
  diversionStatus?: string;
  available_staffed?: number;
  last_modified_on?: string;
  last_updated_by: string;
}
