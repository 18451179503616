export enum ResponderStatusKey {
  ArrivedAtStation = 'ArrivedAtStation',
  ArrivedOnScene = 'ArrivedOnScene',
  RespondingToStation = 'RespondingToStation',
  RespondingToScene = 'RespondingToScene',
  Canceling = 'Canceling',
  Clear = 'Clear',
  Unavailable = 'Unavailable',
}
// function as labels
export const ResponderStatus = {
  [ResponderStatusKey.ArrivedAtStation]: 'Arrived At Station',
  [ResponderStatusKey.ArrivedOnScene]: 'Arrived On Scene',
  [ResponderStatusKey.RespondingToStation]: 'Responding To Station',
  [ResponderStatusKey.RespondingToScene]: 'Responding To Scene',
  [ResponderStatusKey.Canceling]: 'Canceling',
  [ResponderStatusKey.Clear]: 'Clear',
  [ResponderStatusKey.Unavailable]: 'Unavailable',
};
