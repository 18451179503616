import { ImmerReducer } from 'use-immer';
import { ReducerAction } from '../../common/reducerAction';
import { IncidentDateRange, IncidentDateRangeType } from '../incidentSearch/IncidentDateRange';
import { DefaultPagination, OffsetLimit, PaginationReducer } from '../pagination/types';

export type DefaultReportParameters = {
  dateFrom: string;
  dateTo: string;
  dateOption: IncidentDateRangeType;
} & OffsetLimit;

const defaultDateOption = IncidentDateRangeType.Today;

export const DefaultReportInitialState: DefaultReportParameters = {
  dateOption: defaultDateOption,
  dateFrom: IncidentDateRange[defaultDateOption].getDate().dateFrom,
  dateTo: IncidentDateRange[defaultDateOption].getDate().dateTo,
  ...DefaultPagination,
};

export const DefaultUtcReportInitialState: DefaultReportParameters = {
  dateOption: defaultDateOption,
  dateFrom: IncidentDateRange[defaultDateOption].getDate(true).dateFrom,
  dateTo: IncidentDateRange[defaultDateOption].getDate(true).dateTo,
  ...DefaultPagination,
};

export const DefaultReportParameterActions = {
  toDate: 'toDate',
  fromDate: 'fromDate',
  incidentDateOption: 'incidentDateOption',
};

export const DefaultReportReducer: ImmerReducer<DefaultReportParameters, ReducerAction<DefaultReportParameters>> = (
  draft,
  action
) => {
  switch (action.type) {
    case DefaultReportParameterActions.fromDate:
      draft.dateFrom = action.data.dateFrom;
      break;
    case DefaultReportParameterActions.toDate:
      draft.dateTo = action.data.dateTo;
      break;
    case DefaultReportParameterActions.incidentDateOption:
      draft.dateOption = action.data.dateOption;
      const { dateFrom, dateTo } = IncidentDateRange[action.data.dateOption].getDate(
        false,
        action.data.dateFrom,
        action.data.dateTo
      );
      draft.dateTo = dateTo;
      draft.dateFrom = dateFrom;
      break;
    default:
      PaginationReducer(draft, action);
      break;
  }
};
