import { useCallback, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import api from '../../common/api';
import { useAppDispatch } from '../../common/appState/appStateHooks';
import { updateAvailableClients } from '../../common/appState/availableClientsSlice';
import { updateClientId, updateClientName } from '../../common/appState/currentClientSlice';
import routes from '../../common/routesDefinitions';
import { ClientUser } from '../../models/ClientUser';
import { ClientUserRequest } from '../../models/ClientUserRequest';
import { getData } from '../../models/Helpers/FetchHelper';
import { ClientModule } from '../../models/Module';
import { ApiResponseError } from '../../services/api/ApiResponseError';
import modules from '../feature/modules';
import { useApiClient } from '../useApiClient/useApiClient';
export default function NoClients() {
  const apiClient = useApiClient();
  const [clientUsers, setClientUsers] = useState<ClientUser[]>([]);
  const [invitationForm, setInvitationForm] = useState<ClientUserRequest>({
    clientCode: '',
    invitationCode: '',
  });

  const [activateErrorMessages, setActivateErrorMessages] = useState<string[]>([]);
  const [requestErrorMessages, setRequestErrorMessages] = useState<string[]>([]);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const updateClientStore = () => {
    //update our current client information in the global (Redux) app state store
    const clientAlias = JSON.parse(sessionStorage.clientAlias);

    dispatch(updateClientId(clientAlias.clientID));
    dispatch(updateClientName(clientAlias.clientName));

    const availableClientsRaw = JSON.parse(sessionStorage.clientList);

    const availableClients = availableClientsRaw.map((availableClient: { clientID: string; clientName: string }) => ({
      clientID: availableClient.clientID,
      clientName: availableClient.clientName,
    }));

    // update the available clients in the global (Redux) app state store
    dispatch(updateAvailableClients(availableClients));
  };

  // Get Client Users for User
  const getClientUsersForUser = useCallback(async () => {
    try {
      const clientUsersListResult = await apiClient.getClientUsersForUser();
      setClientUsers(clientUsersListResult);

      // Return this so when activated we can use the data to move the user to the dashboard right away
      return clientUsersListResult;
    } catch (error: any) {
      console.error('Failed to fetch clientUser data for user', JSON.stringify(error));
      throw new Error(error);
    }
  }, [apiClient]);
  useEffect(() => {
    getClientUsersForUser();
  }, [getClientUsersForUser]);

  const requestClientUserAccess = async (invitation: ClientUserRequest) => {
    try {
      if (invitation.clientCode.length > 5) {
        const clientUserRequest = await apiClient.postRequestClientUserAccess(invitation);
        if (clientUserRequest != null) {
          await getClientUsersForUser();
        }
      }
    } catch (error: any) {
      if (error instanceof ApiResponseError) {
        console.error(error);
        setRequestErrorMessages([error.message]);
      } else {
        console.error('Unable to request access to Signal Agency. ', JSON.stringify(error));
      }
    }
  };

  const activateInvitation = async (invitation: ClientUserRequest) => {
    try {
      if (invitation.clientCode.length > 5 && invitation.invitationCode.length === 10) {
        const clientUserRequest = await apiClient.activateClientUserInvitation(invitation);
        if (clientUserRequest != null) {
          await getClientUsersForUser().then(async (clients) => {
            // Update SessionState with new Client
            var approvedClients = clients.filter((u) => u.clientUserStateID === 2);
            sessionStorage.setItem('clientList', JSON.stringify(approvedClients));

            const clientAlias = {
              clientID: approvedClients[0].clientID!,
              clientName: approvedClients[0].clientName!,
            };

            sessionStorage.setItem('clientID', approvedClients[0].clientID!);
            sessionStorage.setItem('clientAlias', JSON.stringify(clientAlias));

            // Update the global Redux app state store also
            updateClientStore();

            // Check modules to see where to navigate this user:
            const clientModules = (await (
              await getData(
                `${api.baseUrl}/api/clients/${approvedClients[0].clientID!}/modules/active`,
                sessionStorage.accessToken
              )
            ).json()) as ClientModule[];

            const callTriageIndex = clientModules.findIndex((cm) => cm.name === modules.callTriage);

            if (callTriageIndex > -1) {
              navigate(routes.callTriageIncident);
              return;
            }
            // Send them to the dashboard again
            navigate(routes.dashboard);
            return;
          });
        }
      }
    } catch (error: any) {
      if (error instanceof ApiResponseError) {
        console.error(error);
        setActivateErrorMessages([error.message]);
      } else {
        console.error('Unable to activate invitation to Signal Agency. ', JSON.stringify(error));
      }
    }
  };

  const activateButtonEnabled = () => {
    return invitationForm.clientCode.length > 5 && invitationForm.invitationCode.length === 10;
  };

  const requestButtonEnabled = () => {
    return invitationForm.clientCode.length > 5;
  };

  const handleClientCodeChange = (code: any) => {
    if (code?.length > 12) {
      return;
    }
    setInvitationForm({ ...invitationForm, clientCode: code });
  };
  const handleInvitationCodeChange = (code: any) => {
    if (code?.length > 10) {
      return;
    }
    setInvitationForm({ ...invitationForm, invitationCode: code });
  };

  return (
    <>
      <Container>
        <Row className="mt-3">
          <Col>
            <Container>
              <Row>
                <Col className="col-9">
                  <Container className="border border-dark bg-light offset-1 gap-3">
                    <Row>
                      <Col className="offset-1 text-center p-2">
                        <h3>Connect to a Signal Account</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          Associate your ImageTrend Aware account to a Signal Agency. There's two ways you can proceed:
                        </p>
                        <h5>Activate an invitation:</h5>
                        <p>
                          You've already received an invitation to a Signal Agency with a Client Code and Invitation
                          Code. You can activate your invitation from the link in the email you received, or activate
                          your invitation here.
                        </p>
                        <Container>
                          <Row>
                            <Col className="">
                              <div className="col-6 center">
                                <Form.Group>
                                  <Form.Label>Client Code:</Form.Label>
                                  <Form.Control
                                    value={invitationForm.clientCode}
                                    onChange={(x) => handleClientCodeChange(x.target.value)}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="">
                              <div className="col-6 center">
                                <Form.Group>
                                  <Form.Label>Invitation Code:</Form.Label>
                                  <Form.Control
                                    value={invitationForm.invitationCode}
                                    onChange={(x) => handleInvitationCodeChange(x.target.value)}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col className="">
                              <div className="col-3 center">
                                <div className="d-grid gap-2">
                                  <Button
                                    variant="outline-primary btn-block"
                                    disabled={!activateButtonEnabled()}
                                    onClick={() => activateInvitation(invitationForm)}
                                  >
                                    Activate
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {activateErrorMessages.length > 0 && (
                            <>
                              <Row className="mx-1 p-2">
                                <Col className="col-auto mx-auto">
                                  <Alert
                                    dismissible
                                    onClose={() => setActivateErrorMessages([])}
                                    variant="danger"
                                    show={activateErrorMessages.length > 0}
                                  >
                                    <ul>
                                      {activateErrorMessages.map((errorMessage) => (
                                        <li key={errorMessage}>{errorMessage}</li>
                                      ))}
                                    </ul>
                                  </Alert>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Container>
                        <h5>Request Access:</h5>
                        <p>
                          You know the Client Code for the Signal Agency you'd like access to. Request access to the
                          Signal Agency here.
                        </p>
                        <Container>
                          <Row>
                            <Col className="">
                              <div className="col-6 center">
                                <Form.Group>
                                  <Form.Label>Client Code:</Form.Label>
                                  <Form.Control
                                    value={invitationForm.clientCode}
                                    onChange={(x) => handleClientCodeChange(x.target.value)}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col className="">
                              <div className="col-3 center">
                                <div className="d-grid gap-2">
                                  <Button
                                    variant="outline-primary btn-block"
                                    disabled={!requestButtonEnabled()}
                                    onClick={() => requestClientUserAccess(invitationForm)}
                                  >
                                    Request Access
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {requestErrorMessages.length > 0 && (
                            <>
                              <Row className="mx-1 p-2">
                                <Col className="col-auto mx-auto">
                                  <Alert
                                    dismissible
                                    onClose={() => setRequestErrorMessages([])}
                                    variant="danger"
                                    show={requestErrorMessages.length > 0}
                                  >
                                    <ul>
                                      {requestErrorMessages.map((errorMessage) => (
                                        <li key={errorMessage}>{errorMessage}</li>
                                      ))}
                                    </ul>
                                  </Alert>
                                </Col>
                              </Row>
                            </>
                          )}
                          {clientUsers?.length > 0 && (
                            <Row className="mt-3">
                              <Col className="">
                                <div className="col-9 center">
                                  <div className="d-grid gap-2">
                                    Current Requests:
                                    <Table hover className="list-table table table-striped" aria-label="Active Users">
                                      <thead>
                                        <tr>
                                          <th>Signal Agency</th>
                                          <th>Status of Request</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {clientUsers.map((cu) => {
                                          return (
                                            <tr className="list-table-row" key={cu.clientUserID}>
                                              <td className="list-table-col list-table-col-text">{cu.clientName}</td>
                                              <td className="list-table-col list-table-col-text">
                                                {cu.clientUserState === 'Approved' ? (
                                                  <span>Active</span>
                                                ) : cu.clientUserState === 'Requested' ? (
                                                  <span>{cu.clientUserState}</span>
                                                ) : cu.clientUserState === 'Denied' ? (
                                                  <span>{cu.clientUserState}</span>
                                                ) : cu.clientUserState === 'Disabled' ? (
                                                  <span>Inactive</span>
                                                ) : (
                                                  <span></span>
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col>&nbsp;</Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                {/* <Col className="col-6">
                  <Container>
                    <Row>
                      <Col className="col-12">
                        <Container className="offset-1 gap-3">
                          <Row className="mb-5">
                            <Col className="col-6">{dashboard}</Col>
                            <Col className="col-6">
                              <ul>
                                <li>Live Time Incident & Unit Status tracking.</li>
                                <li>Incident notifications to your mobile device within seconds.</li>
                                <li>Respond to an Incident from your mobile device.</li>
                                <li>Call Triage module allows for Incident data capture, patient transport selection based on real-time hospital data from Resource Bridge.</li>
                              </ul>
                            </Col>
                          </Row>
                          <Row className="mt-5 pt-5">
                            <Col>
                              <Container className="border border-dark bg-light">
                                <Row className="pt-3">
                                  <Col className="text-center">
                                    <h4>New Signal Agency?</h4>
                                  </Col>
                                </Row>

                                <Row className="mt-3 pb-5">
                                  <Col className="">
                                    <div className="col-5 center">
                                      <div className="d-grid gap-2">
                                        <Button disabled variant="outline-primary btn-block">
                                          Create a New Signal Agency
                                        </Button>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </Col> */}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}
