import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownAZ, faArrowUpAZ } from '@imagetrend/fontawesome-pro/pro-solid-svg-icons';
import { FormikErrors } from 'formik';
import { Reducer, useEffect, useMemo, useReducer } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Feedback from 'react-bootstrap/Feedback';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import { useImmerReducer } from 'use-immer';
import { useAppDispatch, useAppSelector } from '../../common/appState/appStateHooks';
import {
  selectDestinationSortOrder,
  selectDestinationSortType,
  selectIncidentDestinationSortOrder,
  selectIncidentDestinationSortType,
  updateDestinationSortOrder,
  updateDestinationSortType,
  updateIncidentDestinationSortOrder,
  updateIncidentDestinationSortType,
} from '../../common/appState/callTriageSettings';
import { ReducerAction } from '../../common/reducerAction';
import {
  SignalOption,
  SignalOptionGroup,
  createSignalOption,
  createSignalOptions,
  getSignalOption,
  getSignalOptionFromGroup,
} from '../../common/types';
import { Office, STEMILevelID, StrokeLevelID, TraumaLevelID } from '../../models/ResourceBridge/Office';
import SignalLoading from '../common/signalLoading';
import { SignalSingleSelect } from '../common/signalSelect';
import SignalPagination from '../pagination/pagination';
import { OffsetLimit, PaginationQuery, PaginationReducer } from '../pagination/types';
import { useApiClient } from '../useApiClient/useApiClient';
import DestinationCard from './destinationCard';
import './destinationCollection.scss';
import { haversine_distance } from './destinations/functions';
import { DestinationSortType, SortOrder } from './destinations/types';
import { CallTriageIncident } from './types';

enum LoadingState {
  Loading,
  Success,
  Error,
}

type DestinationQueryState = {
  loadingState: LoadingState;
  destinations: Office[];
  searchFilter?: string;
  countyOptions?: string[];
  regionOptions?: string[];
  diversionOptions?: string[];
  typeOptions?: string[];
  socOptions: SignalOptionGroup<string>[];
  sortOptions: string[];
  county?: string;
  region?: string;
  diversion?: string;
  type?: string;
  soc?: string;
};

const initialState: DestinationQueryState = {
  loadingState: LoadingState.Loading,
  destinations: [],
  socOptions: [],
  sortOptions: [],
};

enum QueryActions {
  setLoadingState = 'setLoadingState',
  loadDestinations = 'loadDestinations',
  setSearch = 'setSearch',
  setOptions = 'setOptions',
  setCounty = 'setCounty',
  setRegion = 'setRegion',
  setDiversion = 'setDiversion',
  setType = 'setType',
  resetFilters = 'resetFilters',
  setSOC = 'setSOC',
}

const DestinationQuery: Reducer<DestinationQueryState, ReducerAction<DestinationQueryState>> = (state, action) => {
  switch (action.type) {
    case QueryActions.setLoadingState:
      return {
        ...state,
        loadingState: action.data.loadingState,
      };
    case QueryActions.loadDestinations:
      return {
        ...state,
        loadingState: LoadingState.Success,
        destinations: action.data.destinations,
      };
    case QueryActions.setOptions:
      return {
        ...state,
        countyOptions: action.data.countyOptions,
        regionOptions: action.data.regionOptions,
        diversionOptions: action.data.diversionOptions,
        typeOptions: action.data.typeOptions,
        socOptions: action.data.socOptions,
      };
    case QueryActions.setCounty:
      return {
        ...state,
        county: action.data.county,
      };
    case QueryActions.setRegion:
      return {
        ...state,
        region: action.data.region,
      };
    case QueryActions.setDiversion:
      return {
        ...state,
        diversion: action.data.diversion,
      };
    case QueryActions.setType:
      return {
        ...state,
        type: action.data.type,
      };
    case QueryActions.setSOC:
      return {
        ...state,
        soc: action.data.soc,
      };
    case QueryActions.setSearch:
      return {
        ...state,
        searchFilter: action.data.searchFilter,
      };
    case QueryActions.resetFilters:
      return {
        ...state,
        county: undefined,
        region: undefined,
        diversion: undefined,
        type: undefined,
        soc: undefined,
      };
  }
  return state;
};

interface DestinationCollectionProps {
  small?: boolean;
  incident?: CallTriageIncident;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  errors?: FormikErrors<CallTriageIncident>;
  selectedDestination?: Office;
  setSelectedDestination?: React.Dispatch<React.SetStateAction<Office | undefined>>;
  destinationsPerPage?: number;
  isEditable?: boolean;
  onSelect?: (d: Office) => void;
  destinations?: Office[];
}

const DestinationCollection = ({
  incident,
  setFieldValue,
  small,
  destinationsPerPage,
  isEditable,
  onSelect = () => {},
  ...props
}: DestinationCollectionProps) => {
  const defaultPagination: OffsetLimit = useMemo(
    () => ({
      offset: 0,
      limit: destinationsPerPage ?? 10,
    }),
    [destinationsPerPage]
  );

  const dispatch = useAppDispatch();
  const selectSortOrder = incident ? selectIncidentDestinationSortOrder : selectDestinationSortOrder;
  const selectSortType = incident ? selectIncidentDestinationSortType : selectDestinationSortType;
  const sortType = useAppSelector(selectSortType);
  const sortOrder = useAppSelector(selectSortOrder);
  const updateSortOrder = incident ? updateIncidentDestinationSortOrder : updateDestinationSortOrder;
  const updateSortType = incident ? updateIncidentDestinationSortType : updateDestinationSortType;
  const [destinationQuery, dispatchDestination] = useReducer(DestinationQuery, initialState);
  const [pagination, dispatchPagination] = useImmerReducer(PaginationReducer, defaultPagination);

  const apiClient = useApiClient();

  const getSortOptions = useMemo(() => {
    let options = Object.values(DestinationSortType) as Array<DestinationSortType>;
    if (!incident) {
      options = options.filter((x) => x !== DestinationSortType.Distance);
    }
    return createSignalOptions(options);
  }, [incident]);

  useEffect(() => {
    const getDestinations = async () => {
      try {
        const destinations = props.destinations ?? (await apiClient.getDestinations());
        const counties = destinations
          .map((x) => x.county ?? '')
          .sort()
          .filter((value, index, array) => !!value && array.indexOf(value) === index);
        const diversions = destinations
          .map((x) => x.diversion.name ?? '')
          .sort()
          .filter((value, index, array) => !!value && array.indexOf(value) === index);
        const types = destinations
          .flatMap((x) => x.specialties.map((y) => y.name))
          .filter((value, index, array) => !!value && array.indexOf(value) === index);
        const regions = destinations
          .map((x) => x.region)
          .sort()
          .filter((value, index, array) => array.indexOf(value) === index);
        const socGroup: SignalOptionGroup<string>[] = [];
        const cardiac = destinations.find((x) => x.pA_STEMILevelID === STEMILevelID.Level1);
        if (cardiac) {
          socGroup.push({
            label: 'Cardiac',
            options: [
              {
                label: cardiac.pA_STEMILevel,
                value: cardiac.pA_STEMILevelID,
              },
            ],
          });
        }
        const strokeOptions: SignalOption<string>[] = [];
        const stroke1 = destinations.find((x) => x.pA_StrokeLevelID === StrokeLevelID.Level1);
        if (stroke1) {
          strokeOptions.push({
            label: stroke1.pA_StrokeLevel,
            value: stroke1.pA_StrokeLevelID,
          });
        }
        const stroke2 = destinations.find((x) => x.pA_StrokeLevelID === StrokeLevelID.Level2);
        if (stroke2) {
          strokeOptions.push({
            label: stroke2.pA_StrokeLevel,
            value: stroke2.pA_StrokeLevelID,
          });
        }
        strokeOptions.push({
          label: 'All Stroke Centers',
          value: `${StrokeLevelID.Level1}|${StrokeLevelID.Level2}`,
        });
        socGroup.push({
          label: 'Stroke',
          options: strokeOptions,
        });
        const trauma1 = destinations.find((x) => x.traumaLevelID === TraumaLevelID.Level1) ?? {
          traumaLevel: 'Level 1',
          traumaLevelID: TraumaLevelID.Level1,
        };
        const trauma2 = destinations.find((x) => x.traumaLevelID === TraumaLevelID.Level2) ?? {
          traumaLevel: 'Level 2',
          traumaLevelID: TraumaLevelID.Level2,
        };
        const trauma3 = destinations.find((x) => x.traumaLevelID === TraumaLevelID.Level3) ?? {
          traumaLevel: 'Level 3',
          traumaLevelID: TraumaLevelID.Level3,
        };
        const trauma4 = destinations.find((x) => x.traumaLevelID === TraumaLevelID.Level4) ?? {
          traumaLevel: 'Level 4',
          traumaLevelID: TraumaLevelID.Level4,
        };
        socGroup.push({
          label: 'Trauma',
          options: [
            {
              label: `${trauma1.traumaLevel}/${trauma2.traumaLevel}`,
              value: `${trauma1.traumaLevelID}|${trauma2.traumaLevelID}`,
            },
            {
              label: `${trauma3.traumaLevel}/${trauma4.traumaLevel}`,
              value: `${trauma3.traumaLevelID}|${trauma4.traumaLevelID}`,
            },
          ],
        });
        dispatchDestination({
          type: QueryActions.setOptions,
          data: {
            ...initialState,
            countyOptions: counties,
            diversionOptions: diversions,
            regionOptions: regions,
            typeOptions: types,
            socOptions: socGroup,
          },
        });
        dispatchDestination({
          type: QueryActions.loadDestinations,
          data: {
            ...initialState,
            loadingState: LoadingState.Success,
            destinations: destinations,
          },
        });
      } catch (err) {
        console.error('Failed to fetch destination data', err);
      }
    };

    getDestinations();
  }, [apiClient, props.destinations]);

  const sortedDestinations = useMemo(() => {
    const destinations = [...destinationQuery.destinations];

    switch (sortType) {
      case DestinationSortType.Distance:
        if (typeof incident?.latitude === 'number' && typeof incident?.longitude === 'number') {
          destinations.sort((a, b) =>
            haversine_distance(a, { latitude: incident.latitude!, longitude: incident.longitude! }) >
            haversine_distance(b, { latitude: incident.latitude!, longitude: incident.longitude! })
              ? 1
              : -1
          );
        }
        break;
      default:
      case DestinationSortType.Name:
        // Since the array is already sorted, we just apply sort ordering here.
        if (sortOrder === SortOrder.Descending) {
          destinations.reverse();
        }
        break;
      case DestinationSortType.Diversion:
        destinations.sort(
          (a, b) =>
            (a.diversion.updated_on_utc ?? '').localeCompare(b.diversion.updated_on_utc ?? '') *
            (sortOrder === SortOrder.Descending ? -1 : 1)
        );
        break;
      case DestinationSortType.NEDOCS:
        destinations.sort(
          (a, b) =>
            (a.nedoc.created_on_clientTime ?? '').localeCompare(b.nedoc.created_on_clientTime ?? '') *
            (sortOrder === SortOrder.Descending ? -1 : 1)
        );
        break;
    }

    return destinations;
  }, [destinationQuery.destinations, sortType, sortOrder, incident?.latitude, incident?.longitude]);

  const filteredDestinations = useMemo(() => {
    const displayed = sortedDestinations.filter((value) => {
      if (destinationQuery.county && value.county !== destinationQuery.county) {
        return false;
      }
      if (destinationQuery.region && value.region !== destinationQuery.region) {
        return false;
      }
      if (destinationQuery.diversion && value.diversion?.name !== destinationQuery.diversion) {
        return false;
      }
      if (destinationQuery.type && value.specialties.every((x) => x.name !== destinationQuery.type)) {
        return false;
      }
      if (
        destinationQuery.soc &&
        !value.pA_STEMILevelID.match(destinationQuery.soc) &&
        !value.pA_StrokeLevelID.match(destinationQuery.soc) &&
        !value.traumaLevelID.match(destinationQuery.soc)
      ) {
        return false;
      }
      if (
        destinationQuery.searchFilter &&
        !value.agencyName.toLocaleLowerCase().includes(destinationQuery.searchFilter.toLocaleLowerCase())
      ) {
        return false;
      }
      return true;
    });
    return displayed;
  }, [
    sortedDestinations,
    destinationQuery.county,
    destinationQuery.region,
    destinationQuery.diversion,
    destinationQuery.type,
    destinationQuery.soc,
    destinationQuery.searchFilter,
  ]);

  useEffect(() => {
    dispatchPagination({
      type: PaginationQuery.offset,
      data: {
        ...defaultPagination,
        offset: 0,
      },
    });
  }, [filteredDestinations, dispatchPagination, defaultPagination]);

  const displayedDestinations = useMemo(() => {
    if (filteredDestinations.length === 0) {
      return [];
    }

    const filtered = [...filteredDestinations];

    return filtered.slice(pagination.offset, pagination.offset + pagination.limit);
  }, [filteredDestinations, pagination.offset, pagination.limit]);

  const handleSOCChange = (e?: SignalOption<string>) => {
    dispatchDestination({
      type: QueryActions.setSOC,
      data: {
        ...initialState,
        soc: e?.value,
      },
    });
  };

  const handleSortChange = (e: SignalOption<DestinationSortType>) => {
    // This shouldn't matter too much, but I like it existing.
    let newSortOrder = SortOrder.Ascending;
    if (e.value === DestinationSortType.Diversion || e.value === DestinationSortType.NEDOCS) {
      newSortOrder = SortOrder.Descending;
    }
    dispatch(updateSortOrder(newSortOrder));
    dispatch(updateSortType(e.value));
  };

  const toggleSortOrder = () => {
    dispatch(updateSortOrder(sortOrder === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending));
  };

  const resetFilters = () => {
    dispatchDestination({
      type: QueryActions.resetFilters,
      data: {
        ...initialState,
      },
    });
  };

  return (
    <>
      <Row className="m-1">
        <Col className="col-auto my-1">
          <Form.Group>
            <Form.Label visuallyHidden htmlFor="destination-search">
              Search
            </Form.Label>
            <Form.Control
              id="destination-search"
              placeholder="Search"
              title="Search a destination"
              value={destinationQuery.searchFilter}
              onChange={(e) =>
                dispatchDestination({
                  type: QueryActions.setSearch,
                  data: {
                    ...initialState,
                    searchFilter: e.target.value,
                  },
                })
              }
            />
          </Form.Group>
        </Col>
        {!small && (
          <Col className="col-auto my-1">
            <Form.Group>
              <Form.Label visuallyHidden htmlFor="destination-county">
                County
              </Form.Label>
              <SignalSingleSelect
                placeholder="County"
                inputId="destination-county"
                isClearable
                options={createSignalOptions(destinationQuery.countyOptions)}
                value={createSignalOption(destinationQuery.county)}
                onChange={(e) =>
                  dispatchDestination({
                    type: QueryActions.setCounty,
                    data: {
                      ...initialState,
                      county: (e as { value: string })?.value,
                    },
                  })
                }
              />
            </Form.Group>
          </Col>
        )}
        <Col className="col-auto my-1">
          <Form.Group>
            <Form.Label visuallyHidden htmlFor="destination-region">
              Region
            </Form.Label>
            <SignalSingleSelect
              placeholder="Region"
              inputId="destination-region"
              isClearable
              options={createSignalOptions(destinationQuery.regionOptions)}
              value={createSignalOption(destinationQuery.region)}
              onChange={(e) =>
                dispatchDestination({
                  type: QueryActions.setRegion,
                  data: {
                    ...initialState,
                    region: (e as { value: string })?.value,
                  },
                })
              }
            />
          </Form.Group>
        </Col>
        <Col className="col-auto my-1">
          <Form.Group>
            <Form.Label visuallyHidden htmlFor="destination-diversion">
              Diversion
            </Form.Label>
            <SignalSingleSelect
              placeholder="Diversion"
              inputId="destination-diversion"
              isClearable
              options={createSignalOptions(destinationQuery.diversionOptions)}
              value={createSignalOption(destinationQuery.diversion)}
              onChange={(e) =>
                dispatchDestination({
                  type: QueryActions.setDiversion,
                  data: {
                    ...initialState,
                    diversion: (e as { value: string })?.value,
                  },
                })
              }
            />
          </Form.Group>
        </Col>
        <Col className="col-auto my-1">
          <Form.Group>
            <Form.Label visuallyHidden htmlFor="destination-type">
              Type
            </Form.Label>
            <SignalSingleSelect
              placeholder="Type"
              inputId="destination-type"
              isClearable
              options={createSignalOptions(destinationQuery.typeOptions)}
              value={createSignalOption(destinationQuery.type)}
              onChange={(e) =>
                dispatchDestination({
                  type: QueryActions.setType,
                  data: {
                    ...initialState,
                    type: (e as { value: string })?.value,
                  },
                })
              }
            />
          </Form.Group>
        </Col>
        <Col className="col-auto my-1">
          <Form.Group>
            <Form.Label visuallyHidden htmlFor="destination-soc">
              SOC
            </Form.Label>
            <SignalSingleSelect
              placeholder="SOC"
              inputId="destination-soc"
              isClearable
              options={destinationQuery.socOptions}
              value={getSignalOptionFromGroup(destinationQuery.soc, destinationQuery.socOptions)}
              onChange={(option) => handleSOCChange(option as SignalOption<string> | undefined)}
            />
          </Form.Group>
        </Col>
        <Col className="col-auto my-1">
          <Form.Group>
            <Form.Label visuallyHidden htmlFor="destination-sort">
              Sort
            </Form.Label>
            <SignalSingleSelect
              placeholder="Sort"
              inputId="destination-sort"
              options={getSortOptions}
              value={getSignalOption(sortType, getSortOptions)}
              onChange={(option) => handleSortChange(option as SignalOption<DestinationSortType>)}
            />
          </Form.Group>
        </Col>
        {sortType === DestinationSortType.Name && (
          <Col className="col-auto my-1">
            <Form.Group>
              <Button
                title={`Change sort order to ${
                  sortOrder === SortOrder.Ascending ? 'descending (Z to A)' : 'ascending (A to Z)'
                }`}
                onClick={toggleSortOrder}
              >
                {sortOrder === SortOrder.Ascending ? (
                  <FontAwesomeIcon icon={faArrowDownAZ} />
                ) : (
                  <FontAwesomeIcon icon={faArrowUpAZ} />
                )}
              </Button>
            </Form.Group>
          </Col>
        )}
        <Col className="col-auto my-1">
          <Form.Group>
            <Button variant="danger" onClick={resetFilters}>
              Reset
            </Button>
          </Form.Group>
        </Col>
      </Row>
      {destinationQuery.loadingState === LoadingState.Loading && <SignalLoading />}
      {destinationQuery.loadingState === LoadingState.Error && (
        <p className="m-3 text-center">Unable to load destinations. Please try again.</p>
      )}
      {destinationQuery.loadingState === LoadingState.Success && (
        <>
          <Form className="mx-3">
            <FormGroup>
              <Form.Text className={!!props.errors?.finalDestinationID ? 'is-invalid' : ''}></Form.Text>
              <Feedback type="invalid">{props.errors?.finalDestinationID}</Feedback>
            </FormGroup>
          </Form>
          {displayedDestinations.map((d, index) => {
            return (
              <DestinationCard
                key={`destCard-${d.id}`}
                destination={d}
                incident={incident}
                index={index}
                small={small}
                isEditable={isEditable}
                onSelect={onSelect}
              />
            );
          })}
        </>
      )}
      <SignalPagination
        paginationQuery={pagination}
        updatePaginationQuery={dispatchPagination}
        total={filteredDestinations.length}
        short
      />
    </>
  );
};

export default DestinationCollection;
