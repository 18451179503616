import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import '../../listTable.css';

export default function ClientUserCard(props: {
  key: number;
  firstname: string;
  lastname: string;
  emailAddress: string;
  awareUserID: string;
  clientUserState: string;
  clientUserRole: string;
  modifiedOnUtc: string;
  myAwareUserID: string;
  canApprove: boolean;
  handleUserStateClick: (event: React.MouseEvent) => void;
  handleUserRoleClick: (event: React.MouseEvent) => void;
  handleUserStateApproveClick: (event: React.MouseEvent) => void;
  handleUserStateDenyClick: (event: React.MouseEvent) => void;
}): JSX.Element {
  const IsSelf = (props: any) => {
    return props.awareUserID === props.myAwareUserID;
  };

  return (
    <>
      <tr className={IsSelf(props) ? 'list-table-row-disabled list-table-row' : 'list-table-row'}>
        <td className="list-table-col list-table-col-text">
          {props.clientUserState === 'Approved' ? (
            IsSelf(props) ? (
              <span>Active</span>
            ) : (
              <Badge
                pill
                className="clickable"
                bg="success"
                title="Click to Disable this User"
                onClick={props.handleUserStateClick}
              >
                Active
              </Badge>
            )
          ) : props.clientUserState === 'Requested' ? (
            IsSelf(props) ? (
              <span>{props.clientUserState}</span>
            ) : (
              <Badge pill bg="warning">
                {props.clientUserState}
              </Badge>
            )
          ) : props.clientUserState === 'Disabled' ? (
            IsSelf(props) || !props.canApprove ? (
              <span>Inactive</span>
            ) : (
              <Badge
                pill
                className="clickable"
                bg="danger"
                title="Click to Enable this User"
                onClick={props.handleUserStateClick}
              >
                Inactive
              </Badge>
            )
          ) : (
            <Badge pill bg="danger" title="Click to Enable this User" onClick={props.handleUserStateClick}>
              {props.clientUserState}
            </Badge>
          )}
        </td>
        <td className="list-table-col list-table-col-text">
          {props.clientUserRole === 'Admin' ? (
            IsSelf(props) ? (
              <span>{props.clientUserRole}</span>
            ) : (
              <Badge
                pill
                className="clickable"
                title="Click to change Role to User"
                bg="primary"
                onClick={props.handleUserRoleClick}
              >
                {props.clientUserRole}
              </Badge>
            )
          ) : IsSelf(props) ? (
            <span>{props.clientUserRole}</span>
          ) : (
            <Badge
              pill
              className="clickable"
              title="Click to change Role to Admin"
              bg="secondary"
              onClick={props.handleUserRoleClick}
            >
              {props.clientUserRole}
            </Badge>
          )}
        </td>
        <td className="list-table-col list-table-col-text">{props.firstname}</td>
        <td className="list-table-col list-table-col-text">{props.lastname}</td>
        <td className="list-table-col list-table-col-text">
          {props.emailAddress}
          {props.awareUserID === props.myAwareUserID ? (
            <span>
              {' '}
              <Badge pill bg="success" title="This is you. You cannot modify your own Status or Role.">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-person-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                </svg>
              </Badge>
            </span>
          ) : (
            <span></span>
          )}
        </td>
        <td className="list-table-col list-table-col-text">
          {props.clientUserState === 'Requested' ? (
            <>
              <span> </span>
              <Button
                id={props.awareUserID}
                disabled={IsSelf(props) || !props.canApprove}
                type="submit"
                variant="success"
                size="sm"
                onClick={props.handleUserStateApproveClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-check"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path>
                </svg>
                Approve
              </Button>
              <span> </span>
              <Button
                id={props.awareUserID}
                disabled={IsSelf(props)}
                type="submit"
                variant="danger"
                size="sm"
                onClick={props.handleUserStateDenyClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
                Deny
              </Button>
            </>
          ) : (
            <span></span>
          )}
        </td>
      </tr>
    </>
  );
}
