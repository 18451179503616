export function Unique(arr: any[]) {
  var a = [];
  for (var i = 0, l = arr.length; i < l; i++) if (a.indexOf(arr[i]) === -1 && arr[i] !== '') a.push(arr[i]);
  return a;
}

// Check of the contents of two arrays are identical, using the provided itemsEqual
// delegate to determine if items are equal
export function arraysEqual<T>(a: T[], b: T[], itemsEqual: (c: T, d: T) => boolean) {
  if (a.length !== b.length) {
    return false;
  }

  // empty arrays are equal
  if (a.length === 0 && b.length === 0) {
    return true;
  }

  let arraysEqual = true;
  // loop over the first array, take an item from it, and then check it for equality
  // against all items in the second array
  let i = 0;
  while (i < a.length && arraysEqual) {
    const itemFromA = a.at(i)!;

    // compare the item against every item in b
    let j = 0;
    let arrayBHasEqualItem = false;

    while (j < b.length && !arrayBHasEqualItem) {
      const itemFromB = b.at(j)!;
      arrayBHasEqualItem = itemsEqual(itemFromA, itemFromB);
      j++;
    }

    // if we have a matching item, then the loop condition holds and we'll check the
    // next item in array a; if we didn't find a matching item, we know the arrays
    // are not equal and can terminate the loop
    arraysEqual = arrayBHasEqualItem;

    i++;
  }

  return arraysEqual;
}

// Break an array up into chunks with the number of items in each
// chunk equaling provided chunkSize
export function chunk<T>(source: T[], chunkSize: number) {
  const shallowCopy = source.slice();
  const chunks: Array<Array<T>> = [];

  while (shallowCopy.length) {
    chunks.push(shallowCopy.splice(0, chunkSize));
  }

  return chunks;
}
