import { useEffect } from 'react';
import { useAppDispatch } from '../../common/appState/appStateHooks';
import { RightNavItems, updateRightNavActions } from '../../common/appState/rightNavActionsSlice';

const CallTriageRightHandNav = () => {
  const appDispatch = useAppDispatch();

  // Set right hand buttons.
  useEffect(() => {
    appDispatch(updateRightNavActions([RightNavItems.addCallTriageIncidentButton]));
    return () => {
      appDispatch(updateRightNavActions([]));
    };
  }, [appDispatch]);

  return <></>;
};

export default CallTriageRightHandNav;
