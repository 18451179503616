import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../common/api';
import { useAppDispatch } from '../../common/appState/appStateHooks';
import { updateAvailableClients } from '../../common/appState/availableClientsSlice';
import { updateClientId, updateClientName } from '../../common/appState/currentClientSlice';
import routes from '../../common/routesDefinitions';
import { getData } from '../../models/Helpers/FetchHelper';
import { ClientModule } from '../../models/Module';
import modules from '../feature/modules';
import login from './login';

export default function Login() {
  const [userEmailAddress, setUserEmailAddress] = useState({
    emailAddress: '',
  });
  const [userPassword, setUserPassword] = useState({
    password: '',
  });
  const [searchParams] = useSearchParams();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const navigate = useNavigate();

  // We'll need to be able to update the current client information upon login in our global application state
  // which is using Redux; this hook lets us dispatch what's known as actions to trigger updates in our global
  // app state store
  const dispatch = useAppDispatch();

  const updateClientStore = () => {
    //update our current client information in the global (Redux) app state store
    const clientAlias = JSON.parse(sessionStorage.clientAlias);

    dispatch(updateClientId(clientAlias.clientID));
    dispatch(updateClientName(clientAlias.clientName));

    const availableClientsRaw = JSON.parse(sessionStorage.clientList);

    const availableClients = availableClientsRaw.map((availableClient: { clientID: string; clientName: string }) => ({
      clientID: availableClient.clientID,
      clientName: availableClient.clientName,
    }));

    // update the available clients in the global (Redux) app state store
    dispatch(updateAvailableClients(availableClients));
  };

  const handleEmailChange = (event: any) => {
    setUserEmailAddress({ ...userEmailAddress, emailAddress: event.target.value });
  };
  const handlePassChange = (event: any) => {
    setUserPassword({ ...userPassword, password: event.target.value });
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const loginResponse = await login({
      emailAddress: userEmailAddress.emailAddress,
      password: userPassword.password,
      searchParams:
        searchParams.size > 0
          ? {
              action: searchParams.get('action'),
              clientCode: searchParams.get('clientCode'),
              invitationCode: searchParams.get('invitationCode'),
            }
          : undefined,
    });

    if (loginResponse.ok()) {
      setUserEmailAddress({ emailAddress: '' });
      setUserPassword({ password: '' });

      if (sessionStorage.clientAlias) {
        updateClientStore();
        const clientID = JSON.parse(sessionStorage.clientAlias).clientID;
        if (clientID !== '0') {
          const clientModules = (await (
            await getData(`${api.baseUrl}/api/clients/${clientID}/modules/active`, sessionStorage.accessToken)
          ).json()) as ClientModule[];
          const callTriageIndex = clientModules.findIndex((cm) => cm.name === modules.callTriage);

          if (callTriageIndex > -1) {
            navigate(routes.callTriageIncident);
            return;
          }
        }
      }
      navigate(routes.dashboard);
    } else {
      setErrorMessages(loginResponse.errorMessages);
    }
  };

  return (
    <div className="container p-3">
      {searchParams.get('action') === 'activation' && (
        <Row>
          <Col>
            <Alert className="text-center">
              Create an account or login to your aware account to activate your Signal invitation.
            </Alert>
          </Col>
        </Row>
      )}
      <Row className="justify-content-center">
        <Col xl={5} lg={7} md={9}>
          <div className="form-bg form-border shadow-lg p-5">
            <Form onSubmit={(e) => handleFormSubmit(e)}>
              <Alert
                dismissible
                onClose={() => setErrorMessages([])}
                variant="danger"
                show={errorMessages.length > 0}
                aria-label="Login Errors"
              >
                <ul>
                  {errorMessages.map((errorMessage, index) => (
                    <li key={`errorMessage_${index}`}>{errorMessage}</li>
                  ))}
                </ul>
              </Alert>
              <Form.Label className="form-title" htmlFor="emailAddress">
                Email Address
              </Form.Label>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="emailAddress"
                  onChange={handleEmailChange}
                  value={userEmailAddress.emailAddress}
                  required
                  id="emailAddress"
                />
              </Form.Group>
              <Form.Label className="form-title" htmlFor="password">
                Password
              </Form.Label>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  name="password"
                  onChange={handlePassChange}
                  value={userPassword.password}
                  required
                  id="password"
                />
              </Form.Group>
              <Row className="my-1">
                <Col>
                  <Link
                    to={routes.create1}
                    state={{
                      searchParams:
                        searchParams.size > 0
                          ? {
                              clientCode: searchParams.get('clientCode'),
                              invitationCode: searchParams.get('invitationCode'),
                            }
                          : undefined,
                    }}
                    className="float-start text-it-white text-small"
                  >
                    Create an Account
                  </Link>
                </Col>
                <Col>
                  <Link to="/forgot" className="float-end text-it-white text-small">
                    Forgot Password
                  </Link>
                </Col>
              </Row>
              <Row className="mt-5 mx-1">
                <Button type="submit" name="login" variant="primary" className="col-6 offset-6">
                  LOGIN
                </Button>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}
