import Spinner from 'react-bootstrap/Spinner';

const SignalLoading = () => {
  return (
    <div className="h-100 d-flex justify-content-center align align-items-center p-1">
      <Spinner /> Loading
    </div>
  );
};

export default SignalLoading;
