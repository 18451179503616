import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../common/appState/appStateHooks';
import { selectClientId } from '../../../common/appState/currentClientSlice';
import { CommentDateTimeFormatter, dateTimeUnknownUTCToLocal } from '../../../models/Helpers/DateHelper';
import { useApiClient } from '../../useApiClient/useApiClient';
import { AuditChange, CallTriageAuditLog } from '../types';

interface CallTriageIncidentAuditLogProps {
  id: string;
}

const CallTriageIncidentAuditLog = ({ id }: CallTriageIncidentAuditLogProps) => {
  const apiClient = useApiClient();
  const clientId = useAppSelector(selectClientId);
  const [logs, setLogs] = useState<CallTriageAuditLog[]>([]);

  useEffect(() => {
    const loadAuditLog = async () => {
      if (!id) return;
      const auditLogs = await apiClient.Incidents.getAuditLog(clientId, id);
      setLogs(auditLogs);
    };

    loadAuditLog();
  }, [apiClient.Incidents, id, clientId]);

  return (
    <>
      <table className="table table-striped mb-0">
        <tbody>
          {logs.map((log) => {
            if (!log.notes) return <></>;
            const date = `${CommentDateTimeFormatter.format(dateTimeUnknownUTCToLocal(log.createdOnUtc))}`;
            const name = `${log.createdByFirstname} ${log.createdByLastname}`.trim();
            let message = `${date} - ${name} `;
            if (log.action === 'Created') {
              const notes = JSON.parse(log.notes) as string;
              message += `created Incident #${notes}`;
              return (
                <>
                  <tr>
                    <td>{message}</td>
                  </tr>
                </>
              );
            } else if (log.action === 'Updated') {
              const notes = JSON.parse(log.notes) as AuditChange[];

              return (
                <>
                  <tr>
                    <td>
                      <p>{message}updated Incident</p>
                      <ul>
                        {notes.map((note) => {
                          return (
                            <>
                              <li>{`${note.Property} was changed to '${note.NewValue}' from '${note.OldValue}'`}</li>
                            </>
                          );
                        })}
                      </ul>
                    </td>
                  </tr>
                </>
              );
            } else {
              const notes = JSON.parse(log.notes) as string;
              message += `${log.action} ${notes}`;
              return (
                <>
                  <tr>
                    <td>{message}</td>
                  </tr>
                </>
              );
            }
          })}
        </tbody>
      </table>
    </>
  );
};

export default CallTriageIncidentAuditLog;
