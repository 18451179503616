import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import api from '../../../common/api';
import { useAppSelector } from '../../../common/appState/appStateHooks';
import { selectClientId } from '../../../common/appState/currentClientSlice';
import { ReducerAction } from '../../../common/reducerAction';
import Report from '../reportBase';
import { DefaultReportInitialState, DefaultReportParameters, DefaultReportReducer } from '../reportParameters';
import ReportQuery, { QueryParametersEnum } from '../reportQueries';
import { ResponseAction } from '../types';

type ReportResponse = {
  fullName: string;
  awareUserID: string;
  responseAction: string;
  statusCount: number;
};

type ReportParameters = {
  responseActions: ResponseAction[];
  userIDs: string[];
} & DefaultReportParameters;

const ReportInitialState: ReportParameters = {
  ...DefaultReportInitialState,
  responseActions: [],
  userIDs: [],
};

const ReportReducer: ImmerReducer<ReportParameters, ReducerAction<ReportParameters>> = (draft, action) => {
  switch (action.type) {
    case QueryParametersEnum.ResponseActions:
      draft.responseActions = action.data.responseActions;
      break;
    case QueryParametersEnum.UserIDs:
      draft.userIDs = action.data.userIDs;
      break;
    default:
      DefaultReportReducer(draft, action);
      break;
  }
};

const ResponderActionByPersonnelReport = (): JSX.Element => {
  const currentClientId = useAppSelector(selectClientId);
  const columnHelper = createColumnHelper<ReportResponse>();
  const [reportQuery, updateReportQuery] = useImmerReducer(ReportReducer, ReportInitialState);

  const resultColumns: ColumnDef<ReportResponse, any>[] = [
    columnHelper.accessor('fullName', { header: 'Personnel Name' }),
    columnHelper.accessor('responseAction', { header: 'Response Action' }),
    columnHelper.accessor('statusCount', { header: 'Count' }),
  ];

  return (
    <>
      <Report.Base
        title={'Responder - Total Count of Responses by Personnel'}
        columns={resultColumns}
        fetchUrl={`${api.baseUrl}/api/clients/${currentClientId}/reports/responder/actionByPersonnel`}
        reportQuery={reportQuery}
        updateReportQuery={updateReportQuery}
      >
        <ReportQuery.Personnel reportQuery={reportQuery} updateReportQuery={updateReportQuery} />
        <ReportQuery.ResponseActions reportQuery={reportQuery} updateReportQuery={updateReportQuery} />
      </Report.Base>
    </>
  );
};

export default ResponderActionByPersonnelReport;
