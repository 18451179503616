import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './appStore';
import { ClientModule } from '../../models/Module';

interface ClientModulesState {
  enabledModules: ClientModule[];
}

const initialState: ClientModulesState = {
  enabledModules: [],
};

export const clientModulesSlice = createSlice({
  name: 'clientModules',
  initialState,
  reducers: {
    addClientModule: (state, action: PayloadAction<ClientModule>) => {
      state.enabledModules.push(action.payload);
    },
    removeClientModule: (state, action: PayloadAction<ClientModule>) => {
      const moduleIndex = state.enabledModules.findIndex((x) => x.id === action.payload.id);

      if (moduleIndex > -1) {
        state.enabledModules.splice(moduleIndex);
      }
    },
    updateClientModules: (state, action: PayloadAction<ClientModule[]>) => {
      state.enabledModules = action.payload;
    },
  },
});

export const { addClientModule, removeClientModule, updateClientModules } = clientModulesSlice.actions;

export const selectClientModules = (state: RootState) => state.clientModules;

export default clientModulesSlice.reducer;
