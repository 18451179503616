import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import routes from '../../common/routesDefinitions';
import './callTriage.scss';

const CallTriageTopNav = () => {
  return (
    <>
      <Nav fill justify variant="pills">
        <Nav.Item>
          <NavLink className={'nav-link'} to={routes.callTriageIncident}>
            Incident Details
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink className={'nav-link'} to={routes.callTriageDestinations}>
            Destinations
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink className={'nav-link'} to={routes.callTriageEventLog}>
            Event Log
          </NavLink>
        </Nav.Item>
      </Nav>
    </>
  );
};

export default CallTriageTopNav;
