import DestinationCollection from './destinationCollection';
import CallTriageRightHandNav from './rightHandNav';
import CallTriageTopNav from './topNav';

const CallTriageDestinations = () => {
  return (
    <>
      <CallTriageRightHandNav />
      <CallTriageTopNav />
      <DestinationCollection />
    </>
  );
};

export default CallTriageDestinations;
