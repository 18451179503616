import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FormControlProps } from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';

export interface GenericRangeProps {
  label?: string;
  fromProps: FormControlProps;
  toProps: FormControlProps;
}

interface InputRangeProps extends GenericRangeProps {
  inputType: string;
}

const InputRange = ({ label, fromProps, toProps, inputType }: InputRangeProps) => {
  return (
    <>
      <Row>
        {label && <Form.Label>{label}</Form.Label>}
        <Col>
          <Form.Control title="from" type={inputType} {...fromProps} />
        </Col>
        to
        <Col>
          <Form.Control title="to" type={inputType} {...toProps} />
        </Col>
      </Row>
    </>
  );
};

export default InputRange;
