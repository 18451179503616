import './StepIcon.css';

import React from 'react';

import clsx from '../classnames';

export interface StepIconProps {
  /**
   * True if this is the icon for the currently active step.
   */
  active?: boolean;

  /**
   * True if this icon is for a completed step (typically for steps that came before the current active step).
   */
  completed?: boolean;

  /**
   * True if this icon is for a disabled step.
   */
  disabled?: boolean;

  /**
   * True if this icon is for a step that has an error.
   */
  error?: boolean;

  /**
   * The text displayed in the step icon.
   */
  icon: string | number | undefined;
}

const StepIcon: React.FC<StepIconProps> = (props) => {
  const { active = false, completed = false, disabled = false, error = false, icon } = props;
  const className = clsx(
    'stepper-step-icon',
    active && 'active',
    completed && 'completed',
    error && 'error',
    disabled && 'disabled'
  );
  return (
    <svg className={className} focusable="false" viewBox="0 0 26 26" aria-hidden={true}>
      <circle className="stepper-step-icon-circle" cx="12" cy="12" r="12" />
      <text className="stepper-step-icon-text" x="12" y="16" textAnchor="middle">
        {icon}
      </text>
    </svg>
  );
};
export default StepIcon;
