import './StepLabel.css';

import React from 'react';

import clsx from '../classnames';
import StepIcon from './StepIcon';

export interface StepLabelProps {
  /**
   * True if this is the label for the currently active step.
   */
  active?: boolean;

  /**
   * True if this label is for a completed step (typically for steps that came before the current active step).
   */
  completed?: boolean;

  /**
   * True if this label is for a disabled step.
   */
  disabled?: boolean;

  /**
   * True if this label is for a step that has an error.
   */
  error?: boolean;

  /**
   * Override the default label of the step icon.
   */
  icon?: string | number;

  /**
   * In most cases will simply be a string containing a title for the label.
   */
  children?: React.ReactNode;
}

const StepLabel: React.FC<StepLabelProps> = (props) => {
  const { active = false, completed = false, disabled = false, error = false, icon, children } = props;

  return (
    <span
      className={clsx(
        'stepper-step-label',
        active && 'active',
        completed && 'completed',
        disabled && 'disabled',
        error && 'error'
      )}
    >
      <span className="stepper-step-label-iconContainer">
        <StepIcon completed={completed} active={active} disabled={disabled} error={error} icon={icon} />
      </span>
      <span className="stepper-step-label-textContainer">
        <span className="stepper-step-label-text">{children}</span>
      </span>
    </span>
  );
};
export default StepLabel;
