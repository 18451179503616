export enum DestinationSortType {
  Distance = 'Sort by Distance',
  Name = 'Sort by Name',
  Diversion = 'Sort by Diversion Updated',
  NEDOCS = 'Sort by NEDOCS Updated',
}

export enum SortOrder {
  Ascending,
  Descending,
}
