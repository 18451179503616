import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import StickyMainContent from '../common/stickyMainContent';
import { BackButton } from './back';

interface HeaderProps {
  backTo?: string;
  title: string;
  children?: React.ReactNode;
}

export default function HeaderTitle({ backTo, title, ...props }: HeaderProps): JSX.Element {
  return (
    <>
      <StickyMainContent>
        <Row className="mainContent-gray mx-0 pb-2">
          {backTo && <BackButton to={backTo} />}
          <Col className="d-flex">
            <h3 className="pageHeader">{title}</h3>
            <span className="ms-auto">{props.children}</span>
          </Col>
        </Row>
      </StickyMainContent>
    </>
  );
}
