import { Reducer, useReducer } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { ReducerAction } from '../../common/reducerAction';
import { SignalOption } from '../../common/types';
import { IncidentDateRange, IncidentDateRangeType, MultiDateRangeOptions } from '../incidentSearch/IncidentDateRange';
import { useInterval } from '../useInterval/useInterval';
import DateRange from './dateRange';

interface DateSelect {
  dateOption: IncidentDateRangeType;
  dateFrom: string;
  dateTo: string;
}

enum DateSelectActions {
  DateOption = 'DateOption',
  DateFrom = 'DateFrom',
  DateTo = 'DateTo',
}

interface DateSelectorProps {
  className?: string;
  utc?: boolean;
  options?: IncidentDateRangeType[];
  defaultValue?: IncidentDateRangeType;
  toDateValue: string;
  fromDateValue: string;
  handleToDateChange: (toDate: string) => void;
  handleFromDateChange: (fromDate: string) => void;
  updateInterval?: number;
}

const dateSelectReducer: Reducer<DateSelect, ReducerAction<DateSelect>> = (state, action) => {
  switch (action.type) {
    case DateSelectActions.DateOption:
      return {
        ...state,
        dateOption: action.data.dateOption,
      };
    case DateSelectActions.DateTo:
      return {
        ...state,
        dateTo: action.data.dateTo,
      };
    case DateSelectActions.DateFrom:
      return {
        ...state,
        dateFrom: action.data.dateFrom,
      };
    default:
      break;
  }
  return state;
};

export const DateSelector = ({
  utc = false,
  className,
  options,
  defaultValue,
  toDateValue,
  fromDateValue,
  handleToDateChange,
  handleFromDateChange,
  ...props
}: DateSelectorProps) => {
  const defaultDateOption = defaultValue || IncidentDateRangeType.Past7Days;
  const { dateFrom: defaultDateFrom, dateTo: defaultDateTo } = IncidentDateRange[defaultDateOption].getDate(
    utc,
    fromDateValue,
    toDateValue
  );
  const initialDateSelect: DateSelect = {
    dateOption: defaultDateOption,
    dateFrom: defaultDateFrom,
    dateTo: defaultDateTo,
  };

  const [dateSelect, dispatch] = useReducer(dateSelectReducer, initialDateSelect);

  const incidentDateRangeOptions: SignalOption<IncidentDateRangeType>[] = (options ?? MultiDateRangeOptions).map(
    (key) => ({
      value: key,
      label: key,
    })
  );

  const handleIncidentDateRangeChange = (incidentDateChoice: IncidentDateRangeType) => {
    const { dateFrom, dateTo } = IncidentDateRange[incidentDateChoice].getDate(
      utc,
      dateSelect.dateFrom,
      dateSelect.dateTo
    );
    dispatch({
      type: DateSelectActions.DateOption,
      data: {
        ...initialDateSelect,
        dateOption: incidentDateChoice,
      },
    });
    handleFromDateChange(dateFrom);
    handleToDateChange(dateTo);
  };

  useInterval(() => {
    handleIncidentDateRangeChange(dateSelect.dateOption);
  }, props.updateInterval ?? 10000);

  const handleDateSelectorFromDateChange = (dateString: string) => {
    const { dateFrom } = IncidentDateRange[dateSelect.dateOption].getDate(utc, dateString, dateSelect.dateTo);
    handleFromDateChange(dateFrom);
    dispatch({
      type: DateSelectActions.DateFrom,
      data: {
        ...initialDateSelect,
        dateFrom: dateString,
      },
    });
  };

  const handleDateSelectorToDateChange = (dateString: string) => {
    const { dateTo } = IncidentDateRange[dateSelect.dateOption].getDate(utc, dateSelect.dateFrom, dateString);
    handleToDateChange(dateTo);
    dispatch({
      type: DateSelectActions.DateTo,
      data: {
        ...initialDateSelect,
        dateTo: dateString,
      },
    });
  };

  const handleSpecificDateChange = (dateString: string) => {
    const { dateFrom, dateTo } = IncidentDateRange[IncidentDateRangeType.SpecificDate].getDate(
      utc,
      dateString,
      dateString
    );
    dispatch({
      type: DateSelectActions.DateFrom,
      data: {
        ...initialDateSelect,
        dateFrom: dateString,
      },
    });
    dispatch({
      type: DateSelectActions.DateTo,
      data: {
        ...initialDateSelect,
        dateTo: dateString,
      },
    });
    handleFromDateChange(dateFrom);
    handleToDateChange(dateTo);
  };

  return (
    <>
      <Col className={`col-auto ${className ?? ''}`}>
        <Form.Group>
          <Form.Label>Incident Date</Form.Label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            aria-label="Incident Date"
            isSearchable
            name="color"
            value={{ value: dateSelect.dateOption, label: dateSelect.dateOption as string }}
            options={incidentDateRangeOptions}
            onChange={(e) => handleIncidentDateRangeChange(e!.value as IncidentDateRangeType)}
            styles={{
              menu: (base) => ({
                ...base,
                width: 'max-content',
                minWidth: '100%',
                zIndex: 9999,
              }),
            }}
          />
        </Form.Group>
      </Col>
      {dateSelect.dateOption === IncidentDateRangeType.SpecificDate && (
        <Col className={`col-auto ${className ?? ''}`}>
          <Form.Group>
            <Form.Label htmlFor="incident-date">Incident Date</Form.Label>
            <Form.Control
              id="incident-date"
              type="date"
              value={dateSelect.dateFrom}
              onChange={(e) => handleSpecificDateChange(e.target.value)}
            />
          </Form.Group>
        </Col>
      )}
      {dateSelect.dateOption === IncidentDateRangeType.Custom && (
        <Col className={`col-auto ${className ?? ''}`}>
          <Form.Group>
            <DateRange
              label="Custom Date Range"
              fromProps={{
                value: dateSelect.dateFrom,
                onChange: (e) => handleDateSelectorFromDateChange(e.target.value),
              }}
              toProps={{
                value: dateSelect.dateTo,
                onChange: (e) => handleDateSelectorToDateChange(e.target.value),
              }}
            />
          </Form.Group>
        </Col>
      )}
    </>
  );
};
