import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './sideNav.scss';

interface BaseSideNavProps {
  children?: React.ReactNode;
}

const BaseSideNav = ({ children }: BaseSideNavProps) => {
  return (
    <>
      <Navbar className="sidenav-wrapper py-0 d-print-none">
        <Nav className="sidenav d-flex flex-column overflow-auto">
          <Col className="d-flex flex-column align-items-top w-100">{children}</Col>
        </Nav>
      </Navbar>
    </>
  );
};

export default BaseSideNav;
