import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import BounceLoader from 'react-spinners/BounceLoader';
import Navbar from '../navbar/navbar';

export default function Association() {
  const user = JSON.parse(sessionStorage.user);
  if (!user.token || !user) {
    window.location.href = '/';
  }
  let loading = true;
  // const override = css`
  //   display: block;
  //   margin: 0 auto;
  // `;

  // formatting for POST requests
  async function postData(url = '', data = {}) {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  // formatting for GET requests
  async function getData(url = '') {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },
    });
    return response.json();
  }

  async function removeAssociation(agency: any) {
    let selection = sessionStorage.getItem(`${agency.name}`);
    let selectedAgency = JSON.parse(`${selection}`);

    if (window.confirm('Are you sure you want to remove this agency?')) {
      selectedAgency.active = false;
      // let userAgency = new UserAgency({
      //     user: this.user,
      //     agency: agency,
      //     organization: agency.organization,
      // });
      postData('https://localhost:44342/api/user/RemoveAgency', {
        user: user,
        agency: selectedAgency,
        organization: selectedAgency.organization,
      }).then((data) => {
        sessionStorage.setItem('agencies', JSON.stringify(data));
      });
      sessionStorage.removeItem('agencies');
      sessionStorage.removeItem(agency.name);

      load();
    }
  }

  async function load() {
    postData('https://localhost:44342/api/user/agencies', {
      id: user.id,
      emailAddress: user.emailAddress,
      verified: user.verified,
      active: user.active,
      admin: user.admin,
    }).then((data) => {
      //if (data !== []) {
      sessionStorage.setItem('agencies', JSON.stringify(data));
      //}
    });
    if (sessionStorage.getItem('agencies') != null) {
      let agencies = JSON.parse(sessionStorage.agencies);
      // stores agencies as separate objects for potential removal
      for (let a = 0; a < agencies.length; a++) {
        let agency = agencies[a];
        if (agency.modules === null || agency.modules.length === 0) {
          // calls for agency modules if the returned agency list does not have them
          postData('https://localhost:44342/api/agency/modules', {
            active: agency.active,
            activeStatus: agency.activeStatus,
            id: agency.id,
          }).then((data) => {
            agency.modules = data;
            sessionStorage.setItem(`${agency.name}`, JSON.stringify(agency));
          });
        } else {
          sessionStorage.setItem(`${agency.name}`, JSON.stringify(agency));
        }
      }
      // setLoading(false);
      for (let i = 0; i < agencies.length; i++) {
        agencyArray.push({
          id: i,
          name: agencies[i].name,
        });
      }
      agencyArray.shift();
      // loading = false;
    }
  }
  // sets array of retrieved agencies at accessible scope for return
  const agencyArray = [
    {
      id: 0,
      name: '',
    },
  ];

  // stores a products object
  async function products() {
    getData('https://localhost:44342/api/product/all').then((data) => {
      sessionStorage.setItem('products', JSON.stringify(data));
    });
  }
  // calls the above functions on page init
  products();
  load();

  // functions call different association modals
  async function associateElite() {
    let product = 'elite';
    connectModal(product);
  }
  async function associateLicense() {
    let product = 'license';
    connectModal(product);
  }
  async function associateSlate() {
    let product = 'slate';
    connectModal(product);
  }

  // modal visibility state variables
  const [showElite, setShowElite] = useState(false);
  const handleCloseElite = () => setShowElite(false);
  const handleShowElite = () => setShowElite(true);

  const [showLicense, setShowLicense] = useState(false);
  const handleCloseLicense = () => setShowLicense(false);
  const handleShowLicense = () => setShowLicense(true);

  const [showSlate, setShowSlate] = useState(false);
  const handleCloseSlate = () => setShowSlate(false);
  const handleShowSlate = () => setShowSlate(true);

  function connectModal(product: string) {
    if (product === 'elite') {
      handleShowElite();
    }
    if (product === 'license') {
      handleShowLicense();
    }
    if (product === 'slate') {
      handleShowSlate();
    }
  }

  // function connectElite() {
  //     postData(
  //         "https://localhost:44342/api/elite/OrganizationByIdentifier", {
  //         id: document.getElementById("elite1")
  //     })
  //         .then(data => {
  //             agency.modules = data;
  //             sessionStorage.setItem(`${agency.name}`, JSON.stringify(agency));
  //         });
  // }

  return (
    <div>
      <Navbar />
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-12 col-md-9">
                <div className="card o-hidden border-0 shadow-lg my-5">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="p-5">
                          <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-4">My Accounts</h1>
                            <p>
                              Here are the accounts You've authenticated using Aware. You may authenticate multiple
                              accounts for each product.
                            </p>
                            <hr />
                            <BounceLoader color="blue" loading={!loading} />
                            <div className="card">
                              <ul className="list-group list-group-flush">
                                {agencyArray.map((agency) => {
                                  if (agencyArray.length !== 0 && agency.name !== '') {
                                    return (
                                      <>
                                        <li className="list-group-item" id={agency.name} key={agency.id}>
                                          {agency.name}
                                          <Button
                                            className="btn btn-danger removal"
                                            type="submit"
                                            key={agency.id}
                                            onClick={() => removeAssociation(agency)}
                                          >
                                            X
                                          </Button>
                                        </li>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <li className="list-group-item">
                                        <p>Your account does not have any existing agency associations.</p>
                                      </li>
                                    );
                                  }
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-12 col-md-9">
                <div className="card o-hidden border-0 shadow-lg my-1">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="p-5">
                          <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-4">Associate an Account</h1>
                            <p>Select from a product below to authenticate a connection to Frogmouth and Aware.</p>
                            <hr />
                            <div className="card">
                              <ul className="list-group list-group-flush">
                                <li className="list-group my-2" key="0">
                                  <div className="d-grid gap-2">
                                    <Button variant="danger" size="lg" onClick={associateElite}>
                                      ELITE
                                    </Button>
                                  </div>
                                </li>
                                <li className="list-group my-2">
                                  <div className="d-grid gap-2" key="1">
                                    <Button variant="success" size="lg" onClick={associateLicense}>
                                      LICENSE MANAGEMENT
                                    </Button>
                                  </div>
                                </li>
                                <li className="list-group my-2" key="2">
                                  <div className="d-grid gap-2">
                                    <Button variant="primary" size="lg" onClick={associateSlate}>
                                      SLATE
                                    </Button>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <Modal show={showElite} onHide={handleCloseElite}>
                          <Modal.Header closeButton>
                            <Modal.Title>Connect to an Elite Account</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Group className="mb-3" controlId="eliteForm.ControlInput1">
                                <Form.Label>Organization ID</Form.Label>
                                <Form.Control type="text" autoFocus required />
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="eliteForm.ControlInput2">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" required />
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="eliteForm.ControlInput3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" required name="password" />
                              </Form.Group>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="primary">Connect My Account</Button>
                          </Modal.Footer>
                        </Modal>

                        <Modal show={showLicense} onHide={handleCloseLicense}>
                          <Modal.Header closeButton>
                            <Modal.Title>Connect to a License Management Account</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Group className="mb-3" controlId="licenseForm.ControlInput1">
                                <Form.Label>State</Form.Label>
                                <Form.Control type="text" autoFocus required />
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="licenseForm.ControlInput2">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" required />
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="licenseForm.ControlInput3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" required />
                              </Form.Group>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="primary">Connect My Account</Button>
                          </Modal.Footer>
                        </Modal>

                        <Modal show={showSlate} onHide={handleCloseSlate}>
                          <Modal.Header closeButton>
                            <Modal.Title>Connect to a Slate Account</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Group className="mb-3" controlId="slateForm.ControlInput1">
                                <Form.Label>Organization ID</Form.Label>
                                <Form.Control type="text" autoFocus required />
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="slateForm.ControlInput2">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" required />
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="slateForm.ControlInput3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" required />
                              </Form.Group>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="primary">Connect My Account</Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
