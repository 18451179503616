import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@imagetrend/fontawesome-pro/pro-solid-svg-icons';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Stack from 'react-bootstrap/Stack';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import api from '../../common/api';
import { useAppSelector } from '../../common/appState/appStateHooks';
import { selectClientId } from '../../common/appState/currentClientSlice';
import { ReducerAction } from '../../common/reducerAction';
import routes from '../../common/routesDefinitions';
import { constructAddress } from '../../models/Helpers/AddressHelper';
import { dateToDateString, dateToTimeString } from '../../models/Helpers/DateHelper';
import { postData } from '../../models/Helpers/FetchHelper';
import { Incident } from '../../models/Incident';
import DateRange from '../common/dateRange';
import SignalTable from '../common/signalTable';
import TimeRange from '../common/timeRange';
import HeaderTitle from '../headerTitle/headerTitle';
import { useApiClient } from '../useApiClient/useApiClient';
import { IncidentDateRange, IncidentDateRangeType, MultiDateRangeOptions } from './IncidentDateRange';
import './incidentSearch.css';

// search state actions used by the search reducer to handle what search options are provided
enum SearchActions {
  fromDate = 'fromDate',
  toDate = 'toDate',
  fromTime = 'fromTime',
  toTime = 'toTime',
  incidentNumber = 'incidentNumber',
  streetNumber = 'streetNumber',
  streetName = 'streetName',
  city = 'city',
  unitNumber = 'unitNumber',
  fromAndToDate = 'fromAndToDate',
  incidentDateOption = 'incidentDateOption',

  all = 'all',
}

// query actions which may be handled by the query reducer
enum QueryActions {
  autoRetrieve = 'autoRetrieve',
  showLoadingIndicator = 'showLoadingIndicator',
}

type IncidentSearchState = {
  dateFrom: string;
  dateTo: string;
  timeFrom: string;
  timeTo: string;
  incidentNumber: string;
  streetNumber: string;
  streetName: string;
  city: string;
  units: string[];
  incidentDateOption: IncidentDateRangeType;
};

type QueryState = { enableAutoFetch: boolean; showLoadingIndicator: boolean };

type IncidentSearchResult = Pick<
  Incident,
  | 'createdByDispatchDateTime'
  | 'eventType'
  | 'incidentID'
  | 'incidentNumber'
  | 'status'
  | 'streetAddressFull'
  | 'streetNumber'
  | 'streetName'
  | 'streetType'
  | 'streetPrefix'
  | 'streetSuffix'
  | 'city'
  | 'state'
  | 'unitNames'
  | 'active'
>;

// manages which search options have been provided
const incidentSearchReducer: ImmerReducer<IncidentSearchState, ReducerAction<IncidentSearchState>> = (
  draft,
  action
) => {
  switch (action.type) {
    case SearchActions.fromAndToDate:
      draft.dateTo = action.data.dateTo;
      draft.dateFrom = action.data.dateFrom;
      break;
    case SearchActions.fromDate:
      draft.dateFrom = action.data.dateFrom;
      break;
    case SearchActions.toDate:
      draft.dateTo = action.data.dateTo;
      break;
    case SearchActions.fromTime:
      draft.timeFrom = action.data.timeFrom;
      break;
    case SearchActions.toTime:
      draft.timeTo = action.data.timeTo;
      break;
    case SearchActions.incidentNumber:
      draft.incidentNumber = action.data.incidentNumber;
      break;
    case SearchActions.streetNumber:
      draft.streetNumber = action.data.streetNumber;
      break;
    case SearchActions.streetName:
      draft.streetName = action.data.streetName;
      break;
    case SearchActions.city:
      draft.city = action.data.city;
      break;
    case SearchActions.unitNumber:
      draft.units = action.data.units;
      break;
    case SearchActions.incidentDateOption:
      draft.incidentDateOption = action.data.incidentDateOption;
      const { dateFrom, dateTo } = IncidentDateRange[action.data.incidentDateOption].getDate(
        false,
        action.data.dateFrom,
        action.data.dateTo
      );
      draft.dateTo = dateTo;
      draft.dateFrom = dateFrom;
      break;
    case SearchActions.all:
      draft.dateFrom = action.data.dateFrom;
      draft.dateTo = action.data.dateTo;
      draft.timeFrom = action.data.timeFrom;
      draft.timeTo = action.data.timeTo;
      draft.incidentNumber = action.data.incidentNumber;
      draft.streetNumber = action.data.streetNumber;
      draft.streetName = action.data.streetName;
      draft.city = action.data.city;
      draft.units = action.data.units;
      draft.incidentDateOption = action.data.incidentDateOption;
      break;
  }
};

// manages query options, primarily auto-fetching of results; when a search is invoked via the
// Go button, we enable auto fetch which tells react-query to auto-retrieve results based on its
// configured defaults
const queryReducer: ImmerReducer<QueryState, ReducerAction<QueryState>> = (draft, action) => {
  switch (action.type) {
    case QueryActions.autoRetrieve:
      draft.enableAutoFetch = action.data.enableAutoFetch;
      break;
  }
};

const initialQueryState: QueryState = {
  enableAutoFetch: false,
  showLoadingIndicator: false,
};

const columnHelper = createColumnHelper<IncidentSearchResult>();

const IncidentSearch = () => {
  const defaultDateOption = IncidentDateRangeType.Past7Days;
  const initialSearchState = {
    incidentDateOption: defaultDateOption,
    dateFrom: IncidentDateRange[defaultDateOption].getDate().dateFrom,
    dateTo: IncidentDateRange[defaultDateOption].getDate().dateTo,
    timeFrom: '',
    timeTo: '',
    incidentNumber: '',
    streetNumber: '',
    streetName: '',
    city: '',
    units: [],
  };

  const apiClient = useApiClient();
  const clientID = useAppSelector(selectClientId);

  const [units, setUnits] = useState<string[]>([]);
  const [search, updateSearchOptions] = useImmerReducer(incidentSearchReducer, initialSearchState);
  const [queryConfig, updateQueryConfig] = useImmerReducer(queryReducer, initialQueryState);

  // Get all Client Users, count the Total and the Active:
  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const clientUsersListResult = await apiClient.getClientResourceUnitList({ limit: 1000, offset: 0 }, clientID);

        setUnits(clientUsersListResult);
      } catch (error: any) {
        console.error('Failed to fetch clientUser data for clientUser count', JSON.stringify(error));
      }
    };

    fetchUnits();
  }, [clientID, apiClient, setUnits]);

  const IncidentDateRangeOptions: { value: IncidentDateRangeType; label: string }[] = MultiDateRangeOptions.map(
    (key) => ({
      value: key,
      label: key,
    })
  );

  const searchIncidents = async () => {
    let dateTo = search.dateTo;
    // due to date rounding assumptions, append as assuming EOD.
    if (!dateTo.includes('T')) {
      dateTo += 'T23:59:59.9999999';
    }
    var searchResponse = await postData(
      `${api.baseUrl}/api/clients/${clientID}/dispatchIncidents/search`,
      { ...search, dateTo },
      sessionStorage.accessToken
    );

    updateQueryConfig({
      type: QueryActions.showLoadingIndicator,
      data: { ...queryConfig, showLoadingIndicator: false },
    });

    if (searchResponse.ok) {
      return (await searchResponse.json()) as IncidentSearchResult[];
    }

    throw new Error('Unable to load incident search results');
  };

  // search result querying
  const {
    status,
    data: searchResults,
    refetch,
  } = useQuery(['incidentSearchResults'], searchIncidents, {
    enabled: queryConfig.enableAutoFetch,
    refetchInterval: 5000,
  });

  const handleIncidentDateRangeChange = (incidentDateChoice: IncidentDateRangeType) => {
    updateSearchOptions({
      type: SearchActions.incidentDateOption,
      data: {
        ...search,
        incidentDateOption: incidentDateChoice,
      },
    });
  };

  const handleFromDateChange = (dateFrom: string) => {
    updateSearchOptions({
      type: SearchActions.fromDate,
      data: {
        ...search,
        dateFrom,
      },
    });
  };

  const handleToDateChange = (dateTo: string) => {
    updateSearchOptions({
      type: SearchActions.toDate,
      data: {
        ...search,
        dateTo,
      },
    });
  };

  const handleFromTimeChange = (timeFrom: string) => {
    updateSearchOptions({
      type: SearchActions.fromTime,
      data: {
        ...search,
        timeFrom,
      },
    });
  };

  const handleToTimeChange = (timeTo: string) => {
    updateSearchOptions({
      type: SearchActions.toTime,
      data: {
        ...search,
        timeTo,
      },
    });
  };

  const handleSpecificDateChange = (specificDate: string) => {
    updateSearchOptions({
      type: SearchActions.fromAndToDate,
      data: {
        ...search,
        dateFrom: specificDate,
        dateTo: specificDate,
      },
    });
  };

  const handleIncidentNumberChange = (incidentNumber: string) => {
    updateSearchOptions({
      type: SearchActions.incidentNumber,
      data: {
        ...search,
        incidentNumber,
      },
    });
  };

  const handleStreetNumberChange = (streetNumber: string) => {
    updateSearchOptions({
      type: SearchActions.streetNumber,
      data: {
        ...search,
        streetNumber,
      },
    });
  };

  const handleStreetNameChange = (streetName: string) => {
    updateSearchOptions({
      type: SearchActions.streetName,
      data: {
        ...search,
        streetName,
      },
    });
  };

  const handleCityChange = (city: string) => {
    updateSearchOptions({
      type: SearchActions.city,
      data: {
        ...search,
        city,
      },
    });
  };

  const handleUnitsChange = (units: string[]) => {
    updateSearchOptions({
      type: SearchActions.unitNumber,
      data: {
        ...search,
        units,
      },
    });
  };

  const resetFilter = () => {
    updateSearchOptions({
      type: SearchActions.all,
      data: {
        ...initialSearchState,
      },
    });
  };

  const incidentSearchCols: ColumnDef<IncidentSearchResult, any>[] = [
    columnHelper.accessor((row) => dateToDateString(row.createdByDispatchDateTime), {
      header: 'Dispatch Date',
      id: 'incidentDate',
    }),
    columnHelper.accessor((row) => dateToTimeString(row.createdByDispatchDateTime), {
      header: 'Dispatch Time',
      id: 'incidentTime',
    }),
    columnHelper.accessor('incidentNumber', { header: 'Incident Number' }),
    columnHelper.accessor((row) => (row.active ? 'Active' : 'Inactive'), {
      id: 'incidentState',
      header: 'Incident State',
    }),
    columnHelper.accessor('eventType', { header: 'Incident Type' }),
    columnHelper.accessor(
      (row) => {
        return constructAddress(row);
      },
      { id: 'incidentAddress', header: 'Incident Address' }
    ),
    columnHelper.accessor('unitNames', { header: 'Units' }),
    {
      header: '',
      cell: ({ row }) => (
        <>
          <Link to={`${routes.incidentDetails}/${row.original.incidentID}`}>
            <Button variant="secondary">
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </Link>
          {/* 
          We can't do this for now since we store our auth information in session storage, which doesn't carry over to new tabs
          <Link to={`${routes.incidentDetails}/${row.original.incidentID}`} target="_blank">
            <Button variant="secondary">
              <FontAwesomeIcon icon={faExternalLink} />
            </Button>
          </Link> */}
        </>
      ),
      id: 'incidentActions',
    },
  ];

  return (
    <>
      <HeaderTitle title={'Incident Search'} />
      <div className="h-100 overflow-auto">
        <div className="mainContent-gray pb-3">
          <Form>
            <Row className="mx-1">
              <Col md={4} lg={3}>
                <Form.Group>
                  <Form.Label>Incident Date</Form.Label>
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable
                    name="color"
                    value={{ value: search.incidentDateOption, label: search.incidentDateOption as string }}
                    options={IncidentDateRangeOptions}
                    onChange={(e) => handleIncidentDateRangeChange(e!.value as IncidentDateRangeType)}
                  />
                </Form.Group>
              </Col>
              {search.incidentDateOption === IncidentDateRangeType.SpecificDate && (
                <Form.Group as={Col}>
                  <Form.Label htmlFor="incident-date">Incident Date</Form.Label>
                  <Form.Control
                    id="incident-date"
                    type="date"
                    value={search.dateFrom}
                    onChange={(e) => handleSpecificDateChange(e.target.value)}
                  />
                </Form.Group>
              )}
              {search.incidentDateOption === IncidentDateRangeType.Custom && (
                <Form.Group as={Col}>
                  <DateRange
                    label="Incident Date"
                    fromProps={{
                      value: search.dateFrom,
                      onChange: (e) => handleFromDateChange(e.target.value),
                    }}
                    toProps={{
                      value: search.dateTo,
                      onChange: (e) => handleToDateChange(e.target.value),
                    }}
                  />
                </Form.Group>
              )}
              <Form.Group as={Col}>
                <TimeRange
                  label="Incident Time"
                  fromProps={{
                    value: search.timeFrom,
                    onChange: (e) => handleFromTimeChange(e.target.value),
                  }}
                  toProps={{
                    value: search.timeTo,
                    onChange: (e) => handleToTimeChange(e.target.value),
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="align-items-end mx-1">
              <Form.Group as={Col}>
                <Form.Label htmlFor="incident-number">Incident Number</Form.Label>
                <Form.Control
                  id="incident-number"
                  value={search.incidentNumber}
                  onChange={(e) => handleIncidentNumberChange(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label htmlFor="street-number">Street Number</Form.Label>
                <Form.Control
                  id="street-number"
                  value={search.streetNumber}
                  onChange={(e) => handleStreetNumberChange(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label htmlFor="street-number">Street Name</Form.Label>
                <Form.Control
                  id="street-name"
                  value={search.streetName}
                  onChange={(e) => handleStreetNameChange(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label htmlFor="city">City</Form.Label>
                <Form.Control id="city" value={search.city} onChange={(e) => handleCityChange(e.target.value)} />
              </Form.Group>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor="units">Units</Form.Label>
                  <CreatableSelect
                    id={'units'}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable
                    isMulti
                    isClearable
                    name="color"
                    value={search.units.map((x) => ({ value: x, label: x }))}
                    options={units.map((x) => ({
                      value: x,
                      label: x,
                    }))}
                    onChange={(e) => handleUnitsChange(e.map((x) => x.value))}
                  />
                </Form.Group>
              </Col>
              <Col className={'mt-2 col-auto'}>
                <Stack direction="horizontal" gap={2}>
                  <Button
                    onClick={() => {
                      updateQueryConfig({
                        type: QueryActions.showLoadingIndicator,
                        data: { ...queryConfig, showLoadingIndicator: true },
                      });
                      refetch(); // fire off the search query
                      updateQueryConfig({
                        type: QueryActions.autoRetrieve,
                        data: { ...queryConfig, enableAutoFetch: true },
                      }); // enable auto fetching of query results (live updates)
                    }}
                    disabled={queryConfig.showLoadingIndicator}
                  >
                    {queryConfig.showLoadingIndicator && <Spinner animation="border" role="status" />}Search
                  </Button>
                  <Button variant="danger" onClick={resetFilter}>
                    Reset
                  </Button>
                </Stack>
              </Col>
            </Row>
          </Form>
        </div>

        {queryConfig.showLoadingIndicator && <Spinner animation="border" />}
        {status === 'error' && <Alert>Something went wrong loading search results</Alert>}
        {status === 'success' && searchResults && searchResults.length > 0 && (
          <Col className="col-auto">
            <SignalTable
              tableClassName="tableFixHead"
              columns={incidentSearchCols}
              data={searchResults}
              striped={true}
            />
          </Col>
        )}
      </div>
    </>
  );
};

export default IncidentSearch;
