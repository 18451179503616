import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import { Step, StepButton, Stepper } from '../../lib/stepper';
import { Client } from '../../models/Client';
import { ClientSettings } from '../../models/ClientSettings';
import { Country } from '../../models/Country';
import { County } from '../../models/County';
import { Psap } from '../../models/Psap';
import { State } from '../../models/State';
import { TimeZone } from '../../models/TimeZone';
import { useApiClient } from '../useApiClient/useApiClient';
export interface FormError {
  name?: boolean;
  address1?: boolean;
  city?: boolean;
  state?: boolean;
  postal?: boolean;
  phone?: boolean;
  timeZone?: boolean;
  clientCode?: boolean;
  contactName?: boolean;
  contactEmail?: boolean;
}

export default function AgencySignup() {
  const user = JSON.parse(sessionStorage.user);
  const navigate = useNavigate();
  if (!user.token || !user) {
    navigate('/');
  }
  const isCreate = false;

  const [activeStep, setActiveStep] = useState<number>(0);

  const stepTitles = ['General Details', 'Agency Details', 'Additional Information'];
  const [psapList, setPsapList] = React.useState<Psap[]>([]);
  const [stateList, setStateList] = React.useState<State[]>([]);
  const [countyList, setCountyList] = React.useState<County[]>([]);
  const [countryList, setCountryList] = React.useState<Country[]>([]);
  const [filteredCountyList, setFilteredCountyList] = React.useState<County[]>([]);
  const [filteredStateList, setFilteredStateList] = React.useState<State[]>([]);
  const [timeZoneList, setTimeZoneList] = React.useState<TimeZone[]>([]);
  const [client, setClient] = React.useState<Client>({});
  const [clientSettings, setClientSettings] = React.useState<ClientSettings>({ userLimit: 25 });
  const [stateFilter, setStateFilter] = useState<string>('');
  const [countyFilter, setCountyFilter] = useState<string>('');
  const apiClient = useApiClient();
  const [errors, setFormErrors] = React.useState<FormError>({});

  const handleNameChange = (event: any) => {
    let code = createClientCode(event.target.value, clientSettings.postalCode);
    setClient({ ...client, name: event.target.value, clientCode: code });

    setClientSettings({ ...clientSettings, departmentName: event.target.value });

    validateForm();
  };
  const handleContactEmailChange = (event: any) => {
    setClientSettings({ ...clientSettings, contactEmail: event.target.value });
    validateForm();
  };
  const handleContactNameChange = (event: any) => {
    setClientSettings({ ...clientSettings, contactName: event.target.value });
    validateForm();
  };

  const handleTimeZoneChange = (event: any) => {
    setClientSettings({ ...clientSettings, timeZoneID: event.target.value });
    validateForm();
  };
  const handlePhoneNumberChange = (event: any) => {
    handlePhoneInput(event);
    validateForm();
  };
  const handlePostalCodeChange = (event: any) => {
    let code = createClientCode(client.name, event.target.value);
    setClientSettings({ ...clientSettings, postalCode: event.target.value });
    setClient({ ...client, clientCode: code });
    validateForm();
  };
  const handleStateChange = (event: any) => {
    setClientSettings({ ...clientSettings, state: event.target.value });
    validateForm();
  };
  const handleCityChange = (event: any) => {
    setClientSettings({ ...clientSettings, city: event.target.value });
    validateForm();
  };
  const handleAddress1Change = (event: any) => {
    setClientSettings({ ...clientSettings, streetAddress: event.target.value });
    validateForm();
  };
  const handleAddress2Change = (event: any) => {
    setClientSettings({ ...clientSettings, streetAddress2: event.target.value });
    validateForm();
  };
  const handleClientCodeChange = (event: any) => {
    setClient({ ...client, clientCode: event.target.value });
    validateForm();
  };

  const handleStateSelect = (event: any) => {
    setStateFilter(event.target.value);
    let countiesFiltered = countyList.filter((c) => c.state === event.target.value);
    setFilteredCountyList(countiesFiltered);
  };

  const handleCountrySelect = (event: any) => {
    let statesFiltered = stateList.filter((c) => c.country === event.target.value);
    setFilteredStateList(statesFiltered);
  };

  const handleCountySelect = (event: any) => {
    let filter = event.target.value;

    setCountyFilter(filter.split(' ')[0]);
  };

  const handleGoHome = () => {
    navigate('/dashboard');
  };

  const createClientCode = (name?: string, postal?: string) => {
    if (name && postal && name !== '' && postal !== '') {
      let code = '';
      let words = name.split(' ');
      words.forEach((word) => {
        if (code.length > 3) return;
        code = code + word[0];
      });
      code = code + postal;
      return code;
    }
  };

  const validateField = (value?: string, type?: string) => {
    if (value === undefined || value === '') {
      return false;
    }
    if (type === undefined) {
      return true;
    }

    let reg;
    if (type === 'Phone') {
      reg = new RegExp(/^(\(?\d{3}\)?)([ .-])(\d{3})([ .-])(\d{4})$/);
      return reg.test(value);
    } else if (type === 'PostalCode') {
      reg = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);
      return reg.test(value);
    } else if (type === 'EmailAddress') {
      reg = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      return reg.test(value);
    }

    return true;
  };

  const validateForm = () => {
    let errorsLocal = { ...errors };

    let key: keyof typeof errorsLocal;
    for (key in errorsLocal) {
      errorsLocal[key] = undefined;
    }

    setFormErrors(errorsLocal);

    if (activeStep === 0) {
      errorsLocal.name = !validateField(client.name);
      errorsLocal.address1 = !validateField(clientSettings.streetAddress);
      errorsLocal.city = !validateField(clientSettings.city);
      errorsLocal.state = !validateField(clientSettings.state);
      errorsLocal.timeZone = !validateField(clientSettings.timeZoneID);
      errorsLocal.postal = !validateField(clientSettings.postalCode, 'PostalCode');
      errorsLocal.phone = !validateField(clientSettings.phone, 'Phone');
    }

    if (activeStep === 1) {
      errorsLocal.contactName = !validateField(clientSettings.contactName);
      errorsLocal.contactEmail = !validateField(clientSettings.contactEmail, 'EmailAddress');
    }

    if (activeStep === 2) {
      errorsLocal.clientCode = !validateField(client.clientCode);
    }
    setFormErrors({ ...errorsLocal });

    for (key in errorsLocal) {
      let value = errorsLocal[key];
      if (value === true) {
        return false;
      }
    }
    return true;
  };

  const handlePhoneInput = (e: any) => {
    let phone = formatPhone(e.target.value, e.target.defaultValue);
    setClientSettings({ ...clientSettings, phone: phone });
  };

  const formatPhone = (value: any, previousValue: any) => {
    // return nothing if no value
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue;

      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    } else {
      return currentValue;
    }
  };

  const selectPsap = (psap: Psap) => {
    setPsapList((current) =>
      current.map((obj) => {
        if (obj.psapid === psap.psapid) {
          return { ...obj, selected: !obj.selected };
        }

        return { ...obj, selected: false };
      })
    );
  };

  const statesData = React.useCallback(() => {
    apiClient
      .getStatesList()
      .then((queryResult: any) => {
        setStateList(queryResult);
      })
      .catch((err: any) => {
        console.error('Failed to fetch state data', err);
      });
  }, [apiClient]);

  const countyData = React.useCallback(() => {
    apiClient
      .getCountiesList()
      .then((queryResult: any) => {
        setCountyList(queryResult);
      })
      .catch((err: any) => {
        console.error('Failed to fetch county data', err);
      });
  }, [apiClient]);

  const countryData = React.useCallback(() => {
    apiClient
      .getCountriesList()
      .then((queryResult: any) => {
        setCountryList(queryResult);
      })
      .catch((err: any) => {
        console.error('Failed to fetch country data', err);
      });
  }, [apiClient]);

  const timeZoneData = React.useCallback(() => {
    apiClient
      .getTimeZoneList()
      .then((queryResult: any) => {
        setTimeZoneList(queryResult);
      })
      .catch((err: any) => {
        console.error('Failed to fetch time zone data', err);
      });
  }, [apiClient]);

  if (stateList.length === 0) {
    statesData();
  }

  if (countyList.length === 0) {
    countyData();
  }

  if (countryList.length === 0) {
    countryData();
  }

  if (timeZoneList.length === 0) {
    timeZoneData();
  }

  let psapData = React.useCallback(() => {
    apiClient

      .getPSAPList({ state: stateFilter, county: countyFilter, limit: 500 })

      .then((queryResult: any) => {
        setPsapList(queryResult);
      })

      .catch((err: any) => {
        console.error('Failed to fetch PSAP data', err);
      });
  }, [apiClient, stateFilter, countyFilter]);

  const handleSearch = () => {
    psapData();
  };

  const handleNextStep = () => {
    console.log(client);
    console.log(clientSettings);
    if (validateForm()) {
      setActiveStep(activeStep + 1);
    }
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSave = () => {
    if (validateForm()) {
      let clientLocal = { ...client };
      let clientSettingsLocal = { ...clientSettings };

      apiClient
        .postSaveClient(clientLocal)
        .then((d: any) => {
          clientLocal.clientID = d.clientID;

          apiClient
            .postSaveClientSettings(clientLocal, clientSettingsLocal)
            .then((response: any) => {
              setClient(clientLocal);
              setClientSettings(clientSettingsLocal);

              setActiveStep(activeStep + 1);
            })
            .catch((error: any) => {
              console.log('Error saving new client settings:', error);
            });
        })
        .catch((error: any) => {
          console.log('Error saving new client:', error);
        });
    }
  };

  const handleCancel = () => {
    navigate('/dashboard');
  };

  return (
    <div className="incidentBG">
      <Container className="ms-0 me-0">
        <Row className="mt-1">
          <Col>
            <h3 className="pageHeader col-auto">Agency Registration</h3>
          </Col>
        </Row>
        <Row className="">
          <Col className="ms-0 col-12 center">
            <Container className="border border-primary-secondary">
              <Row className="mt-4 mx-4">
                <Col>
                  <Stepper activeStep={activeStep} nonLinear={!isCreate}>
                    {stepTitles.map((stepTitle, i) => (
                      <Step key={i}>
                        <StepButton onClick={() => setActiveStep(i)}>{stepTitle}</StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Col>
              </Row>
              <hr />
              <Row>
                <Container className=" p-4">
                  {activeStep === 0 && (
                    <div>
                      <Row>
                        <Col className="col-7">
                          <Form.Group className="mb-3" controlId="client.name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Agency Name"
                              value={client.name}
                              onChange={handleNameChange}
                              isValid={errors.name !== undefined && !errors.name}
                              isInvalid={errors.name !== undefined && errors.name}
                              autoFocus
                              required
                            />

                            <Form.Control.Feedback type="invalid">Please enter a name.</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>

                      <hr />

                      <Row className="mt-4">
                        <Col className="col-12">
                          <Form.Group className="mb-3" controlId="client.address1">
                            <Form.Label>Address 1:</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Address"
                              value={clientSettings.streetAddress}
                              onChange={handleAddress1Change}
                              isValid={errors.address1 !== undefined && !errors.address1}
                              isInvalid={errors.address1 !== undefined && errors.address1}
                              required
                            />

                            <Form.Control.Feedback type="invalid">Please enter an address.</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="col-12">
                          <Form.Group className="mb-3" controlId="client.address2">
                            <Form.Label>Address 2:</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Address"
                              value={clientSettings.streetAddress2}
                              onChange={handleAddress2Change}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="col-6">
                          <Row>
                            <Col className="col-12">
                              <Form.Group className="mb-3" controlId="client.city">
                                <Form.Label>City:</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="City"
                                  value={clientSettings.city}
                                  onChange={handleCityChange}
                                  isValid={errors.city !== undefined && !errors.city}
                                  isInvalid={errors.city !== undefined && errors.city}
                                  required
                                />

                                <Form.Control.Feedback type="invalid">Please enter a city.</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="col-6">
                          <Row>
                            <Col className="col-12">
                              <Form.Group className="mb-3" controlId="client.state">
                                <Form.Label>State:</Form.Label>
                                <Form.Select
                                  name="state"
                                  defaultValue={clientSettings.state}
                                  isValid={errors.state !== undefined && !errors.state}
                                  isInvalid={errors.state !== undefined && errors.state}
                                  onChange={(e) => handleStateChange(e)}
                                >
                                  <option value=""></option>
                                  {stateList.map((data) => {
                                    return <option value={data.abbrev}>{data.name}</option>;
                                  })}
                                </Form.Select>

                                <Form.Control.Feedback type="invalid">Please select a state.</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="col-4">
                          <Form.Group className="mb-3" controlId="client.postal">
                            <Form.Label>Postal Code:</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Postal Code"
                              value={clientSettings.postalCode}
                              onChange={handlePostalCodeChange}
                              isValid={errors.postal !== undefined && !errors.postal}
                              isInvalid={errors.postal !== undefined && errors.postal}
                              required
                            />

                            <Form.Control.Feedback type="invalid">
                              Please enter a valid postal code.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>

                      <hr />

                      <Row>
                        <Col className="col-6">
                          <Row>
                            <Col className="col-12">
                              <Form.Group className="mb-3" controlId="client.phone">
                                <Form.Label>Phone Number:</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Phone Number"
                                  value={clientSettings.phone}
                                  onChange={handlePhoneNumberChange}
                                  isValid={errors.phone !== undefined && !errors.phone}
                                  isInvalid={errors.phone !== undefined && errors.phone}
                                  required
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please enter a valid phone number.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="col-6">
                          <Row>
                            <Col className="col-12">
                              <Form.Group className="mb-3" controlId="client.timeZone">
                                <Form.Label>Timezone:</Form.Label>
                                <Form.Select
                                  name="timeZone"
                                  defaultValue={clientSettings.timeZoneID}
                                  isValid={errors.timeZone !== undefined && !errors.timeZone}
                                  isInvalid={errors.timeZone !== undefined && errors.timeZone}
                                  onChange={(e) => handleTimeZoneChange(e)}
                                >
                                  <option></option>
                                  {timeZoneList.map((data) => {
                                    return <option value={data.value}>{data.text}</option>;
                                  })}
                                </Form.Select>

                                <Form.Control.Feedback type="invalid">Please select a time zone.</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <hr />
                    </div>
                  )}
                  {activeStep === 1 && (
                    <div>
                      <Row>
                        <Col>
                          <Row>
                            <Col className="col-12">
                              <Form.Group className="mb-3" controlId="client.organizationType">
                                <Form.Label>Organization Type:</Form.Label>
                                <Form.Check
                                  type="radio"
                                  id="fire-deptartment"
                                  name="organizationType"
                                  label="Fire Department"
                                />
                                <Form.Check
                                  type="radio"
                                  id="government"
                                  name="organizationType"
                                  label="Governmental, Non-Fire or Law Enforcement"
                                />
                                <Form.Check type="radio" id="hospital" name="organizationType" label="Hospital" />
                                <Form.Check
                                  type="radio"
                                  id="private"
                                  name="organizationType"
                                  label="Prive, Nonhospital"
                                />
                                <Form.Check type="radio" id="tribal" name="organizationType" label="Tribal" />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <hr />
                      <h5>Contact Information</h5>
                      <Row>
                        <Col className="col-7">
                          <Form.Group className="mb-3" controlId="clientSettings.contactName">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Name"
                              value={clientSettings.contactName}
                              onChange={handleContactNameChange}
                              isValid={errors.contactName !== undefined && !errors.contactName}
                              isInvalid={errors.contactName !== undefined && errors.contactName}
                              required
                            />

                            <Form.Control.Feedback type="invalid">Please enter a contact name.</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-7">
                          <Form.Group className="mb-3" controlId="clientSettings.contactEmail">
                            <Form.Label>E-mail Address:</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="E-mail Address"
                              value={clientSettings.contactEmail}
                              onChange={handleContactEmailChange}
                              isValid={errors.contactEmail !== undefined && !errors.contactEmail}
                              isInvalid={errors.contactEmail !== undefined && errors.contactEmail}
                              required
                            />

                            <Form.Control.Feedback type="invalid">
                              Please enter a valid e-mail address.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {activeStep === 2 && (
                    <div>
                      <h5>Dispatch/Communication Center</h5>

                      <Row>
                        <Col className="col-7">
                          <Form.Group className="mb-3" controlId="psapCountry">
                            <Form.Label>Country:</Form.Label>
                            <Form.Select name="psapState" onChange={(e) => handleCountrySelect(e)}>
                              <option value="">Country</option>
                              {countryList.map((data) => {
                                return <option value={data.code}>{data.name}</option>;
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-7">
                          <Form.Group className="mb-3" controlId="psapState">
                            <Form.Label>State:</Form.Label>
                            <Form.Select name="psapState" placeholder="State" onChange={(e) => handleStateSelect(e)}>
                              <option>State</option>
                              {filteredStateList.map((data) => {
                                return <option value={data.abbrev}>{data.name}</option>;
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="col-7">
                          <Form.Group className="mb-3" controlId="psapCounty">
                            <Form.Label>County:</Form.Label>
                            <Form.Select name="psapState" placeholder="County" onChange={(e) => handleCountySelect(e)}>
                              <option>County</option>
                              {filteredCountyList.map((data) => {
                                return <option value={data.name}>{data.name}</option>;
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="col-12 text-center">
                          <Button onClick={handleSearch}>Search</Button>
                        </Col>
                      </Row>
                      {psapList.length > 0 && (
                        <Row>
                          <Col className="col-12">Select your agency</Col>
                        </Row>
                      )}

                      {psapList.length > 0 && (
                        <Row>
                          <Col className="col-12">
                            <div className="psap-table">
                              <Table className="table table-secondary table-striped" hover>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>City</th>
                                    <th>State</th>
                                  </tr>
                                </thead>

                                <tbody className="table-striped">
                                  {psapList.length > 0 &&
                                    psapList.map((data) => {
                                      return (
                                        <tr
                                          className={data.selected ? 'selected' : ''}
                                          onClick={() => selectPsap(data)}
                                        >
                                          <td>
                                            <Form.Check type="radio" name="psap-agency" value={data.psapid} />
                                          </td>
                                          <td>{data.psapid}</td>
                                          <td>{data.psapName}</td>
                                          <td>{data.city}</td>
                                          <td>{data.state}</td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      )}
                      <hr />

                      <Row>
                        <Col className="col-12">
                          <label className="form-label"></label>
                        </Col>
                        <Col className="col-7">
                          <Form.Group className="mb-3" controlId="currentEmployee">
                            <Form.Label>Are you currently an ImageTrend Elite client?</Form.Label>
                            <Form.Check type="radio" id="elite-client" name="elite-client" label="Yes" value="true" />

                            <Form.Check type="radio" id="elite-client2" name="elite-client" label="No" value="false" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <hr />

                      <Row>
                        <Col className="col-12">
                          <label className="form-label"></label>
                        </Col>
                        <Col className="col-7">
                          <Form.Group className="mb-3" controlId="currentEmployee">
                            <Form.Label>Create your Client Code</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Client Code"
                              value={client.clientCode}
                              onChange={handleClientCodeChange}
                              isValid={errors.clientCode !== undefined && !errors.clientCode}
                              isInvalid={errors.clientCode !== undefined && errors.clientCode}
                              required
                            />

                            <Form.Control.Feedback type="invalid">Please enter a client code.</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {activeStep === 3 && (
                    <div>
                      <Row>
                        <Col className="col-12 text-center text-success">
                          <h4>Agency submitted successfully!</h4>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Volutpat est velit egestas dui id ornare arcu odio ut. Luctus
                            accumsan tortor posuere ac ut consequat semper viverra. Viverra accumsan in nisl nisi
                            scelerisque eu ultrices vitae auctor. Cum sociis natoque penatibus et magnis dis parturient.
                            Tellus in hac habitasse platea dictumst vestibulum rhoncus. Elementum eu facilisis sed odio
                            morbi quis commodo. Ut tellus elementum sagittis vitae. Adipiscing elit ut aliquam purus sit
                            amet luctus venenatis lectus. Nisl nunc mi ipsum faucibus. Risus pretium quam vulputate
                            dignissim suspendisse. Suscipit tellus mauris a diam maecenas. Sit amet nisl purus in mollis
                            nunc sed id semper. Et egestas quis ipsum suspendisse ultrices gravida.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Container>
              </Row>
              <Row className="mb-3">
                <Col className="col-6">
                  {activeStep === 0 && (
                    <Button variant="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                  )}

                  {activeStep > 0 && activeStep < 3 && (
                    <Button variant="secondary" onClick={handlePreviousStep}>
                      Previous
                    </Button>
                  )}
                </Col>
                {activeStep < 2 && (
                  <Col className="col-6 right">
                    <Button variant="primary" onClick={handleNextStep}>
                      Next
                    </Button>
                  </Col>
                )}

                {activeStep === 2 && (
                  <Col className="col-6 right">
                    <Button variant="primary" onClick={handleSave}>
                      Submit
                    </Button>
                  </Col>
                )}

                {activeStep === 3 && (
                  <Col className="col-12 center">
                    <Button variant="primary" onClick={handleGoHome}>
                      Back to Dashboard
                    </Button>
                  </Col>
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
