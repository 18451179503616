import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../common/appState/appStateHooks';
import { selectClientAlias } from '../../common/appState/currentClientSlice';
import ClientAlias from '../../common/clientAlias';
import currentUser from '../../models/CurrentUser';

const RequireClientAdminRoute = () => {
  const [userIsClientAdmin, setUserClientAdmin] = useState(false);
  const currentClient: ClientAlias = useAppSelector(selectClientAlias);

  useEffect(() => {
    if (currentUser.isAdministrator(currentClient) !== userIsClientAdmin) {
      setUserClientAdmin(currentUser.isAdministrator(currentClient));
    }
  }, [userIsClientAdmin, setUserClientAdmin, currentClient]);

  if (userIsClientAdmin) {
    return <Outlet />;
  }

  return <Alert variant="warning">Access Denied</Alert>;
};

export default RequireClientAdminRoute;
