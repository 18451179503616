import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './appStore';
import ClientAlias from '../clientAlias';

interface CurrentClientState {
  id: string;
  name: string;
}

const initialState: CurrentClientState = {
  id: '',
  name: '',
};

// These reducers let you update the client information, either in part by using
// updateClientId or updateClientName, or in full by using updateClientAlias. They
// are responsible for updating all state related to the current client stored in
// global app state (src/common/appState/appStore)
export const currentClientSlice = createSlice({
  name: 'currentClient',
  initialState,
  reducers: {
    updateClientId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    updateClientName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    updateClientAlias: (state, action: PayloadAction<ClientAlias>) => {
      state.id = action.payload.clientID;
      state.name = action.payload.clientName;
    },
  },
});

export const { updateClientId, updateClientName, updateClientAlias } = currentClientSlice.actions;

// these selectors can be passed to the useAppSelector hook in src/common/appStateHooks to pull
// the current values of current client state in the redux store
export const selectClientId = (state: RootState) => state.currentClient.id;
export const selectClientName = (state: RootState) => state.currentClient.name;
export const selectClientAlias = (state: RootState) => ({
  clientID: state.currentClient.id,
  clientName: state.currentClient.name,
});

export default currentClientSlice.reducer;
