import { ResponderStatusKey } from '../responderMode/types';

export enum ResponseAction {
  Responded = 'Responded',
  Unavailable = 'Unavailable',
}

export const ResponseActionToResponderStatusKey = {
  [ResponseAction.Responded]: {
    statuses: [
      ResponderStatusKey.ArrivedAtStation,
      ResponderStatusKey.ArrivedOnScene,
      ResponderStatusKey.Canceling,
      ResponderStatusKey.Clear,
      ResponderStatusKey.RespondingToScene,
      ResponderStatusKey.RespondingToStation,
    ],
  },
  [ResponseAction.Unavailable]: {
    statuses: [ResponderStatusKey.Unavailable],
  },
};
