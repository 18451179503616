import { JwtBody } from '../models/Jwt';

interface TokenState {
  activate: () => TokenState;
  expire: () => TokenState;
  onTokenExpired: (handler: () => Promise<void>) => Promise<void>;
  onTokenValid: (handler: (jwt: string) => Promise<void>) => Promise<void>;
}

class UnexpiredTokenState implements TokenState {
  private readonly _jwt: string;

  constructor(jwt: string) {
    if (!jwt) {
      throw new Error('jwt must be provided');
    }

    this._jwt = jwt;
  }

  activate(): TokenState {
    return this as TokenState;
  }

  expire(): TokenState {
    return new ExpiredTokenState(this._jwt);
  }

  onTokenExpired(handler: () => Promise<void>): Promise<void> {
    //NO-OP as token isn't expired
    return Promise.resolve();
  }

  async onTokenValid(handler: (jwt: string) => Promise<void>) {
    await handler(this._jwt);
  }
}

class ExpiredTokenState implements TokenState {
  private readonly _jwt: string;

  constructor(jwt: string) {
    if (!jwt) {
      throw new Error('jwt must be provided');
    }

    this._jwt = jwt;
  }

  activate(): TokenState {
    return new UnexpiredTokenState(this._jwt);
  }

  expire(): TokenState {
    return this;
  }

  async onTokenExpired(handler: () => Promise<void>) {
    await handler();
  }

  onTokenValid(handler: (jwt: string) => Promise<void>) {
    //NO-OP as token has expired
    return Promise.resolve();
  }
}

export default class TokenContext {
  private readonly _jwt: string;
  private _tokenState: TokenState;

  constructor(jwt: string) {
    if (!jwt) {
      throw new Error('jwt must be provided');
    }

    this._jwt = jwt;
    this._tokenState = new UnexpiredTokenState(jwt);
  }

  async useToken(validTokenHandler: (token: string) => Promise<void>, expiredTokenHandler: () => Promise<void>) {
    this.checkJwtExpired();

    await this._tokenState.onTokenExpired(async () => await expiredTokenHandler());
    await this._tokenState.onTokenValid(async (validJwt: string) => await validTokenHandler(validJwt));
  }

  private checkJwtExpired() {
    const tokenParts = this._jwt.split('.');

    if (tokenParts.length < 3) {
      this._tokenState = this._tokenState.expire();
      return;
    }

    const tokenBodyRaw = window.atob(tokenParts[1]);

    const tokenBody = JSON.parse(tokenBodyRaw) as JwtBody;

    var localNow = new Date();
    var utcNow = Date.UTC(localNow.getUTCFullYear(), localNow.getUTCMonth(), localNow.getUTCDate()) / 1000;

    if (utcNow > tokenBody.exp) {
      this._tokenState = this._tokenState.expire();
    } else {
      this._tokenState = this._tokenState.activate();
    }
  }
}
