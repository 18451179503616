import moment from 'moment';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export default function UnitCard(props: {
  unitName?: string;
  index: number;
  arrivalDateTime?: Date;
  enrouteDateTime?: Date;
  dispatchDateTime?: Date;
  destinationName?: string;
  clearDateTime?: Date;
}): JSX.Element {
  return (
    <>
      <Accordion
        key={`${props.unitName}-${props.index}`}
        className={'mt-3 ' + (props.clearDateTime ? 'iResourceCard' : 'aResourceCard')}
      >
        <Accordion.Item eventKey="0">
          <Accordion.Button className="py-0">
            <Row className="mt-3 mx-0 align-items-center">
              <Col>
                <p className="text-bold text-center text-large">{props.unitName}</p>
              </Col>
              <Col className="col-auto">
                <p className="text-center">
                  {props.arrivalDateTime != null ? 'On Scene' : ''}
                  {props.arrivalDateTime == null && props.enrouteDateTime != null ? 'En Route' : ''}
                  {props.arrivalDateTime == null && props.enrouteDateTime == null && props.dispatchDateTime != null
                    ? 'Dispatched'
                    : ''}
                </p>
              </Col>
              <Col className="col-auto">
                <p className="text-center text-small">
                  {props.arrivalDateTime != null ? moment(props.arrivalDateTime).format('MM/DD/yyyy HH:mm') : ''}
                  {props.arrivalDateTime == null && props.enrouteDateTime != null
                    ? moment(props.enrouteDateTime).format('MM/DD/yyyy HH:mm')
                    : ''}
                  {props.arrivalDateTime == null && props.enrouteDateTime == null && props.dispatchDateTime != null
                    ? moment(props.dispatchDateTime).format('MM/DD/yyyy HH:mm')
                    : ''}
                </p>
              </Col>
              <Col className="col-auto">
                <p className="text-center text-small">
                  - {props.arrivalDateTime != null ? moment().diff(props.arrivalDateTime, 'minutes') : ''}
                  {props.arrivalDateTime == null && props.enrouteDateTime != null
                    ? moment().diff(props.enrouteDateTime, 'minutes')
                    : ''}
                  {props.arrivalDateTime == null && props.enrouteDateTime == null && props.dispatchDateTime != null
                    ? moment().diff(props.dispatchDateTime, 'minutes')
                    : ''}
                  min
                </p>
              </Col>
            </Row>
          </Accordion.Button>

          <Accordion.Body>
            {props.dispatchDateTime && (
              <Row className="mx-0">
                <Col className="text-start">Dispatched:</Col>
                <Col className="text-end">
                  {props.dispatchDateTime != null ? moment(props.dispatchDateTime).format('MM/DD/yyyy HH:mm') : ''}
                </Col>
              </Row>
            )}
            {props.enrouteDateTime && (
              <Row className="mx-0">
                <Col className="text-start">En Route:</Col>
                <Col className="text-end">
                  {props.enrouteDateTime != null ? moment(props.enrouteDateTime).format('MM/DD/yyyy HH:mm') : ''}
                </Col>
              </Row>
            )}
            {props.arrivalDateTime && (
              <Row className="mx-0">
                <Col className="text-start">On Scene:</Col>
                <Col className="text-end">
                  {props.arrivalDateTime != null ? moment(props.arrivalDateTime).format('MM/DD/yyyy HH:mm') : ''}
                </Col>
              </Row>
            )}
            {props.destinationName && (
              <Row className="mx-0">
                <Col className="text-start">Destination:</Col>
                <Col className="text-end">{props.destinationName != null ? props.destinationName : ''}</Col>
              </Row>
            )}
            {props.clearDateTime && (
              <Row className="mx-0">
                <Col className="text-start">Available:</Col>
                <Col className="text-end">
                  {props.clearDateTime != null ? moment(props.clearDateTime).format('MM/DD/yyyy HH:mm') : ''}
                </Col>
              </Row>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
