import { createSlice } from '@reduxjs/toolkit';
import { ActiveStatus, FilterState } from '../../components/dashboard/dashboardConsts';
import { RootState } from './appStore';

interface DashboardSettings {
  incidentStatus: ActiveStatus;
  activeState: FilterState;
}

const initialState: DashboardSettings = {
  incidentStatus: ActiveStatus.None,
  activeState: FilterState.None,
};

interface ReducerAction<T> {
  type: string;
  payload: T;
}

export const dashboardSettings = createSlice({
  name: 'dashboardSettings',
  initialState,
  reducers: {
    updateActiveStatus: (state, action: ReducerAction<ActiveStatus>) => {
      state.incidentStatus = action.payload;
    },
    updateStatusFilter: (state, action: ReducerAction<FilterState>) => {
      state.activeState = action.payload;
    },
  },
});

export const { updateActiveStatus, updateStatusFilter } = dashboardSettings.actions;

export const selectActiveStatus = (state: RootState) => state.dashboardSettings.incidentStatus;
export const selectActiveState = (state: RootState) => state.dashboardSettings.activeState;

export default dashboardSettings.reducer;
