import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './appStore';

interface NavVisibleState {
  visible: boolean;
}

const initialState: NavVisibleState = {
  visible: false,
};

export const navVisibleSlice = createSlice({
  name: 'navVisible',
  initialState,
  reducers: {
    showNav: (state) => {
      state.visible = true;
    },
    hideNav: (state) => {
      state.visible = false;
    },
  },
});

export const { showNav, hideNav } = navVisibleSlice.actions;

export const selectNavVisible = (state: RootState) => state.showNav.visible;

export default navVisibleSlice.reducer;
