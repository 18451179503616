import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import api from '../../common/api';
import routes from '../../common/routesDefinitions';
import { ClientUserInvitationRequest } from '../../models/ClientUserInvitationRequest';
import { postData } from '../../models/Helpers/FetchHelper';
import { appendQueryString } from '../../services/api/querystrings';
import Logo from '../logo/logo';

export default function Create1() {
  // User object attributes for Aware API call
  const [userFirstName, setUserFirstName] = useState({
    firstName: '',
  });
  const [userLastName, setUserLastName] = useState({
    lastName: '',
  });
  const [userEmailAddress, setUserEmailAddress] = useState({
    emailAddress: '',
  });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const location = useLocation();
  const searchParams: ClientUserInvitationRequest = location?.state?.searchParams;
  const navigate = useNavigate();

  // Handlers for user input in text fields to apply values to attribute states
  const handleFirstNameChange = (event: any) => {
    setUserFirstName({ ...userFirstName, firstName: event.target.value });
  };
  const handleLastNameChange = (event: any) => {
    setUserLastName({ ...userLastName, lastName: event.target.value });
  };

  const handleEmailChange = (event: any) => {
    setUserEmailAddress({ ...userEmailAddress, emailAddress: event.target.value });
  };

  // checks email address formatting
  function NotValid(email: string): boolean {
    let valid = false;
    let EMAIL_REGEXP =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (email !== '' && (email.length <= 5 || !EMAIL_REGEXP.test(email))) {
      valid = true;
    }
    return valid;
  }

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();
    // checks the user's entered email for formatting requirements,
    // then calls the Aware API to check if that email is already in use
    if (!NotValid(userEmailAddress.emailAddress)) {
      const awareResponse = await postData(`${api.baseAwareUrl}/user/ValidateEmailExists`, {
        emailAddress: userEmailAddress.emailAddress,
      });
      if (!awareResponse.ok) {
        setErrorMessages(['Unable to contact server.  Please try again later.']);
        return;
      }
      const data = await awareResponse.json();
      if (!!data) {
        setErrorMessages(['An account already exists using the email address you provided.']);
        return;
      }
      navigate(routes.create2, {
        state: {
          emailAddress: userEmailAddress.emailAddress,
          firstName: userFirstName.firstName,
          lastName: userLastName.lastName,
          searchParams: searchParams,
        },
      });

      setUserEmailAddress({ emailAddress: '' });
      setUserFirstName({ firstName: '' });
      setUserLastName({ lastName: '' });
    }
  };

  return (
    <div>
      <Row className="mx-0">
        <Col className="textRight">
          <Logo />
        </Col>
      </Row>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-12 col-md-9">
            <div className="form-bg form-border shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-white mb-4">
                          Create an Aware account:
                          <br />
                          Step 1 of 3
                        </h1>
                        <p className="text-white">These are the credentials you will use as your Signal login.</p>
                      </div>
                      <Form onSubmit={(e) => handleFormSubmit(e)}>
                        <Alert
                          dismissible
                          onClose={() => setErrorMessages([])}
                          variant="danger"
                          show={errorMessages.length > 0}
                        >
                          <ul>
                            {errorMessages.map((errorMessage, index) => (
                              <li key={index}>{errorMessage}</li>
                            ))}
                          </ul>
                        </Alert>
                        <Form.Group className="mb-3">
                          <Form.Label className="form-title" htmlFor="emailAddress">
                            Email Address
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="emailAddress"
                            name="emailAddress"
                            onChange={handleEmailChange}
                            value={userEmailAddress.emailAddress}
                            required
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="form-title" htmlFor="firstName">
                            First Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="firstName"
                            name="firstName"
                            onChange={handleFirstNameChange}
                            value={userFirstName.firstName}
                            required
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="form-title" htmlFor="lastName">
                            Last Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="lastName"
                            name="lastName"
                            onChange={handleLastNameChange}
                            value={userLastName.lastName}
                            required
                          />
                        </Form.Group>
                        <div className="row mb-3 mx-1">
                          <Button type="submit" name="next" variant="primary">
                            Next Step
                          </Button>
                        </div>
                        <div className="row mx-1"></div>
                      </Form>
                      <hr />
                      <div className="text-center">
                        <p className="text-white">Already have an Aware account?</p>
                        <br />
                        <Link to={appendQueryString(routes.login, searchParams)}>Back to Login Page</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
