import { Office, STEMILevelID, StrokeLevelID, TraumaLevelID } from '../../models/ResourceBridge/Office';

export enum RequestType {
  // None = 0,
  ClosestBurnCenter = 1,
  ClosestCardiacCenter = 2,
  ClosestChildrensHospital = 3,
  ClosestHospital = 4,
  ClosestPediatricTraumaCenter = 5,
  ClosestStrokeCenter = 6,
  ClosestTraumaCenter = 7,
  NoPreferenceOnDestination = 8,
  RequestedDestinationNotProvided = 9,
}

export enum GenerationType {
  None,
  Generate,
  Select,
}

// TODO: Closest Destination is unimplemented, but keeping it for potential future usage.
interface DestinationRequestType {
  label: string;
  generationType: GenerationType;
  getClosestDestination(destinations: Office[]): Office | null;
}

export const DestinationRequestTypeOptions: {
  [key in RequestType]: DestinationRequestType;
} = {
  [RequestType.ClosestBurnCenter]: {
    label: 'Closest Burn Center',
    generationType: GenerationType.Generate,
    getClosestDestination(destinations) {
      return destinations.find((x) => x.specialties.filter((y) => y.name === 'Burn Center').length > 0) ?? null;
    },
  },
  [RequestType.ClosestCardiacCenter]: {
    label: 'Closest Cardiac Center',
    generationType: GenerationType.Generate,
    getClosestDestination(destinations) {
      return destinations.find((x) => x.pA_STEMILevelID === STEMILevelID.Level1) ?? null;
    },
  },
  [RequestType.ClosestChildrensHospital]: {
    label: "Closest Children's Hospital",
    generationType: GenerationType.Generate,
    getClosestDestination(destinations) {
      return destinations.find((x) => x.specialties.filter((y) => y.name === "Children's Hospital").length > 0) ?? null;
    },
  },
  [RequestType.ClosestHospital]: {
    label: 'Closest Hospital',
    generationType: GenerationType.Generate,
    getClosestDestination(destinations) {
      return destinations[0] ?? null;
    },
  },
  [RequestType.ClosestPediatricTraumaCenter]: {
    label: 'Closest Pediatric Trauma Center',
    generationType: GenerationType.Generate,
    getClosestDestination(destinations) {
      return (
        destinations.find((x) => x.specialties.filter((y) => y.name === 'Pediatric Trauma Center').length > 0) ?? null
      );
    },
  },
  [RequestType.ClosestStrokeCenter]: {
    label: 'Closest Stroke Center',
    generationType: GenerationType.Generate,
    getClosestDestination(destinations) {
      return (
        destinations.find(
          (x) => x.pA_StrokeLevelID === StrokeLevelID.Level1 || x.pA_StrokeLevelID === StrokeLevelID.Level2
        ) ?? null
      );
    },
  },
  [RequestType.ClosestTraumaCenter]: {
    label: 'Closest Trauma Center',
    generationType: GenerationType.Generate,
    getClosestDestination(destinations) {
      return (
        destinations.find(
          (x) =>
            x.traumaLevelID === TraumaLevelID.Level1 ||
            x.traumaLevelID === TraumaLevelID.Level2 ||
            x.traumaLevelID === TraumaLevelID.Level3 ||
            x.traumaLevelID === TraumaLevelID.Level4
        ) ?? null
      );
    },
  },
  [RequestType.NoPreferenceOnDestination]: {
    label: 'No Preference on Destination',
    generationType: GenerationType.None,
    getClosestDestination() {
      return null;
    },
  },
  [RequestType.RequestedDestinationNotProvided]: {
    label: 'Requested Destination Not Provided',
    generationType: GenerationType.None,
    getClosestDestination() {
      return null;
    },
  },
};

// These aren't fully hard-coded due to database shenanigans, but it should be fine.
export enum CallTriageIncidentDispositionID {
  DestinationAssigned = 1,
  LineDisconnected = 2,
  NonTransport = 3,
}

export interface CallTriagePatient {
  incidentPatientID: number;
  age: number | null;
  ageUnitsID: number | null;
  genderID: number | null;
  createdOnUtc?: Date;
  createdBy?: string;
  deleted: boolean;
}

export interface CallTriageComment {
  createdBy: string;
  createdOnUtc: Date;
  comment: string;
  createdByFirstname?: string;
  createdByLastname?: string;
}

export interface CallTriageIncident {
  eventID: string;
  incidentID: string | null;
  incidentNumber: string;
  incidentStatusID: number | null;
  incidentDispositionID: CallTriageIncidentDispositionID | null;
  incidentLocation: string;
  longitude: number | null;
  latitude: number | null;
  incidentCommonPlaceID: number | null;
  incidentTypeID: number | null;
  incidentSubtypeID: number | null;
  primaryResourceAgencyID: number | null;
  primaryResourceID: string;
  finalDestinationID: string | null;
  finalDestinationName: string | null;
  requestedDestinationType: RequestType | null;
  requestedDestinationID: string | null;
  requestedDestinationName: string | null;
  patients: CallTriagePatient[];
  comments: CallTriageComment[];
  createdOnUtc?: Date;
  createdBy?: string;
  startBy?: string;
  startOnUtc?: Date;
  modifiedOnUtc?: Date;
  modifiedBy?: string;
}

export interface LatLong {
  latitude: number;
  longitude: number;
}

export interface CallTriageIncidentStatus {
  incidentStatusID: number;
  name: string;
}

export interface CallTriageIncidentDisposition {
  incidentDispositionID: number;
  name: string;
}

export interface CallTriageCommonPlace {
  commonPlaceID: number;
  name: string;
  latitude: number;
  longitude: number;
}

export interface CallTriageIncidentType {
  incidentTypeID: number;
  name: string;
}

export interface CallTriageIncidentSubtype {
  incidentSubtypeID: number;
  incidentTypeID: number;
  name: string;
}

export interface CallTriageAgency {
  agencyID: number;
  clientID: string;
  name: string;
}

export interface DestinationTransport {
  name: string;
  incidentCount: number;
}

export interface CallTriageAuditLog {
  incidentAuditLogID: number;
  incidentID?: string;
  action?: string;
  notes?: string;
  createdOnUtc: Date;
  createdByFirstname?: string;
  createdByLastname?: string;
}

export interface AuditChange {
  Property: string;
  OldValue?: string;
  NewValue?: string;
}
