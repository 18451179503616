import './Step.css';

import React from 'react';

import clsx from '../classnames';
import StepConnector from './StepConnector';
import StepLabel, { StepLabelProps } from './StepLabel';

export interface StepProps {
  /**
   * Sets the step as active. Is passed to child components.
   */
  active?: boolean;

  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed?: boolean;

  /**
   * Mark the step as disabled, will also disable the button if
   * `StepButton` is a child of `Step`. Is passed to child components.
   */
  disabled?: boolean;

  /**
   * Mark the step with an error.  Is passed to child components.
   */
  error?: boolean;

  /**
   * Should be `Step` sub-components such as `StepLabel`, `StepContent`.
   */
  children?: React.ReactNode;

  /**
   * The zero-based index of this step within a `Stepper`.
   */
  index?: number;

  /**
   * True if this is the last step within a `Stepper`.
   */
  last?: boolean;
}

const Step: React.FC<StepProps> = (props) => {
  const { active = false, completed = false, disabled = false, error = false, index = 0, children } = props;
  return (
    <div className={clsx('stepper-step', active && 'active', completed && 'completed', disabled && 'disabled')}>
      <StepConnector active={active} completed={completed} disabled={disabled} first={index === 0} />
      {React.Children.map(children, (child) => {
        let childElement: React.ReactElement;
        if (React.isValidElement(child)) {
          childElement = child;
        } else {
          childElement = <StepLabel>{child}</StepLabel>;
        }

        const stepLabelProps: Partial<StepLabelProps> = {
          active,
          completed,
          disabled,
          error,
          icon: index + 1,
        };
        return React.cloneElement(childElement, {
          ...stepLabelProps,
          ...childElement.props,
        });
      })}
    </div>
  );
};
export default Step;
