import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import api from '../../common/api';
import Logo from '../logo/logo';

export default function ForgotPassword() {
  const [userEmailAddress, setUserEmailAddress] = useState({
    emailAddress: '',
  });

  const [showAlert, setShowAlert] = useState(false);

  const handleEmailChange = (event: any) => {
    setUserEmailAddress({ ...userEmailAddress, emailAddress: event.target.value });
  };

  async function postData(url = '', data = {}) {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  const handleFormSubmit = (event: any) => {
    event.preventDefault();

    postData(`${api.baseAwareUrl}/user/forgotPassword`, {
      emailAddress: userEmailAddress.emailAddress,
    }).then((data) => {
      if (data) {
        console.log(data);
        // Stores the user object for calls to Aware for further functionality
        let user = {
          emailAddress: data.emailAddress,
          verified: data.verified,
          active: data.active,
          admin: data.admin,
          id: data.id,
        };
        sessionStorage.setItem('user', JSON.stringify(user));
        window.location.href = '/forgot2';
      } else {
        setShowAlert(true);
      }
    });
    setUserEmailAddress({ emailAddress: '' });
  };

  return (
    <div>
      <Row className="mx-0">
        <Col className="textRight">
          <Logo />
        </Col>
      </Row>
      <div className="container">
        <div className="row justify-content-center mx-0">
          <div className="col-xl-5 col-lg-12 col-md-9">
            <div className="form-bg o-hidden form-border shadow-lg my-5">
              <div>
                <div className="row mx-0">
                  <div className="col-lg-12">
                    <h4 className="forgotHeader" id="forgotPanelTop">
                      Forgot your password?
                    </h4>
                    <p className="forgotHeader mx-5">
                      Type your account email address and we'll send you a code to reset it.
                    </p>
                    <div className="forgotContent">
                      <Form onSubmit={(e) => handleFormSubmit(e)}>
                        <Alert dismissible onClose={() => setShowAlert(false)} show={showAlert} variant="danger">
                          User email address not found.
                        </Alert>
                        <p className="form-title">Email Address</p>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            name="emailAddress"
                            onChange={handleEmailChange}
                            value={userEmailAddress.emailAddress}
                            required
                          />
                        </Form.Group>
                        <Row className="my-1">
                          <Col>
                            <div className="textLeft">
                              <Link to="/" className="small">
                                Back to Login
                              </Link>
                            </div>
                          </Col>
                        </Row>
                        <div className="row mb-4 mt-5 mx-1">
                          <Button type="submit" name="login" variant="primary" className="col-12">
                            SEND CODE
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
