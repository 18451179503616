import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import api from '../../common/api';
import routes from '../../common/routesDefinitions';
import { ClientUserInvitationRequest } from '../../models/ClientUserInvitationRequest';
import { postData } from '../../models/Helpers/FetchHelper';
import { appendQueryString } from '../../services/api/querystrings';
import login from '../login/login';
import Logo from '../logo/logo';

export default function Create3() {
  const navigate = useNavigate();
  const location = useLocation();

  const emailAddress = location?.state?.emailAddress;
  const firstName = location?.state?.firstName;
  const lastName = location?.state?.lastName;
  // This is probably a bad idea, but it's either this or re-login.
  const password = location?.state?.password;
  const code = location?.state?.code;
  const searchParams: ClientUserInvitationRequest | undefined = location?.state?.searchParams;

  useEffect(() => {
    if (!location?.state) {
      navigate(routes.create1);
    }
  });

  // User object attributes for Aware API call
  const [userEmailAddress, setUserEmailAddress] = useState({
    emailAddress: emailAddress,
  });
  const [userCode, setUserCode] = useState({
    code: '',
  });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  // Handlers for user input in text fields to apply values to attribute states
  const handleEmailChange = (event: any) => {
    setUserEmailAddress({ ...userEmailAddress, emailAddress: event.target.value });
  };
  const handleCodeChange = (event: any) => {
    setUserCode({ ...userCode, code: event.target.value });
  };

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();
    if (userCode.code !== code) {
      setErrorMessages(['The verification code you entered was incorrect.']);
      return;
    }
    const awareResponse = await postData(`${api.baseAwareUrl}/user/GetByCode`, {
      emailAddress: userEmailAddress.emailAddress,
      verificationCode: userCode.code,
    });

    if (!awareResponse.ok) {
      setErrorMessages(['Something went wrong. Please contact an administrator.']);
      return;
    }
    const awareDate = await awareResponse.json();
    const awareVerifyResponse = await postData(`${api.baseAwareUrl}/user/verifyAndLogin`, {
      id: awareDate.id,
      emailAddress: userEmailAddress.emailAddress,
      verificationCode: userCode.code,
      verified: true,
      firstName: firstName,
      lastName: lastName,
    });
    if (!awareVerifyResponse.ok) {
      setErrorMessages(['Something went wrong. Please contact an administrator.']);
      return;
    }
    const loginResponse = await login({
      emailAddress: userEmailAddress.emailAddress,
      password,
      searchParams,
    });
    if (loginResponse.ok()) {
      setUserEmailAddress({ emailAddress: '' });
      setUserCode({ code: '' });
      navigate('/dashboard');
    } else {
      // move them on to the next page, since their account finished setting up
      navigate(routes.login, { state: { errorMessages: errorMessages } });
    }
  };

  return (
    <div>
      <Row className="mx-0">
        <Col className="textRight">
          <Logo />
        </Col>
      </Row>
      <div className="container">
        <div className="row justify-content-center mx-0">
          <div className="col-xl-5 col-lg-12 col-md-9">
            <div className="form-bg form-border shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row mx-0">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-white mb-4">
                          Create an Aware account:
                          <br />
                          Step 3 of 3
                        </h1>
                        <p className="text-white">
                          We sent a verification code to your email address. Enter your email and the code you received
                          below to verify your account and login to Signal.
                        </p>
                      </div>
                      <Form onSubmit={(e) => handleFormSubmit(e)}>
                        <Alert
                          dismissible
                          onClose={() => setErrorMessages([])}
                          variant="danger"
                          show={errorMessages.length > 0}
                        >
                          <ul>
                            {errorMessages.map((errorMessage) => (
                              <li>{errorMessage}</li>
                            ))}
                          </ul>
                        </Alert>
                        <p className="form-title">Email Address</p>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            name="emailAddress"
                            onChange={handleEmailChange}
                            value={userEmailAddress.emailAddress}
                            required
                          />
                        </Form.Group>
                        <p className="form-title">Verification Code</p>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="password"
                            name="verificationCode"
                            onChange={handleCodeChange}
                            value={userCode.code}
                            required
                          />
                        </Form.Group>
                        <div className="row mb-3 mx-1">
                          <Button type="submit" name="next" variant="primary">
                            Verify Account
                          </Button>
                        </div>
                        <div className="row mx-1"></div>
                      </Form>
                      <hr />
                      <div className="text-center">
                        <p className="text-white">Already have an Aware account?</p>
                        <br />
                        <Link to={appendQueryString(routes.login, searchParams)}>Back to Login Page</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
