import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@imagetrend/fontawesome-pro/pro-solid-svg-icons';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

const BackArrow = () => {
  return <FontAwesomeIcon icon={faCircleArrowLeft} size={'3x'} className={'text-it-ems'} />;
};

interface BackButtonProps {
  to: string;
}

export const BackButton = ({ to }: BackButtonProps) => {
  return (
    <Col className="col-auto d-print-none">
      <Link to={to}>
        <BackArrow></BackArrow>
      </Link>
    </Col>
  );
};
